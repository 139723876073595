import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Sector, Cell } from "recharts";
import { ScrollPanel } from 'primereact/scrollpanel';
import { GoDotFill } from "react-icons/go";
import { BsFolder } from "react-icons/bs";
import { FaArrowTrendUp } from "react-icons/fa6";
import { PiBatteryChargingLight, PiListChecks, PiUsersThree } from "react-icons/pi";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import TitleBar from "../../Common/TitleBar";
import DashboardHeader from "../../Common/DashboardHeader";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import DashboardFooter from '../../Common/DashboardFooter';
import { useDispatch } from 'react-redux';
import { fetchAdminDashboardAction, fetchAdminDashboardActionMessage } from '../../../Redux/Actions/AdminDashboardActions';
import { FaSpider, FaSpinner } from "react-icons/fa";
import SidePanel from '../../Common/SidePanel';
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { MdOutlineCreate } from "react-icons/md";
import { BiDollar } from "react-icons/bi";
import Alert from 'react-bootstrap/Alert';
import { FaArrowTrendDown } from "react-icons/fa6";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { Dropdown } from 'primereact/dropdown';

import { Calendar } from 'primereact/calendar';

const AdminDashboard = () => {
    const [selectedCity, setSelectedCity] = useState({ name: 'All', code: '0' });
    const [buttonLoader, setButtonLoader] = useState(false)
    const [stausType, setStausType] = useState("All")
    const cities = [
        { name: 'All', code: '0' },
        { name: 'Submission task', code: '1' },

    ];

    // const [date, setDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const currentDate = new Date();

    const [isActive, setIsActive] = useState(true);
    const [messageData, setMessageData] = useState([])
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const [show, setShow] = useState([]);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    // piechart..........
    const dispatch = useDispatch()
    const [fetchData, setFetchData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })
    const data = [
        { name: "Group A", value: fetchData?.task_status?.approved },
        { name: "Group B", value: fetchData?.task_status?.rejected },
        { name: "Group c", value: fetchData?.task_status?.pending }
    ];
    const data2 = [
        { name: "Group A", value: fetchData?.task_status?.approved },
        { name: "Group B", value: fetchData?.task_status?.rejected },
        { name: "Group c", value: fetchData?.task_status?.pending },
        { name: "Group D", value: fetchData?.task_status?.automation_approve },

    ];
    const COLORS = ["#BAEDBD", "#95A4FC", "#1C1C1C"];
    const COLORS2 = ["#95a4fc", "rgb(248, 215, 218)", "rgb(255, 243, 205)", "#BAEDBD"];
    // BarChart.......
    // BarChart.......
    const data1 = fetchData?.bardata;
    // const maxPV = data1?.length > 0 ? Math.max(...data1.map(item => item.pv || 0)) : 0;
    const totalPV = data1?.reduce((acc, item) => acc + (item.pv || 0), 0) || 0;
    const isOddLength = data1?.length % 2 !== 0;
    const maxPV = isOddLength ? totalPV : Math.max(...data1.map(item => item.pv || 0));
    // const ticks = Array.from({ length: Math.ceil(maxPV / 1000) + 1 }, (_, i) => i * maxPV);
    const tickCount = 4;
    const tickInterval = maxPV / tickCount;
    const ticks = Array.from({ length: tickCount + 1 }, (_, i) => Math.floor(i * tickInterval));


    const fetchData1 = (showLoader = true) => {

        if (showLoader) {
            setLoader({
                ...loader,
                fetch: true
            })
        }
        let formData = {
            "startDate": startDate?.toLocaleDateString('en-CA') || null,
            "endDate": endDate?.toLocaleDateString('en-CA') || null,
            "status": selectedCity?.code
        }
        dispatch(fetchAdminDashboardAction((newData) => {
            setFetchData(newData);
            setLoader({ ...loader, fetch: false });
            setButtonLoader(false)
            setStausType(selectedCity?.code)
        }, formData, setLoader, loader))

    }
    const fetchMessage = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchAdminDashboardActionMessage(setMessageData, setLoader, loader))
    }
    useEffect(() => {
        fetchData1()
        fetchMessage()
    }, [])
    const handleAlertClose = (index) => {
        setShow((prev) => {
            const newShow = [...prev];
            newShow[index] = false;
            return newShow;
        });
    };
    useEffect(() => {
        if (messageData?.message?.length > 0) {
            setShow(messageData.message.map(() => true));
        }
    }, [messageData]);
    const handleSubmit = () => {
        fetchData1(false)
        setButtonLoader(true)

    }
    const handleClear = () => {
        setEndDate(null)
        setStartDate(null)
    }

    return (
        <>

            <TitleBar title="Dashboard" />
            <div className="dashboard creator_dashboard  container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-12 ps-md-0 pe-md-3 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Dashboard" />
                                        {
                                            loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-5" style={{ height: "100vh" }}><FaSpinner className="spin" style={{ fontSize: "32px" }} /></div> :

                                                <ScrollPanel className="custombar2 content-area px-3">
                                                    <div className="p-3 ps-0 ps-md-3">
                                                        <div className="do_task_wrapper ">
                                                            <div className='d-flex account-block date-pick-container align-items-center justify-content-end gap-2 mb-4'>
                                                                <div className='d-flex align-items-center'> <h6 style={{ fontWeight: "500", marginRight: "8px" }}>From</h6>
                                                                    <div className="date-pickers flex justify-content-center">
                                                                        <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} maxDate={endDate || currentDate} dateFormat="dd/mm/yy" />
                                                                    </div></div>
                                                                <div className='d-flex align-items-center'> <h6 style={{ fontWeight: "500", marginRight: "10px" }}>To</h6>
                                                                    <div className="date-pickers flex justify-content-center">
                                                                        <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} minDate={startDate} maxDate={currentDate} dateFormat="dd/mm/yy" />
                                                                    </div></div>
                                                                <div className="d-flex date-pickers justify-content-center">
                                                                    <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                                                        placeholder="All" className="border-0" />
                                                                </div>
                                                                <button className='theme-btn' onClick={handleSubmit}>{buttonLoader ? <FaSpinner className='spin' /> : "Submit"}</button>
                                                                <button className='theme-btn' onClick={handleClear}>Clear</button>

                                                            </div>
                                                            <div className="row justify-content-between">
                                                                <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task">
                                                                        <div className="d-flex justify-content-between mb-2 align-items-center">
                                                                            <h3>{stausType === "0" ? "Total Tasker" : "Total Submission"}</h3>
                                                                            <PiUsersThree className="m_icon" />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between gap-3">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>{stausType === "0" ? fetchData?.total_tasker : fetchData?.total_submission}</span>
                                                                            {/* <div className="class_trend">
                                                                                <span className="me-1">+11.01%</span>
                                                                                <FaArrowTrendUp />
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task d2">
                                                                        <div className="d-flex justify-content-between mb-2 align-items-center">
                                                                            <h3>{stausType === "0" ? "Total Task Creator" : "Automation Approved"}</h3>
                                                                            <MdOutlineCreate className='m_icon' />

                                                                        </div>
                                                                        <div className="d-flex justify-content-between gap-3">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>{stausType === "0" ? fetchData?.total_creator : fetchData?.total_automation_apporove}</span>
                                                                            {/* <div className="class_trend">
                                                                                <span className="me-1">+15.03%</span>
                                                                                <FaArrowTrendUp />
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task">
                                                                        <div className="d-flex justify-content-between mb-2 align-items-center">
                                                                            <h3>{stausType === "0" ? "Total Tasks" : "Automation Rejected"}</h3>
                                                                            <PiListChecks className="m_icon" />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between gap-3">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>{stausType === "0" ? fetchData.total_task : fetchData.total_automation_rejected}</span>
                                                                            {/* <div className="class_trend">
                                                                                <span className="me-1">-0.03%</span>
                                                                                <FaArrowTrendDown style={{ rotate: "115deg" }} />
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task d2">
                                                                        <div className="d-flex justify-content-between mb-2 align-items-center">
                                                                            <h3>{stausType === "0" ? "Total Spending" : "Total UnAssign"}</h3>
                                                                            <PiBatteryChargingLight className="m_icon" />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between gap-3">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}> {stausType === "0" ? "₹" : ""} {stausType === "0" ? fetchData?.total_spending : fetchData?.total_unassign}</span>
                                                                            {/* <div className="class_trend">
                                                                                <span className="me-1">+11.01%</span>
                                                                                <FaArrowTrendUp />
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-2 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task d2">
                                                                        <div className="d-flex justify-content-between mb-2 align-items-center">
                                                                            <h3>{stausType === "0" ? "Total Payouts" : "Total Pending"}</h3>
                                                                            <RiMoneyRupeeCircleLine className='m_icon' />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between gap-3">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>{stausType === "0" ? "₹" : ""} {stausType === "0" ? fetchData.total_payout : fetchData.total_pending_task}</span>
                                                                            {/* <div className="class_trend">
                                                                                <span className="me-1">+11.01%</span>
                                                                                <FaArrowTrendUp />
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {messageData?.message?.length > 0 &&
                                                            messageData.message.map((curElem, i) => (
                                                                show[i] && (
                                                                    <div key={i}>
                                                                        <Alert
                                                                            className="theme-alert"
                                                                            onClose={() => handleAlertClose(i)}
                                                                            dismissible
                                                                        >
                                                                            <div className="d-flex w-100 align-items-start align-items-md-center flex-column flex-md-row">
                                                                                <div className="col-auto ps-2 pe-0">
                                                                                    <HiOutlineBellAlert style={{ fontSize: "25px" }} />
                                                                                </div>
                                                                                <div className="col-lg-11 ps-2 ps-lg-1">
                                                                                    <p>{curElem}</p>
                                                                                </div>
                                                                            </div>
                                                                        </Alert>
                                                                    </div>
                                                                )
                                                            ))}


                                                        <div className="row">{
                                                            stausType === "0" ?

                                                                <div className=" col-xl-6 col-12">
                                                                    <div className="campaign-stat-left admin_graph_wrap w-100">
                                                                        <div className="account-block status">
                                                                            <h6>Task Status</h6>
                                                                            <div>
                                                                                <div className='row'>
                                                                                    <div className="col-xl-5 col-12">
                                                                                        <div className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 265, margin: '0 auto' }}>
                                                                                            <ResponsiveContainer width="100%" height="100%">
                                                                                                <PieChart>
                                                                                                    <Pie
                                                                                                        data={data}
                                                                                                        cx="50%"
                                                                                                        cy="50%"
                                                                                                        innerRadius={60}
                                                                                                        outerRadius={80}
                                                                                                        fill="#8884d8"
                                                                                                        paddingAngle={5}
                                                                                                        dataKey="value"
                                                                                                    >
                                                                                                        {data.map((entry, index) => (
                                                                                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                                                        ))}
                                                                                                    </Pie>
                                                                                                    <Tooltip />
                                                                                                </PieChart>
                                                                                            </ResponsiveContainer>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="col-xl-7 col-12">
                                                                                        <ul className='graph_data pe-4'>
                                                                                            <li>
                                                                                                <p><GoDotFill style={{ color: "rgb(255, 243, 205)", marginRight: "3px" }} />Pending</p>
                                                                                                <span>{fetchData?.task_status?.pending}%</span>
                                                                                            </li>
                                                                                            <li>
                                                                                                <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />Approved</p>
                                                                                                <span>{fetchData?.task_status?.approved}%</span>
                                                                                            </li>
                                                                                            {/* <li>
                                                                                    <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Admin Approval Pending</p>
                                                                                    <span>6%</span>
                                                                                </li> */}
                                                                                            {/* <li>
                                                                                    <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Task Creator Approval Pending</p>
                                                                                    <span>6%</span>
                                                                                </li> */}
                                                                                            <li>
                                                                                                <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} />Rejected</p>
                                                                                                <span>{fetchData?.task_status?.rejected}%</span>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> : <>
                                                                    <div className="col-xl-6 col-12">
                                                                        <div className="campaign-stat-right admin_graph_wrap w-100 ps-0 pe-0">
                                                                            <div className="account-block status">
                                                                                <h6>Submission Status</h6>
                                                                                <div>

                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-xl-5 col-12">
                                                                                            <div className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 265, maxWidth: 500, margin: '0 auto' }}>
                                                                                                <ResponsiveContainer width="100%" height="100%">
                                                                                                    <PieChart>
                                                                                                        <Pie
                                                                                                            data={data2}
                                                                                                            cx="50%"
                                                                                                            cy="50%"

                                                                                                            innerRadius={60}
                                                                                                            outerRadius={80}
                                                                                                            fill="#8884d8"
                                                                                                            paddingAngle={5}
                                                                                                            dataKey="value"
                                                                                                        >

                                                                                                            {data2.map((entry, index) => (
                                                                                                                <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                                                                                                            ))}
                                                                                                        </Pie>

                                                                                                    </PieChart>
                                                                                                </ResponsiveContainer>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="col-xl-7 col-12">
                                                                                            <div className="graph_data pe-4">
                                                                                                {/* <li>
                                                                                                    <p><GoDotFill style={{ color: "#1c1c1c", marginRight: "3px" }} />Total</p>
                                                                                                    <span>{ }</span>
                                                                                                </li> */}
                                                                                                <li>
                                                                                                    <p><GoDotFill style={{ color: "rgb(255, 243, 205)", marginRight: "3px" }} />Pending</p>
                                                                                                    <span>{fetchData?.task_status?.pending}%</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />Automation</p>
                                                                                                    <span>{fetchData?.task_status?.automation_approve}%</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <p><GoDotFill style={{
                                                                                                        color: "rgb(248, 215, 218) ", marginRight: "3px"
                                                                                                    }} />Rejected</p>
                                                                                                    <span>{fetchData?.task_status?.rejected}%</span>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <p><GoDotFill style={{ color: "#95a4fc", marginRight: "3px" }} />Approved</p>
                                                                                                    <span>{fetchData?.task_status?.approved}%</span>
                                                                                                </li>





                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }
                                                        </div>

                                                        <div className="account-block d-none d-md-block mt-4 mb-5">
                                                            <h6>Tasks Overview</h6>
                                                            <div className="task_overview">
                                                                <ResponsiveContainer width="100%" height={300}>
                                                                    <BarChart
                                                                        data={data1}
                                                                        margin={{
                                                                            top: 5,
                                                                            right: 30,
                                                                            left: 20,
                                                                            bottom: 5,
                                                                        }}
                                                                        barSize={20}
                                                                    >
                                                                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                                                        <YAxis tickFormatter={(value) => Math.floor(value)} ticks={ticks} />
                                                                        <Tooltip />
                                                                        <Legend />
                                                                        <CartesianGrid strokeDasharray="3 3" />
                                                                        <Bar dataKey="pv" fill="#A8C5DA" background={{ fill: "#eee" }} />
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ScrollPanel>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className=''><DashboardFooter /></div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default AdminDashboard
