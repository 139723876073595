import React, { useEffect, useState } from 'react';
import { BiSolidBank } from "react-icons/bi";
import { GiWallet } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdLock } from "react-icons/io";
import { PiContactlessPaymentFill } from "react-icons/pi";
import SidePanelTaskCreator from '../../Common/SidePanelTaskCreator';
import TitleBar from '../../Common/TitleBar';
import DashboardHeader from '../../Common/DashboardHeader';
import DashboardFooter from '../../Common/DashboardFooter';
import { Dropdown } from 'primereact/dropdown';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import PhoneNumber from '../../Auth/PhoneNumber';
import RazrorPay from './RazrorPay';
import { displayRazorpay } from './RazrorPay';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FetchAmountdetails } from '../../../Redux/Actions/TaskCreatorAction';
import { FaSpinner } from 'react-icons/fa';
import img from "../../../images/amount-img.jpg"
const CreateAmount = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const token = useSelector(state => state.auth.token)
  const paymentkey = location.state.payment_id;
  const dispatch = useDispatch()

  const [selectedCountry, setSelectedCountry] = useState({ name: 'India', code: 'IN', phoneCode: '+91' });
  const [countries, setCountries] = useState([])
  const [loader, setLoader] = useState({
    fatch: false,
    save: false
  })
  const [data, setData] = useState({})
  const [isActive, setIsActive] = useState(true);
  const toggleClass = () => {
    setIsActive(!isActive);
  }
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="d-flex align-items-center ">
          <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
          <div className='ms-2'>{option.name} </div>

        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="d-flex align-items-center ">
        <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
        <div className='ms-2'>{option.name}  ({option.phoneCode})</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          'No country selected.'
        )}
      </div>
    );
  };
  let fetchAmouuntDeatils = () => {
    let formData = {
      "paymentkey": paymentkey

    }
    dispatch(FetchAmountdetails(formData, setData, loader, setLoader))

  }
  useEffect(() => {
    setLoader({
      ...loader,
      fetch: true
    })
    fetchAmouuntDeatils()
  }, [])

  return (
    <>
      <TitleBar title="Dashboard" />
      <div className="dashboard creator_dashboard  container-fluid overflow-hidden">
        <div className="row">
          <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
            <SidePanelTaskCreator />
          </div>
          <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
            <div>
              <div className="row">
                <div className="col-12 ps-0 pe-3">
                  <div className="dashboard-in">
                    <DashboardHeader toggleSidebar={toggleClass} title="Amount Payment" />
                    {
                      loader.fatch ? <FaSpinner className='spin ms-1 w-100 d-flex mt-4' style={{ fontSize: "40px" }} /> :

                        <div>
                          <div className={` p-0 ${isActive ? "col-lg-12 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                            <div className='d-flex create-payment p-5 align-items-center justify-content-center' style={{ backgroundColor: "#c7ccd647" }}>
                              <div className='container'>
                                <div className='row justify-content-center'>
                                  <div className='col-lg-12'>
                                    <div className='create-payment-bg'>
                                      <div className='row align-items-center justify-content-center'>
                                        <div className='col-lg-7'>
                                          <div className='position-absolute d-flex align-items-center gap-1 payment-logo' style={{ top: "28px" }}><PiContactlessPaymentFill style={{ fontSize: "50px", color: "#3532ea" }} />
                                            <span className='fw-bold fs-5 mb-0'>Payment</span>
                                          </div>
                                          {/* <form action="" className='mb-4'>
                                            <span style={{ fontSize: "18px" }} className='payment-heading mb-4 d-block'>Customer Information</span>
                                            <div className='inner-form mb-4'>
                                              <div className='user-name common-border mb-3'>Udit Sharma</div>
                                              <div className='row'>
                                                <div className='col-lg-6'>
                                                  <div class="input-group flex-nowrap phone_code_wrap" style={{ borderBottom: "1px solid #d6dce3" }}>
                                                    <div className="card d-flex border-0 justify-content-center phone_code">
                                                      <Dropdown
                                                        style={{
                                                          border: error?.status === false && error?.data?.type === "mobile" ? "1px solid red" : "",
                                                          borderRight: "0",
                                                          borderRadius: "8px",
                                                          borderTopRightRadius: "0",
                                                          borderBottomRightRadius: "0"
                                                        }}

                                                        value={selectedCountry}
                                                        onChange={(e) => setSelectedCountry(e.value)}
                                                        options={countries}
                                                        optionLabel="name"
                                                        placeholder="Country"
                                                        valueTemplate={selectedCountryTemplate}
                                                        itemTemplate={countryOptionTemplate}
                                                        className="w-full md:w-14rem border-0"
                                                        panelFooterTemplate={panelFooterTemplate}
                                                        dropdownIcon={(opts) => {
                                                          return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                                                        }} />



                                                    </div>
                                                    <div className="input-logo custom-width">
                                                      <input
                                                        type="tel"
                                                        className="login-input border-0" style={{ paddingBlock: "4px", paddingLeft: "0px" }}
                                                        placeholder="Phone number"
                                                        name="phone"
                                                        value={signInfo.phone}
                                                        onChange={(e) => {
                                                          const newValue = e.target.value.replace(/[^0-9]/g, '');
                                                          setSignInfo({ ...signInfo, phone: newValue });
                                                        }}
                                                        onBlur={() => handleBlur("mobile")}
                                                        maxLength={10}
                                                        pattern="[0-9]*"
                                                        inputMode="numeric"
                                                      />

                                                    </div>
                                                    {error?.status === false && error?.data?.type === "mobile" ?
                                                            <p className='mt-2' style={{ color: error?.data?.type === "mobile" && error?.status === false ? "red" : "" }}>{error.msg}</p>
                                                            : ""}
                                                  </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                  <p className='common-border mb-3 pb-2 fw-semibold'>Udit@example.com</p>
                                                </div>
                                                <div className='col-lg-12'>
                                              <div className='user-name common-border mb-3'>India</div>
                                            </div>
                                              </div>
                                              <div className='row'>
                                                <div className='col-lg-6'>
                                                  <p className='common-border mb-3 pb-2 fw-semibold'>Japiur</p>
                                                </div>
                                                <div className='col-lg-6'>
                                                  <p className='common-border mb-3 pb-2 fw-semibold'>002335</p>
                                                </div>
                                                <span className='fw-semibold'>433 Old Gate Ln, MIlford</span>
                                              </div>
                                            </div>

                                            <div className='mb-4'>
                                              <span className='mb-3 fw-semibold d-block' style={{ color: "rgba(28, 28, 28, 0.60)" }}>Payment Method</span>
                                              <div className='row'>
                                                <div className='col-lg-6 mb-3'>
                                                  <div className='d-flex align-items-center gap-2 fs-6 payment-card'>
                                                    <BiSolidBank
                                                      style={{ fontSize: "18px" }} />
                                                    <span className='payment-heading'>e-Transfer</span>
                                                  </div>
                                                </div>
                                                <div className='col-lg-6 mb-3'>
                                                  <div className='d-flex align-items-center gap-2 fs-6 payment-card'>
                                                    <GiWallet
                                                      style={{ fontSize: "18px" }} />
                                                    <span className='payment-heading'>Online</span>
                                                  </div>
                                                </div>

                                              </div>
                                            </div>
                                            <div className="add_new_task"><button className="theme-btn w-100 mt-2" style={{ padding: "10px 20px", fontSize: "16px", gap: "5px", color: "#fff" }} type="button" onClick={() => displayRazorpay()}>Make payment <IoIosArrowForward style={{ fontSize: "15px", marginTop: "3px" }} /></button>

                                            </div>
                                            <RazrorPay />
                                          </form> */}
                                          <img className='img-fluid' src={img} alt="" />
                                        </div>
                                        <div className='col-lg-5'>
                                          <div className='payment-description'>
                                            <div className='d-flex align-items-center pb-5 flex-column gap-2 border-bottom'>
                                              <span className='payment-heading mb-0 d-block'>Total Amount</span>
                                              <div className='payment-inner-head' style={{ color: "#3532ea", fontSize: "40px" }}>
                                                ₹ {data.totalamount}
                                                {/* <span style={{ color: "#acabeb" }}>76</span> */}
                                              </div>
                                              <div className='d-flex align-items-center gap-1'>
                                                <IoMdLock
                                                  style={{ fontSize: "16px", color: "#079a5d", marginTop: "-2px" }} />
                                                <small>Secure Payment</small>
                                              </div>
                                            </div>
                                            <div className='mt-4'>
                                              <span className='mb-2 d-block' style={{ fontWeight: "500" }}>Order Summary</span>
                                              <div className='d-flex flex-column gap-1 mb-3 order-summary'>
                                                <div className='d-flex justify-content-between mb-2'>
                                                  <span>Price per  submission:</span>
                                                  <span>₹ {data?.task_total_price}</span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                  <span className='text-secondary' style={{ fontWeight: "500" }}>No. of submission:</span>
                                                  <span> {data?.max_submission}</span>
                                                </div>
                                              </div>
                                              <div className='d-flex flex-column gap-1 mb-3 py-0 order-summary'>

                                                <div className='d-flex justify-content-between pb-2'>
                                                  <span style={{ fontWeight: "500" }} className='text-secondary'>Net pay</span>
                                                  <span>₹ {data?.task_total_price * data?.max_submission}</span>
                                                </div>
                                                <div className='d-flex justify-content-between mb-3'>
                                                  <span style={{ fontWeight: "500" }} className='text-secondary'>GST 18%</span>
                                                  <span>₹ {data?.tax}</span>
                                                </div>
                                              </div>
                                              <div className='d-flex flex-column gap-1 pt-1 order-summary border-0'>
                                                <div className='d-flex justify-content-between'>
                                                  <span style={{ fontSize: "17px" }}>Total</span>
                                                  <span style={{ fontSize: "20px", color: "#4340ea" }}>₹ {data.totalamount}</span>
                                                </div>
                                              </div>
                                              <div className="add_new_task"><button className="theme-btn w-100 mt-2" style={{ padding: "10px 20px", fontSize: "16px", gap: "3px", color: "#fff" }} type="button" onClick={() => displayRazorpay(data, paymentkey, token, navigation)}>Make payment <IoIosArrowForward style={{ fontSize: "15px", marginTop: "3px" }} /></button>

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    }
                    <DashboardFooter />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
}

export default CreateAmount;
