import React, { useEffect, useState } from 'react'
import { FiUpload } from "react-icons/fi";
import { FaCloudUploadAlt } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { LuMinus } from "react-icons/lu";
import { Dropdown } from 'primereact/dropdown';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { useDispatch } from 'react-redux';

const CreateUpload = (props) => {

    const dispatch = useDispatch()
    const [assets, setAssets] = useState([{ assets_name: '', assets_type: '', assets: null }]);
    const [selectType, setSelectType] = useState("")


    const handleChange = (index, e) => {
        const newAssets = [...assets];
        const { name, value } = e.target;
        newAssets[index][name] = value;

        setAssets(newAssets);
        let arr = [...props.data[name]]
        arr[index] = value
        props.setData({ ...props.data, [name]: arr })
        if (name === "assets_type") {

            setSelectType(value)
        }
    };



    const handleFileChange = (index, e) => {
        const newAssets = [...assets];
        if (e.target?.files[0]?.size > 200 * 1024 * 1024) {

            dispatch(setAlert("File size exceeds 200MB. Please upload a smaller file.", "danger"));
            return;
        }
        newAssets[index].assets = e.target.files[0];
        setAssets(newAssets);

        const name = 'assets';
        let arr = [...props.data[name]]
        arr[index] = e.target.files[0];
        props.setData({ ...props.data, [name]: arr })
    };

    const addAsset = () => {
        setAssets([...assets, { assets_name: '', assets_type: '', assets: null }]);
    };

    const removeAsset = (index) => {
        const newAssets = assets.filter((_, i) => i !== index);
        setAssets(newAssets);
        const newAssetsData = props?.data?.assets?.filter((_, i) => i !== index);
        const newAssetsName = props?.data?.assets_name?.filter((_, i) => i !== index);
        const newAssetsType = props?.data?.assets_type?.filter((_, i) => i !== index);

        props.setData({
            ...props.data,
            assets: newAssetsData,
            assets_name: newAssetsName,
            assets_type: newAssetsType,
        });


    };



    return (
        <div className="submission_wrap submission_bg mt-3 py-4">
            {assets.map((asset, index) => (
                <div className="row mb-3 mb-md-5" key={index}>
                    <div className="col-lg-6 mb-3">
                        <span className='mb-2 mb-md-2 d-block'>Assets Name</span>
                        <input
                            type="text"
                            name="assets_name"
                            value={asset.assets_name}
                            placeholder='Enter name'
                            disabled={!props.editing}
                            onChange={(e) => handleChange(index, e)}
                            style={{ border: "none", fontWeight: "500", width: "100%" }}

                        />
                    </div>
                    <div className="col-lg-6">
                        <span className='mb-2 mb-md-2 d-block'>Assets Type</span>
                        <div style={{ marginLeft: "-10px" }}>
                            <Dropdown
                                value={asset.assets_type}
                                onChange={(e) => handleChange(index, e)}
                                options={props.option6}
                                optionLabel="name"
                                placeholder="Select Type"
                                className="input ms-2 p-2 rounded-3 border-0"
                                name="assets_type"
                                disabled={!props.editing}
                            />
                        </div>

                    </div>
                    <div className={asset?.assets?.type ? "col-lg-9" : "col-lg-11"}>
                        <div className="create_upload mt-3 mb-3 mb-lg-0 d-flex flex-row gap-5">
                            <div className='d-flex flex-column gap-2 gap-md-0 align-items-center'>
                                <FaCloudUploadAlt style={{ fontSize: "50px", color: "#bf006e" }} />
                                <span className='fs-6 text-dark'>Upload Assets</span>
                                <span className='text-center'>JPEG, PNG, PDG, and MP4 formats, up to 200MB</span>
                                <input type="file" onChange={(e) => handleFileChange(index, e)} accept={selectType === "1" ? ".jpg, .jpeg, .png, .gif" : selectType === "2" ? "video/*" : "application/pdf"} disabled={!props.editing} />
                            </div>
                        </div>
                    </div>
                    <div className={asset?.assets?.type ? "col-lg-2" : "d-none"}>
                        <div className='preview-uploader'>
                            {asset.assets ? (
                                asset.assets.type.startsWith('image/') ? (
                                    <img src={URL.createObjectURL(asset.assets)} alt="Preview" className="img-fluid" />
                                ) : asset.assets.type.startsWith('video/') ? (
                                    <video controls src={URL.createObjectURL(asset.assets)} className="img-fluid" />
                                ) : asset.assets.type === 'application/pdf' ? (
                                    <iframe src={URL.createObjectURL(asset.assets)} className="img-fluid" title="PDF Preview" />
                                ) : (
                                    <span>Unsupported file type</span>
                                )
                            ) : (
                                <span>No file uploaded</span>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-1 d-flex flex-column align-items-center justify-content-center">
                        {index === 0 && (
                            <button
                                className='theme-btn upload-increment'
                                type='button'
                                disabled={!props.editing}
                                onClick={addAsset}
                                style={!props.editing ? { color: "#fff", background: "gray" } : {}}
                            >
                                <FiPlus style={{ color: "#fff" }} />
                            </button>
                        )}

                        {assets?.length > 1 && index > 0 && (
                            <button
                                className='theme-btn upload-increment'
                                type='button'
                                disabled={!props.editing}
                                onClick={() => removeAsset(index)}
                                style={!props.editing ? { color: "#fff", background: "gray" } : {}}
                            >
                                <LuMinus style={{ color: "#fff" }} />
                            </button>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CreateUpload
