import React, { useEffect, useRef, useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import NotificationBar from "../../Common/NotificationBar";
import { IoCopyOutline, IoSearchOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import TitleBar from '../../Common/TitleBar';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { Dropdown } from 'primereact/dropdown';
import { CiMobile3 } from "react-icons/ci";
import { useDispatch, useSelector } from 'react-redux';
import { fetchReferralQueryData } from '../../../Redux/Actions/EarningsAction';
import { FaSpinner } from 'react-icons/fa';
import Pagination from '../../Common/Pagination';
import { FaRupeeSign } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";



function Referral() {
    const referamount = useSelector(state => state.auth.user.referamount)
    const token = useSelector(state => state.auth.user.referToken)
    const [data, setData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false
    })
    const dispatch = useDispatch()
    const [right, setRight] = useState(false)
    const [filteredData, setFilteredData] = useState([]);
    const [searchInput, setSearchInput] = useState('')

    const baseURL = window.location.origin
    const url = `${baseURL}/referralauth?key=${token}`

    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    // const [isActive2, setIsActive2] = useState(false);
    // const toggleNotification = () => {
    //     setIsActive2(!isActive2);
    // };
    const [selectedCity, setSelectedCity] = useState(null);
    const cities = [
        { name: '10', code: '10' },
        { name: '20', code: '20' },
        { name: '30', code: '30' },
        { name: '40', code: '40' },
        { name: '50', code: '50' }
    ];
    const inputRef = useRef(null);
    const fetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(fetchReferralQueryData(setData, setLoader, loader))
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {

        if (data) {
            if (searchInput) {

                const newFilteredData = data.filter(val =>
                    String(val.name || '').includes(searchInput.toLowerCase()) ||
                    String(val.email || '').includes(searchInput.toLowerCase())
                );

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data);
            }
        }
    }, [searchInput, data]);
    const handleCopy = () => {
        const inputValue = inputRef.current.value;
        navigator.clipboard.writeText(inputValue)
            .then(() => {
                setRight(true)
            })
            .catch((err) => {
                setRight(false)
            });
    };
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });
    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);
    return (
        <>
            <TitleBar title="Referal" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Referral" />
                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="content-area-in creator_new_task" style={{ padding: "22px 35px" }}>
                                                <h5 className='mb-3'>Refer</h5>
                                                <div className='account-block mb-2'>
                                                    <div className='row'>
                                                        <div className='col-lg-9'>
                                                            <div className="submission_wrap submission_bg  mb-3 mb-lg-0">
                                                                <label htmlFor="" className="form-label fw-semibold mt-0">Referal link
                                                                </label>
                                                                <div className='d-flex align-items-center gap-3'>
                                                                    <input type="url"
                                                                        className=" w-100 border-0"
                                                                        id="exampleFormControlInput1"
                                                                        placeholder="https://www.youtube.com"
                                                                        name="url"
                                                                        value={url}
                                                                        ref={inputRef}
                                                                        defaultValue={url}
                                                                        readOnly
                                                                        style={{ backgroundColor: right === true ? "#f4f7fb" : "" }}
                                                                    />
                                                                    <button className='theme-btn px-4' onClick={handleCopy}>{right === true ? "Copied" : "Copy"}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-3'>
                                                            <div className='submission_wrap submission_bg  mb-3 mb-lg-0 d-flex flex-row gap-2 align-items-center justify-content-start'>

                                                                <div className='d-flex flex-column' style={{ gap: "3px" }}>
                                                                    <span style={{ fontWeight: "500", fontSize: "14px" }}>Referal Points
                                                                    </span>
                                                                    <h3 style={{ fontSize: "24px", padding: "0px" }}> ₹ {referamount || 0}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="account-block refer-table mt-4">
                                                    <div className="row">
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <h6 className=''>Refer User List
                                                            </h6>
                                                            {/* <div className="col">
                                                            <div className="" style={{ width: "fit-content" }}>
                                                                <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                                                    placeholder="10" className="" />
                                                            </div>
                                                        </div> */}
                                                            <div className="col-auto">
                                                                <div className="table-bar-right">
                                                                    <div className="header-search">
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Search"
                                                                            name="searchInput"
                                                                            value={searchInput}
                                                                            onChange={(e) => setSearchInput(e.target.value)}
                                                                        />
                                                                        <span className="left-icon"><IoSearchOutline /></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {

                                                            loader.fetch ? <div className='d-flex align-items-center justify-content-center'><FaSpinner className='spin ms-1' style={{ fontSize: "40px" }} /></div> :
                                                                <div className="table-responsive mt-2">
                                                                    <table className="table theme-table">
                                                                        <tr>
                                                                            <th>Name</th>
                                                                            <th>Email</th>
                                                                            <th>Mobile Number</th>
                                                                            <th>Status</th>
                                                                            <th> Account Status</th>
                                                                        </tr>
                                                                        {currentTodo.length > 0 ? currentTodo.map((curElem, i) => {
                                                                            return (
                                                                                <tr key={i}>
                                                                                    <td data-label="Name">{curElem.name}</td>
                                                                                    <td data-label="Email"><MdOutlineEmail style={{ marginTop: "-2px", fontSize: "18px", width: "20px", marginRight: "3px" }} />{curElem.email}</td>
                                                                                    <td data-label="Mobile Number"><CiMobile3 style={{ marginTop: "-2px", fontSize: "18px", width: "20px", marginRight: "3px" }} />
                                                                                        {curElem.mobileNumber}</td>
                                                                                    <td data-label="Status">{
                                                                                        <div className='badges' style={{ padding: "5px 20px", borderRadius: "50px", width: "fit-content", backgroundColor: curElem.isdeactivated === "0" ? "#def8ee" : "#f8d7da", color: curElem.isdeactivated === "0" ? "#4aa785" : "#721c24" }}>{curElem.isdeactivated === "0" ? "Active" : "Deactive"}</div>
                                                                                    }
                                                                                    </td>
                                                                                    <td data-label="Account Status">{
                                                                                        <div className='badges' style={{ padding: "5px 20px", borderRadius: "50px", width: "fit-content", backgroundColor: curElem.status === "0" ? "rgb(255, 243, 205)" : curElem.status === "1" ? " #def8ee" : "#f8d7da", color: curElem.status === "0" ? "rgb(133, 100, 4)" : curElem.status === "1" ? "#4aa785" : "721c24" }}>{curElem.status === "0" ? "Pending" : curElem.status === "1" ? "Active" : "Deactive"}</div>
                                                                                    }
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                            : <tr><td className='text-center py-3' colSpan={7}>{data.length === 0 ? "No histroy found" : ""}</td></tr>
                                                                        }

                                                                    </table>
                                                                </div>}
                                                    </div>

                                                    <Pagination
                                                        listData={filteredData}
                                                        pagination={pagination}
                                                        setPagination={setPagination}
                                                        currentTodo={currentTodo}
                                                        listArr={data}
                                                        loader={loader.fetch}
                                                    />
                                                </div>

                                            </div>

                                        </ScrollPanel>


                                        <div>
                                            <DashboardFooter />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                                <RecommendTasks />
                            </div> */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>




        </>
    )
}

export default Referral;