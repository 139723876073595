import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import SummerNote from '../../TaskCreator/CreatorTask/SummerNote';
import { LuSend } from "react-icons/lu";
import { FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { EmailNotificationFetch, EmailNotificationSend } from '../../../Redux/Actions/AdminNotificationList';
import { setAlert } from '../../../Redux/Actions/AlertActions';


const EmailNotification = () => {
    const [loader, setLoader] = useState({
        fetch: false,
        save: false
    })
    const dispatch = useDispatch()
    const [fetchData, setFetchData] = useState([])
    const [data, setData] = useState({
        "description": "",
        "subject": ""
    });
    const fetchEmail = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(EmailNotificationFetch("notification-status", setFetchData, setLoader, loader))
    }
    const onChangehandle = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            save: true
        })
        if (data.description === "") {
            dispatch(setAlert("Enter the message", "danger"))
            setLoader({
                ...loader,
                save: false
            })
            return;
        }
        if (data.subject === "") {
            dispatch(setAlert("Enter the subject`", "danger"))
            setLoader({
                ...loader,
                save: false
            })
            return;
        }
        let formData = {
            "subject": data.subject,
            "message": data.description
        }
        dispatch(EmailNotificationSend("send-message", formData, setLoader, loader, fetchEmail))
    }
    useEffect(() => {
        fetchEmail()
    }, [])

    return (
        <>
            <ScrollPanel className="custombar2 creator_new_task   content-area">
                <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>
                    <h5 className='my-3'>Notification</h5>
                    <form onSubmit={handleSubmit}>
                        <div className='account-block mb-4'>
                            <div className='submission_wrap submission_bg mb-4'>
                                <label htmlFor="" className="form-label">Subject</label>
                                <input style={{ padding: "8px" }}
                                    className="border-0 w-100"
                                    type="text"
                                    placeholder="Enter Subject "
                                    value={data.subject}
                                    name="subject"
                                    onChange={onChangehandle}
                                    required
                                />
                            </div>
                            <div className="submission_wrap submission_bg my-3 pt-3">
                       <label className='mb-2' htmlFor="">Task Description</label>
                                <SummerNote state={data} setState={setData} editing={true} />
                            </div>
                            <button style={{ width: "fit-content" }} className='theme-btn gap-1 ms-auto d-flex justify-content-end'><LuSend style={{ fontSize: "16px" }} type="submit" />
                                Send {loader.save ? <FaSpinner className="spin ms-1" /> : null}</button>
                        </div>
                    </form>
                    <div className='account-block pt-3'>
                        <div className="table-responsive mt-2">
                            <h6 className='mb-2'>Notification
                            </h6>
                            {loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px", height: "100vh" }} /></div> :
                                <table className="table theme-table">
                                    <tr>
                                        <th>Heading
                                        </th>
                                        <th>Total Mail
                                        </th>
                                        <th>Total Sended
                                        </th>
                                        <th >Send Percentage
                                        </th>
                                    </tr>
                                    {fetchData?.list?.length > 0 ? fetchData?.list?.map((curElem, i) => {
                                        let percentage = curElem.totaluser !== 0
                                            ? ((curElem.totalsend / curElem.totaluser) * 100).toFixed(2)
                                            : 0;
                                        return (
                                            < tr key={i}>
                                                < td data-label=">Heading">{curElem.subject}
                                                </td>
                                                <td data-label="Total Mail">{curElem.totaluser}</td>
                                                <td data-label="Total Sended">
                                                    {curElem.totalsend}

                                                </td>
                                                <td data-label="Send Percentage"> {percentage}%

                                                </td>
                                            </tr>
                                        )

                                    }) : <td className='text-center py-3' colSpan={7}>
                                        No data found
                                    </td>}
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </ScrollPanel >
        </>
    )
}

export default EmailNotification
