import React, { useEffect, useState } from 'react'
import TitleBar from '../../Common/TitleBar'
import SidePanelAdmin from '../../Common/SidePanelAdmin'
import DashboardHeader from '../../Common/DashboardHeader'
import { ScrollPanel } from 'primereact/scrollpanel'
import { IoSearchOutline } from 'react-icons/io5'
import DashboardFooter from '../../Common/DashboardFooter'
import { Dropdown } from 'primereact/dropdown';
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { useDispatch } from 'react-redux'
import { onBordingDelete, onBordingFetchData, onBordingSave } from '../../../Redux/Actions/AdminNotificationList'
import { FaSpinner } from 'react-icons/fa'
import { MdOutlineEdit } from 'react-icons/md'
import { RiDeleteBinLine } from 'react-icons/ri'
import SummerNote from '../../TaskCreator/CreatorTask/SummerNote'
const AdminOnboarding = () => {
    const dispatch = useDispatch()
    const [language, setLanguage] = useState({ name: 'Hindi', code: 'Hindi' })
    const [step, setStep] = useState({ name: 'Step-1', code: '1' })
    const [description, setDescription] = useState({
        "description": "",

    });
    const [data, setData] = useState([])
    const [url, setUrl] = useState("")
    const [deleteId, setDeleteId] = useState('')

    const [loader, setLoader] = useState({
        fetch: false,
        save: false,
        delete: false
    })
    const Langauge = [
        { name: 'Hindi', code: 'Hindi' },
        { name: 'English', code: 'English' },
    ]
    const Step = [
        { name: 'Step-1', code: '1' },
        { name: 'Step-2', code: '2' },
        { name: 'Step-3', code: '3' },

    ]
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    const fetchOnbording = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onBordingFetchData("fetch-onboarding", setData, setLoader, loader))

    }
    useEffect(() => {
        fetchOnbording()
    }, [])
    const handleSubmit = (e) => {
        e.preventDefault()
        if (url === "") {
            dispatch(setAlert("Please enter video url", "danger"))
            return;
        }
        if (description.description === "") {
            dispatch(setAlert("Please enter description", "danger"))
            return;
        }
        setLoader({
            ...loader,
            save: true
        })
        let formData = {
            "url": url,
            "description": description?.description,
            "language": language.code,
            "step": step.code
        }
        setDescription('')
        setUrl('')
        dispatch(onBordingSave("add-onboarding", formData, setLoader, loader, fetchOnbording))

    }
    const handleDelete = (curElem) => {
        setDeleteId(curElem.id)
        setLoader({
            ...loader,
            delete: true
        })
        let formData = {
            "id": curElem.id
        }
        dispatch(onBordingDelete("delete-onboarding", formData, setLoader, loader, fetchOnbording))
    }
    return (
        <>
            <TitleBar title="Onboarding" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Onboarding" />
                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="content-area-in" style={{ padding: "22px 35px" }}>
                                                <div className='creator_new_task h-100'>
                                                    <div className='account-block phone-emails mb-4'>
                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className='col-lg-12 mb-4'>
                                                                    <div className="submission_wrap submission_bg ">
                                                                        <label style={{ fontWeight: "500" }} for="" className="form-label">Url</label>
                                                                        <input style={{ fontSize: "14px" }} type="url" className="form-control border-0" id="exampleFormControlInput1" placeholder="Video url" name="url" value={url} onChange={(e) => setUrl(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6 mb-4'>
                                                                    <div className="submission_wrap submission_bg placeholder-grow">
                                                                        <label style={{ fontWeight: "500" }} className='form-label' htmlFor="">Language</label>
                                                                        <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} options={Langauge} optionLabel="name"
                                                                            value={language} onChange={(e) => setLanguage(e.value)}
                                                                            placeholder="Language" className="rounded-2 fs-2 border-0" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6 mb-4'>
                                                                    <div className="submission_wrap submission_bg placeholder-grow">
                                                                        <label style={{ fontWeight: "500" }} className='form-label' htmlFor="">Steps</label>
                                                                        <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} options={Step} optionLabel="name"
                                                                            value={step} onChange={(e) => setStep(e.value)}
                                                                            placeholder="step" className="rounded-2 fs-2 border-0" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-12 mb-4'>
                                                                    <div className='submission_wrap submission_bg py-3'>
                                                                        <div className='theme-focus-bg'>
                                                                            <label style={{ fontWeight: "500" }} className='form-label' htmlFor="">Description</label>
                                                                            <SummerNote state={description} setState={setDescription} editing={true} />
                                                                            {/* <textarea className="form-control border-0" id="" rows="6" placeholder='Description' name='description'
                                                                                value={description} onChange={(e) => setDescription(e.target.value)}
                                                                            ></textarea> */}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-12 d-flex'>
                                                                    <button className='theme-btn ms-auto' type="submit">Save {loader.save ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null}</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                <div className="refer-table mt-3 p-1">
                                                    <div className="row">
                                                        <div className='d-flex align-items-center justify-content-between'>
                                                            <h6>Onboarding list
                                                            </h6>
                                                        </div>
                                                        <div className="table-responsive mt-2">

                                                            <table className="table theme-table">
                                                                <tr>
                                                                    <th>S.No
                                                                    </th>
                                                                    <th>Step
                                                                    </th>
                                                                    <th>Langauge</th>
                                                                    <th>Url</th>
                                                                    <th>Description</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                {
                                                                    loader.fetch ? <td colSpan={5} className='text-center pt-3'><FaSpinner className="spin ms-1" style={{ fontSize: "42px" }} /></td> :
                                                                        data?.length > 0 ? data.map((curElem, i) => {
                                                                            return (

                                                                                < tr key={i} >
                                                                                    < td data-label="S.No">{i + 1}</td>
                                                                                    < td data-label="Step">{curElem.step}</td>
                                                                                    <td data-label="Langauge">{curElem.language}</td>
                                                                                    <td data-label="Url">
                                                                                        <a href={curElem.url} target='_blank'>{curElem.url}</a>
                                                                                    </td>
                                                                                    <td data-label="Description"> <p dangerouslySetInnerHTML={{ __html: curElem.description }} /></td>
                                                                                    <td data-label="Action" onClick={(e) => handleDelete(curElem)} style={{ cursor: "pointer" }}>
                                                                                        <div className="table-btns d-flex align-items-center gap-2">

                                                                                            <span className="table-delete" onClick={() => handleDelete(curElem.id)}>
                                                                                                {curElem.id === deleteId ? loader.delete ? <FaSpinner className="spin ms-1" /> : null : <RiDeleteBinLine style={{ width: "20px" }} />}
                                                                                            </span>
                                                                                        </div>


                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        }) : <td className='text-center pt-3' colSpan={7}>No data found</td>
                                                                }

                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollPanel>

                                        <div>
                                            <DashboardFooter />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                            <RecommendTasks />
                        </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* <RaisedQueryModal
                show={show}
                handleClose={handleClose}
            /> */}


        </>
    )
}

export default AdminOnboarding
