import React from 'react'
import { Modal } from "react-bootstrap";
import { ScrollPanel } from "primereact/scrollpanel";
import { FaSpider, FaSpinner } from "react-icons/fa";

const TaskPreferenceEditModal = (props) => {
   
  return (
<Modal className="modalRights creator_new_task theme-modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
<Modal.Header closeButton style={{ borderBottom: "0" }}>
</Modal.Header>
            <Modal.Body className='pt-0'>
                <div className='modalTitle ps-0 pb-2 mb-4' style={{height:"unset"}}>
                    <h6>Edit Category</h6>
                </div>

                <div className='account-block pt-2 mb-4'>
                    <div className="submission_wrap submission_bg mt-3 mb-3 alt">
                        <label className="label mb-2" htmlFor="">Name</label>
                        <input className="input border-0" type="text" placeholder="Enter Value" name="name" id="" value={props.edit} onChange={(e)=>props.setEdit(e.target.value)} />
                    </div>
                    <div className="modal-button-bottom mt-4" style={{background:"unset"}}>
                        <button style={{fontSize:"13px"}} className="theme-btn light"  type="button" onClick={props.handleClose}>Cancel</button>
                        <button className="theme-btn" type="button" onClick={props.handleEditSubmit}>Save{props.loader.edit?<FaSpinner className="spin" />:""}</button>
                    </div>

                </div>

            </Modal.Body>
        </Modal>
  )
}

export default TaskPreferenceEditModal
