import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useState } from 'react'
import SummerNote from '../../TaskCreator/CreatorTask/SummerNote';
import { FaSpinner } from 'react-icons/fa';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { useDispatch } from 'react-redux';
import { EmailMessageSend } from '../../../Redux/Actions/AdminNotificationList';
import { LuSend } from 'react-icons/lu';

const EmailNotification = () => {
    const [loader, setLoader] = useState({ save: false })
    const [data, setData] = useState({
        "description": "",
        "subject": ""

    });
    const dispatch = useDispatch()
    const onChangehandle = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            save: true
        })
        if (data.description === "") {
            dispatch(setAlert("Enter the message", "danger"))
            setLoader({
                ...loader,
                save: false
            })
            return;
        }
        if (data.subject === "") {
            dispatch(setAlert("Enter the subject`", "danger"))
            setLoader({
                ...loader,
                save: false
            })
            return;
        }
        let formData = {
            "subject": data.subject,
            "message": data.description
        }
        setData({
            ...data,
            description: "",
            subject: ""
        })


        dispatch(EmailMessageSend("create-cron-message", formData, setLoader, loader))
    }
    return (
        <>
            <ScrollPanel className="custombar2 creator_new_task   content-area">
                <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>
                    <h5 className='my-3'>Notification</h5>
                    <form onSubmit={handleSubmit}>
                        <div className='account-block'>
                            <div className='submission_wrap submission_bg mb-3'>
                                <label htmlFor="" className="form-label">Subject</label>
                                <input style={{ padding: "8px" }}
                                    className="border-0 w-100"
                                    type="text"
                                    placeholder="Enter the subject"
                                    value={data.subject}
                                    onChange={onChangehandle}
                                    name="subject"
                                    required
                                />
                            </div>
                            <div className="submission_wrap submission_bg my-4 py-3">
                                <label className='mb-2' htmlFor="">Message</label>
                                <SummerNote state={data} setState={setData} editing={true} />
                            </div>
                            <button style={{ width: "fit-content" }} className='theme-btn gap-1 ms-auto d-flex justify-content-end'><LuSend style={{ fontSize: "16px" }} type="submit" />
                                Send {loader.save ? <FaSpinner className="spin ms-1" /> : null}</button>
                        </div>
                    </form>
                </div>
            </ScrollPanel >
        </>
    )
}

export default EmailNotification
