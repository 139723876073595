import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { PaymentSettingAdminFetch, PaymentSettingAdminUpdate } from '../../../Redux/Actions/AdminManagerUserAction';

const Setting = () => {
    const dispatch = useDispatch()
    const [payment, setPayment] = useState({
        payment: "0"
    })
    const [loader, setLoader] = useState({
        fetch: true,
        save: false,
    });
    const handleChange = (e) => {

        const { name, value, checked } = e.target;

        setPayment({ ...payment, [name]: checked ? '1' : '0' })
    }
    const handleSubmit = () => {
        setLoader({
            ...loader,
            save: true
        })
        let data = {
            "status": payment.payment
        }
        dispatch(PaymentSettingAdminUpdate("change-setting", data, loader, setLoader))

    }
    const fetchData = () => {
        dispatch(PaymentSettingAdminFetch("get-setting", setPayment, loader, setLoader))
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <>
            <div className="account-block mb-3 mb-lg-5 ">
                <h6> Settings</h6>
                <div className="block-white mt-4">
                    {/* <h6>Payment</h6> */}
                    <div className="notification-cardN">
                        {loader.fetch ? <div className='d-flex align-items-center justify-content-center'><FaSpinner className='spin' style={{ fontSize: "22px" }} /></div> :
                            <div className="notification-card-single mt-0">
                                <div className="notification-card-txt">
                                    <h6>Payment accept setting</h6>
                                    <p>Enable to accept payment request</p>
                                </div>
                                <span className="custom-switch">
                                    <input type="checkbox" checked={payment.payment === '1'} name="payment" onChange={handleChange} />
                                    <span className="checkMark"></span>
                                </span>

                            </div>
                        }




                    </div>
                </div>
                <div className="btnGroup mt-4"><button type="button" className="theme-btn" onClick={handleSubmit}>Save {loader.save ? <FaSpinner className="spin ms-1" /> : null}</button></div>
            </div>
        </>
    )
}

export default Setting