import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';

import { IoSearchOutline } from "react-icons/io5";
import { PiUserCircleDuotone } from "react-icons/pi";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { FiUser, FiLock, FiUserPlus, FiStar, FiHelpCircle, FiLogOut } from "react-icons/fi";
import { logoutUser } from "../../Redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { CiSettings } from "react-icons/ci";
import { MdLogin } from "react-icons/md";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { MdOutlineAccountCircle } from "react-icons/md";


const DashboardHeader = ({ toggleSidebar, notificationToggle, title }) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [compName, setCompName] = useState("")

    const handleOut = () => {
        dispatch(logoutUser())
    }

    const auth = useSelector(state => state.auth.user.role)
    const isLoggedIn = useSelector(state => state.auth.token)

    useEffect(() => {
        if (isLoggedIn) {

            const script1 = document.createElement('script');
            script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-BM39S8WDBD';
            script1.async = true;
            document.head.appendChild(script1);


            const script2 = document.createElement('script');
            script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-BM39S8WDBD');
      `;
            document.head.appendChild(script2);
        }
    }, [isLoggedIn])
    return (
        <>
            <div className="dashboard-header ">
                <div className="dashboard-header-left">
                    <div className="dashboard-icons">
                        <ul>
                            <li onClick={toggleSidebar}><img src={require("../../images/icon/news.svg").default} alt="" /></li>
                            {/* <li><img src={require("../../images/icon/star.svg").default} alt="" /></li> */}
                        </ul>
                    </div>
                    <div className="dashboard-breabcrumb">
                        <ul>
                            <li style={{ color: "#000" }} >{title}</li>
                            {/* {location.pathname === "/dashboard" || compName === "" ? null :
                                <>
                                    <li>/</li>
                                    <li className="current">{compName}</li>
                                </>
                            } */}
                        </ul>
                    </div>
                </div>
                <div className="dashboard-header-right">
                    <div className="header-search">
                        <input type="text" placeholder="Search" />
                        <span className="left-icon"><IoSearchOutline /></span>
                    </div>
                    <div className="dashboard-icons">
                        <ul>
                            {/* <li><img src={require("../../images/icon/theme.svg").default} alt="" /></li>
                            <li><img src={require("../../images/icon/clock.svg").default} alt="" /></li> */}
                            {/* <li className="notifcation-badge position-relative" onClick={notificationToggle}><img className="position-relative" src={require("../../images/icon/notification.svg").default} alt="" />
                                <span>1</span>
                            </li> */}
                            {/* <li><img src={require("../../images/icon/news.svg").default} alt="" /></li> */}
                            <div className="profile-menu">
                                <Dropdown>
                                    <Dropdown.Toggle variant="successN" className="drop-btn">
                                        <PiUserCircleDuotone />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* <Link to="/my-account"><FiUser /> My Account</Link> */}
                                        {/* <Link to="/privacy"><FiLock /> Privacy</Link> */}
                                        {/* <Link to="/account-management"><FiUserPlus /> Account Management</Link> */}
                                        {/* <Link><FiSettings /> Settings</Link> */}
                                        {/* <Link to="/upgrade"><FiStar /> Upgrade</Link>
                                        <Link to="/help"><FiHelpCircle /> Help</Link> */}

                                        <Link to={auth === "Tasker" ? '/profile-settings' : auth === "Admin" ? "/admin/profile-settings" : "/task-creator/profile-settings"}><MdOutlineAccountCircle style={{ fontSize: "18px" }} />
                                            My account</Link>
                                        <Link to={auth === "Tasker" ? '/privacy-tasker' : auth === "Admin" ? "/privacy-admin" : "/privacy-nav"}><FiLock style={{ fontSize: "18px" }} />
                                            Privacy  </Link>
                                        <Link to={auth === "Tasker" ? '/faq' : auth === "Admin" ? "" : "/creator-faq"}><FiHelpCircle style={{ fontSize: "18px" }} />
                                            Help & Support  </Link>
                                        <Link onClick={handleOut}><MdLogin style={{ fontSize: "18px" }} />
                                            Logout</Link>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardHeader;