import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { IoCloseOutline } from "react-icons/io5";
import StarRatings from 'react-star-ratings';
import { FaSpinner } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { TaskerReviewSubmit } from '../../../Redux/Actions/TaskTaskerActions';
import { useNavigate } from 'react-router-dom';

const PendingFilesModel = (props) => {
const navigation=useNavigate()
    const dispatch = useDispatch()
    const [overRating, setOverRating] = useState(0)
    const [loader, setLoader] = useState(false)
    const [rating, setRating] = useState({
        // "clarity": 0,
        // "communication": 0,
        // "timely": 0,
        // "copperation": 0,
        // "professionlism": 0,
        // "instiuctions": 0,
        "availability": 0,
        "communication": 0,
        "deadline": 0,
        "copperation": 0,
        "skillset": 0,
        "work_quality": 0,


    })
    const handleChange = (newRating, name) => {
        setRating({ ...rating, [name]: newRating });
    }
    const handleOverRating = (newRating) => {
        setOverRating(newRating);
    };
    const [textfield, setTextfield] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        let formdata = {
            "reviewratings": rating,
            "message": textfield,
            "slug": props?.slug,
            "rating": overRating
        }
        dispatch(TaskerReviewSubmit(formdata, setLoader, props.handleClose,navigation))

    }
    return (
        <>
            <Modal className="theme-modal small-modal" show={props.show} onHide={props.handleClose} centered>
                <Modal.Body>
                    <span className="modal-close" onClick={props.handleClose}><IoCloseOutline /></span>
                    <div className="text-center">
                        <img src={require("../../../images/icon/chat.svg").default} alt="" />
                        <h3 className="pt-4">Submit Review</h3>
                        <p className="pt-2">You can also provide an overall rating here and write a detailed review describing your experience working with the Client</p>
                        <hr className="mt-4" />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="account-block">
                            <h6> Overall rating</h6>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Rating</div>
                                <div className="feedBlock-right">

                                    <StarRatings
                                        rating={overRating}
                                        starRatedColor="gold"
                                        // starEmptyColor="red"
                                        changeRating={handleOverRating}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='overRating'
                                    />
                                </div>
                            </div>
                            <h6 style={{ fontSize: "14px", marginTop: "10px" }}>Feedback to the client</h6>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Clarity of Requirements</div>
                                <div className="feedBlock-right">

                                    <StarRatings
                                        rating={rating.availability}
                                        starRatedColor="gold"
                                        // starEmptyColor="red"
                                        changeRating={handleChange}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='availability'
                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Communication</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.communication}
                                        starRatedColor="gold"
                                        // starEmptyColor="red"
                                        changeRating={handleChange}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='communication'
                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Timely Feedback</div>
                                <div className="feedBlock-right">

                                    <StarRatings
                                        rating={rating.deadline}
                                        starRatedColor="gold"
                                        // starEmptyColor="red"
                                        changeRating={handleChange}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='deadline'
                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Cooperation</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.copperation}
                                        starRatedColor="gold"
                                        // starEmptyColor="red"
                                        changeRating={handleChange}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='copperation'
                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Professionalism</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.skillset}
                                        starRatedColor="gold"
                                        // starEmptyColor="red"
                                        changeRating={handleChange}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='skillset'
                                    />
                                </div>
                            </div>
                            <div className="feedBlock">
                                <div className="feedBlock-left">Clear instructions</div>
                                <div className="feedBlock-right">
                                    <StarRatings
                                        rating={rating.work_quality}
                                        starRatedColor="gold"
                                        // starEmptyColor="red"
                                        changeRating={handleChange}
                                        starDimension="20px"
                                        starSpacing="3px"
                                        numberOfStars={5}
                                        name='work_quality'
                                    />
                                </div>
                            </div>
                            <hr className="mt-4" />
                            <h6>Write a review</h6>
                            <textarea className="input-style mt-2" name="textfield" id="" rows="5" value={textfield} onChange={(e) => setTextfield(e.target.value)}></textarea>
                            <div className="text-center mt-2">
                                <button className="theme-btn" type="submit">Submit Review {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PendingFilesModel