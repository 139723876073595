import React, { useState } from 'react';

import { ScrollPanel } from 'primereact/scrollpanel';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import TitleBar from '../../Common/TitleBar';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import DashboardHeader from '../../Common/DashboardHeader';
import DashboardFooter from '../../Common/DashboardFooter';
import Setting from './Setting';





const SettingTab = () => {
    const [isActive, setIsActive] = useState(false);
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    const [isActive2, setIsActive2] = useState(false);
    const toggleNotification = () => {
        setIsActive2(!isActive2);


    };


    return (
        <>
            <TitleBar title="Setting" />
            <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
                <SidePanelAdmin />

                <div className="dashboard-in">
                    <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="Payment setting" />
                    <ScrollPanel className="custombar2 content-area">
                        <div className="content-area-in tab-style">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                                <div className="tab-bar">
                                    <Nav variant="pillsb">
                                        <Nav.Item><Nav.Link eventKey="tab-1">Setting</Nav.Link></Nav.Item>


                                    </Nav>


                                </div>
                                <Tab.Content>
                                    <Tab.Pane eventKey="tab-1">
                                        <Setting />
                                    </Tab.Pane>

                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </ScrollPanel>
                    <DashboardFooter />
                </div>

            </div>

        </>
    )
}

export default SettingTab
