import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const fetchEarningsData = (setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("earnings", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}
export const fetchEarningsData1 = (url, formData, setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios(url, formData, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}
export const fetchRaisedData = (setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("raised-query-history", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}
export const closeQuery = (data, setLoader, loader, fetchData) => (dispatch, getState) => {

    commonAxios("close-query", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            fetchData()
            setLoader({
                ...loader,
                close: false
            })
        })
        .catch((err) => {
            console.log(err)
        })
}
export const fetchReferralQueryData = (setData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("referal-list", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                setData(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        })
        .catch((err) => {
            console.log(err)


        })
}


export const fetchBankDataAction = (formData, setData, setLoader, loader, setMessage) => (dispatch, getState) => {

    commonAxios("get-payment-details", formData, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setData(res.data)
            }
            else {
                setMessage(res)

            }
            setLoader({
                ...loader,
                fetch: false
            })
        })

        .catch((err) => {
            console.log(err)


        })
}

export const payOutRequestAction = (data, setLoader, loader, handleClose) => (dispatch, getState) => {

    commonAxios("payout-request", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                handleClose()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
                handleClose()

            }
            setLoader({
                ...loader,
                confirm: false
            })


        })
        .catch((err) => {
            console.log(err)



        })
}
export const raisedQueryAction = (data, setLoader, handleClose) => (dispatch, getState) => {

    commonAxios("raised-query", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {

                dispatch(setAlert(res.msg, "success"))
                handleClose()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
                handleClose()

            }
            setLoader(false)


        })
        .catch((err) => {
            console.log(err)



        })
}