import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import MyAchievements from "./MyAchievements";
import ReviewsReceivedTabs from "./ReviewsReceivedTabs";
import ReviewsGiven from "./ReviewsGiven";
import PendingReviews from "./PendingReviews";
import { FaSpinner } from "react-icons/fa";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import SidePanel from "../../Common/SidePanel";
import { ScrollPanel } from "primereact/scrollpanel";
import { useDispatch } from 'react-redux';
import { fetchAchivementData } from '../../../Redux/Actions/AchivementsActions';
import TitleBar from '../../Common/TitleBar';

function Achievements() {
   const dispatch = useDispatch()
   const [isActive, setIsActive] = useState(false);
   const toggleClass = () => {
      setIsActive(!isActive);
   };

   const [isActive2, setIsActive2] = useState(false);

   const toggleNotification = () => {
      setIsActive2(!isActive2);
   };

   const [loader, setLoader] = useState({
      fetch: true
   })
   const [data, setData] = useState([])

   //   fetchAchivementData
   const AchievenentData = () => {
      dispatch(fetchAchivementData(setData, setLoader, loader))
   }
   useEffect(() => {

      AchievenentData()
   }, [])
   
   return (
      <>
         <TitleBar title="Achievements" />
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
            <SidePanel />

            <div className="dashboard-in">
               <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="Achivements" />
               <ScrollPanel className="custombar2 content-area content-area-sidebar custom-footer" style={{ height: "calc(100vh - 4.3rem)" }}>
                  {loader.fetch ? <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}> <FaSpinner className="spin ms-1" style={{ fontSize: "30px" }} /></div> :

                     <div className="content-area-in tab-style">
                        <ScrollPanel>
                           <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                              <div className="tab-bar">
                                 <Nav variant="pillsb">
                                    <Nav.Item><Nav.Link eventKey="tab-1">Reviews Received</Nav.Link></Nav.Item>
                                    <Nav.Item><Nav.Link eventKey="tab-2">Reviews Given</Nav.Link></Nav.Item>
                                    {/* <Nav.Item><Nav.Link eventKey="tab-3">Pending Reviews</Nav.Link></Nav.Item> */}
                                 </Nav>
                              </div>
                              <Tab.Content>
                                 <Tab.Pane eventKey="tab-1">
                                    <ReviewsReceivedTabs data={data?.recievedreview.allreview} loader={loader} totalreview={data?.recievedreview.totalreview} averagerating={data?.recievedreview.averagerating} />
                                 </Tab.Pane>
                                 <Tab.Pane eventKey="tab-2">
                                    <ReviewsGiven data={data?.givenreview} loader={loader} />
                                 </Tab.Pane>

                                 {/* <Tab.Pane eventKey="tab-3">
                              <PendingReviews />
                           </Tab.Pane> */}
                              </Tab.Content>
                           </Tab.Container>
                        </ScrollPanel>
                        <MyAchievements data={data?.other} loader={loader} />
                     </div>
                  }
                  <DashboardFooter />
               </ScrollPanel>
            </div>
         </div>

      </>
   )
}
export default Achievements;