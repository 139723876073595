import React, { useEffect, useState, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useDispatch } from 'react-redux';

const SummerNote = ({ state, setState, editing }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const editorRef = useRef(null);
    const isProgrammaticUpdateRef = useRef(true);

    const handleChange = (val) => {
        setState({
            ...state,
            description: val
        });
    };

    const editorConfiguration = {
        toolbar: ['heading', '|', 'fontSize', 'bold', 'italic', '|', 'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo']
    };



    return (
        <>

            <CKEditor
                editor={DecoupledEditor}
                data={state.description || ''}
                config={editorConfiguration}
                disabled={!editing}
                onReady={(editor) => {
                    editor.ui.getEditableElement().parentElement.insertBefore(
                        editor.ui.view.toolbar.element,
                        editor.ui.getEditableElement()
                    );
                    editorRef.current = editor;

                    isProgrammaticUpdateRef.current = true;
                }}
                onChange={(event, editor) => {
                    if (isProgrammaticUpdateRef.current) {
                        isProgrammaticUpdateRef.current = false;
                        return;
                    }

                    const data = editor.getData();
                    handleChange(data);
                }}
                onError={(error, { willEditorRestart }) => {
                    if (willEditorRestart) {
                        editorRef.current.ui.view.toolbar.element.remove();
                    }
                }}
            />
        </>
    );
};

export default SummerNote;
