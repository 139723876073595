import React, { useEffect, useState } from 'react'
import TitleBar from '../../Common/TitleBar';
import SidePanel from '../../Common/SidePanel';
import DashboardHeader from '../../Common/DashboardHeader';
import { ScrollPanel } from 'primereact/scrollpanel';
import DashboardFooter from '../../Common/DashboardFooter';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import ViewTaskUserDetails from './ViewTaskUserDetails';
import ViewTaskSubmission from './ViewTaskSubmission';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { taskSubmissionDetailsAdmin } from '../../../Redux/Actions/AdminSettingsActions';

const TaskSubmissionProfile = () => {
    const location = useLocation()

    const userId = location?.state?.slug
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({

        fetch: false
    })
    const [isActive, setIsActive] = useState(true);
    const [data, setData] = useState({
        "userdetail": [],
        "taskdetail": [],
        "proof": [],
        "asssets": []
    })
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const fetchTaskDatails = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "submission_id": userId
        }
        dispatch(taskSubmissionDetailsAdmin(formData, setData, loader, setLoader))
    }
    useEffect(() => {

        fetchTaskDatails()

    }, [userId])

    return (
        <>
            <TitleBar title="task-submission" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="row">
                            <div className=" col-xl-10 col-lg-8 ps-0 pe-0">
                                <div className="dashboard-in main_task_wrapper">
                                    <DashboardHeader toggleSidebar={toggleClass} title="Task Details" />
                                    <ScrollPanel className="custombar2 content-area">
                                        <div className="content-area-in tab-style">
                                            <ViewTaskSubmission taskdetail={data?.taskdetail[0]} proof={data?.proof} asssets={data?.asssets} loader={loader} />
                                        </div>
                                    </ScrollPanel>
                                </div>
                                <div className="d-none d-lg-block">
                                    <DashboardFooter />
                                </div>
                            </div>
                            <div className=" col-xl-2 col-lg-4 ps-0">
                                <ViewTaskUserDetails userDetails={data?.userdetail[0]} loader={loader} />
                            </div>
                            <div className="d-block d-lg-none ps-0">
                                <DashboardFooter />
                            </div>

                        </div>
                    </div>

                </div>
            </div>



        </>
    )
}

export default TaskSubmissionProfile
