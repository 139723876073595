
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { FaSpider, FaSpinner } from "react-icons/fa";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";


import { FaRegCheckCircle } from "react-icons/fa";

import { RxCross1 } from "react-icons/rx";
import { setAlert } from '../../Redux/Actions/AlertActions';
import { useDispatch } from 'react-redux';
import { UpdatePasswordForget } from '../../Redux/Actions/AuthActions';
function SetPasswordModal(props) {
    const [view, setView] = useState(false)
    const [view1, setView1] = useState(false)
    const regpass = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,16}$/;
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()

    const [signInfo, setSignInfo] = useState({
        password: "",
        confirmPassword: "",
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        setSignInfo({
            ...signInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        if (regpass.test(signInfo?.password) === false) {
            dispatch(setAlert("Use 8 or more characters with a mix of letters, numbers & symbols ", "danger"));
            setLoader(false)
        }
        else if (signInfo.password !== signInfo.confirmPassword) {
            dispatch(setAlert("password and confirm password is not equel", "danger"))
            setLoader(false)
        }
        else {
            const signData = {
                "hash": props.hash,
                "password": signInfo?.password,
                "confirmpassword": signInfo?.confirmPassword,

            }
            // console.log("xfcjh",signData)
            // props.handleClose()
            dispatch(UpdatePasswordForget(signData, setLoader, props.handleClose))

        }
    }

    return (
        <>
            <Modal className="theme-modal creator_new_task forgotpass-modal link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                        {/* <span><MdOutlineArrowBackIos /></span> */}
                        <h3 className="m-auto" style={{ fontSize: "30px" }}>Set New Password</h3>
                        {/* <span onClick={props.handleClose}><RxCross1 /></span> */}
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-card mt-4 pt-1">
               <div className='account-block mb-0 pb-2 pt-0'>
               <div className='link_checkbox flex-column d-flex align-items-center my-4'>
                                <h4 className='m-auto'>Change Password</h4>
                                <small>Don’t share your password with anyone else</small>
                            </div>

                            <div className="submission_wrap submission_bg mt-3 mb-3 position-relative" style={{minHeight:"70px"}}>
                                <div className='input-logo'>
                                    <input
                                        className="login-input border-0"
                                        type={view ? "text" : "password"}
                                        placeholder="Password"
                                        required
                                        name="password"
                                        value={signInfo.password}
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="inp-icon"
                                        onMouseUp={() => setView(false)}
                                        onMouseLeave={() => setView(false)}
                                        onMouseDown={() => setView(true)}

                                    >
                                        <IoEyeOffOutline />
                                    </span>
                                </div>
                            </div>
                            {/* <div className='password-tracker'>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div> */}
                            <small className='d-flex align-items-center justify-content-center mt-3'>Use 8 or more characters with a mix of letters, numbers & symbols.</small>
                            <div className="submission_wrap submission_bg mt-3 mb-3 position-relative" style={{minHeight:"70px"}}>
                                <div className='input-logo'>
                                    <input
                                        className="login-input border-0"
                                        type={view1 ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        required
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        value={signInfo.confirmPassword}
                                    />
                                    <span
                                        className="inp-icon"
                                        onMouseUp={() => setView1(false)}
                                        onMouseLeave={() => setView1(false)}
                                        onMouseDown={() => setView1(true)}
                                    >
                                        <IoEyeOffOutline />
                                    </span>
                                </div>
                            </div>
               </div>
                            <div className="link_btn mt-3 text-end">
                                <button className='theme-btn saveBtn' type="submit" style={{ padding: "12px 24px" }}>Update Password {loader ? <FaSpinner className="spin ms-1" /> : null}</button>
                            </div>
                        </div>
                    </form>

                </Modal.Body>
            </Modal >
        </>
    )
}

export default SetPasswordModal
