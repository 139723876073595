import React, { useEffect, useState, useRef } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';

const CommonStepperContent = ({ handleEnded, data }) => {

    const [data3, setData3] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('Hindi');
    const videoRef = useRef(null);


    useEffect(() => {

        const filteredData = data?.step?.find((curElem) => curElem.language === selectedLanguage);
        setData3(filteredData || null);
    }, [data, selectedLanguage]);


    useEffect(() => {
        if (videoRef.current && data3?.url) {
            videoRef.current.load();
        }
    }, [data3]);

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    return (
        <>
            <div className="account-block mb-4 mt-4" style={{ minHeight: "490px" }}>
                <div style={{ position: 'relative', width: '100%', height: '40vh' }}>

                    <div className="d-flex step-btn align-items-center gap-2 mb-4">
                        <button
                            className={`theme-btn ${selectedLanguage === 'Hindi' ? 'step-active' : 'step-inactive'}`}
                            onClick={() => handleLanguageChange('Hindi')}
                        >
                            Hindi
                        </button>
                        <button
                            className={`theme-btn ${selectedLanguage === 'English' ? 'step-active' : 'step-inactive'}`}
                            onClick={() => handleLanguageChange('English')}
                        >
                            English
                        </button>
                    </div>


                    {data3?.url ? (
                        <video
                            ref={videoRef}
                            width="100%"
                            height="100%"
                            controls
                            // autoPlay
                            onEnded={handleEnded}
                            style={{ border: 'none', display: 'block' }}
                        >
                            <source src={data3.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <p>No video available for the selected language.</p>
                    )}
                </div>
            </div>


            <div className="account-block">
                <h6 className="mb-2">Description:</h6>
                <ScrollPanel style={{ height: '100px' }}>
                    {data3?.description ? (
                        <p dangerouslySetInnerHTML={{ __html: data3.description }} />
                    ) : (
                        <p>No description available for the selected language.</p>
                    )}
                </ScrollPanel>
            </div>
        </>
    );
};

export default CommonStepperContent;
