import React, { useEffect, useState } from 'react'
import { MdOutlineWatchLater } from "react-icons/md";
const CountDownTimer = ({ expireTime }) => {
    const [timeLeft, setTimeLeft] = useState("");

    useEffect(() => {
        const calculateTimeLeft = () => {
            const currentTime = new Date();
            const targetTime = new Date(expireTime);

            const difference = targetTime - currentTime;

            if (difference > 0) {
                const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, "0");
                const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, "0");
                const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, "0");

                setTimeLeft(`${hours}:${minutes}:${seconds}`);
            } else {
                setTimeLeft("00:00:00");
            }
        };


        calculateTimeLeft();
        const timer = setInterval(calculateTimeLeft, 1000);


        return () => clearInterval(timer);
    }, [expireTime]);

    return <span style={{ color: "#000" }}>{timeLeft}</span>;


}

export default CountDownTimer