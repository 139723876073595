import React, { useState } from 'react';
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import SidePanelAdmin from '../../Common/SidePanelAdmin';
import TitleBar from '../../Common/TitleBar';
import EmployeeStatic from './EmployeeStatic';
import SocialMediaStatic from './SocialMediaStatic';
import { IoStatsChartOutline } from "react-icons/io5";



const StaticsNav = () => {
   const [isActive, setIsActive] = useState(false);
   const toggleClass = () => {
      setIsActive(!isActive);
   };

   const [isActive2, setIsActive2] = useState(false);
   const toggleNotification = () => {
      setIsActive2(!isActive2);


   };


   return (
      <>
         <TitleBar title="Statistics " />
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
            <SidePanelAdmin />

            <div className="dashboard-in">
               <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="Statistics " />
               <ScrollPanel className="custombar2 content-area" >
                  <div className="content-area-in tab-style" style={{ height: "100vh" }}>
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">Employee Statistics </Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-2">Social Media Statistics </Nav.Link></Nav.Item>
                           </Nav>
                        </div>
                        <Tab.Content className='pt-0'>
                           <Tab.Pane eventKey="tab-1">
                              <EmployeeStatic />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-2">
                              <SocialMediaStatic />
                           </Tab.Pane>
                        </Tab.Content>
                     </Tab.Container>
                  </div>
               </ScrollPanel>
               <DashboardFooter />
            </div>

         </div>

      </>
   )
}

export default StaticsNav
