import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import edit from '../../../images/icon/PencilLine.svg'
import CurrencyDollar from '../../../images/CurrencyCircleDollar.svg'
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { fetchBankDataAction, payOutRequestAction } from '../../../Redux/Actions/EarningsAction';
import { FaSpinner } from 'react-icons/fa';
import { setAlert } from '../../../Redux/Actions/AlertActions';

const PayoutRequestModal = (props) => {
    const [filter, setFilter] = useState('');
    const [selectBank, setSelectBank] = useState('Select Bank');
    const [bankDetails, setbankDetails] = useState([])
    const [message, setMessage] = useState({})
    const [input, setInput] = useState("")
    const [loader, setLoader] = useState({
        fetch: false,
        confirm: false
    })

    const dispatch = useDispatch()


    const fetchBankData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "method": filter
        }

        dispatch(fetchBankDataAction(formData, setbankDetails, setLoader, loader, setMessage))
    }
    const handleChange = (e) => {
        const { value } = e.target;

        setInput(value);


    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            confirm: true
        })
        const numericInput = parseFloat(input);

        if (isNaN(numericInput) || numericInput < 100) {
            dispatch(setAlert("The minimum withdraw amount is ₹100", "danger"))
            setLoader({
                ...loader,
                confirm: false
            })

            return;
        } else if (numericInput > props.avaiable) {
            dispatch(setAlert(`The maximum withdraw amount is ${props.avaiable} ₹`, "danger"))
            setLoader({
                ...loader,
                confirm: false
            })

            return;
        } else
            if (filter !== "upi" && filter !== "bank" && filter !== "stripe" && filter !== "paypal") {
                dispatch(setAlert("Please select a valid withdrawal method", "danger"))
                setLoader({
                    ...loader,
                    confirm: false
                })

                return;
            } else if (message.status === false) {
                if (filter === "upi") {
                    dispatch(setAlert("Please provied your UPI details ", "danger"))
                    setLoader({
                        ...loader,
                        confirm: false
                    })
                    setMessage({})
                }
                else if (filter === "bank") {
                    dispatch(setAlert("Please  provied your bank details", "danger"))
                    setLoader({
                        ...loader,
                        confirm: false
                    })
                    setMessage({})
                }
                else if (filter === "stripe") {
                    dispatch(setAlert("Please  provied your stripe details", "danger"))
                    setLoader({
                        ...loader,
                        confirm: false
                    })
                    setMessage({})
                }
                else {
                    dispatch(setAlert("Please  provied your paypal details", "danger"))
                    setLoader({
                        ...loader,
                        confirm: false
                    })
                    setMessage({})
                }
            }
            else {

                let formData = {
                    "withdrawn_amount": input,
                    "withdrawn_mode": filter
                }

                dispatch(payOutRequestAction(formData, setLoader, loader, props.handleClose))
            }

    }
    useEffect(() => {
        if (filter) {
            fetchBankData()
        }

    }, [filter])

    const numericInput = parseFloat(input) || 0;
    const remainingBalance = Math.max(props.avaiable - numericInput, 0);

    const parsebankDeials = JSON.parse(bankDetails.data || null)

    return (
        <>
            <Modal className="theme-modal Payout_request_wrap" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className="Payout_request_modal">
                        <div className="Payout_request_img mb-2">
                            <img src={CurrencyDollar} alt="" />
                        </div>
                        <h3 className="text-center">Request For Payout </h3>
                        <p className="text-center pt-2">The weekly billing period begins Monday at 00:00 midnight and ends Sunday at 23:59 UTC. Because we’ve freelancers and clients from all across globe.</p>
                        <div className="modal-card mt-4 bg-transparent">
                            <form onSubmit={handleSubmit}>
                                <div className="Payout_request_modal">
                                    <div className="account-block mt-2">
                                        <h6>Withdrawal Details</h6>
                                        <ul className='mt-2'>
                                            <li>
                                                <p>Available Balance</p>
                                                <span>₹{props?.avaiable}</span>
                                            </li>
                                            <li>
                                                <p>Withdraw Amount</p>
                                                <input className='border-0 w-50 withdraw-amount-inp' type='number'
                                                    name="input" value={input}
                                                    onChange={handleChange}
                                                    min="100"
                                                    placeholder="Enter amount" />

                                            </li>
                                            <li>
                                                <p>Withdrawal Method</p>
                                                <Dropdown className='withdraw-dropdown'>
                                                    <Dropdown.Toggle variant="" id="dropdown-basic" className='border py-1 px-3 border-muted'>
                                                        {filter === "upi" ? "UPI" : filter === "bank" ? "Bank" : filter === "stripe" ? "Stripe" : "Paypal"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className='py-0'
                                                            onClick={() => {
                                                                setFilter("upi")
                                                                setMessage({})
                                                            }
                                                            }>UPI</Dropdown.Item>
                                                        <Dropdown.Item className='py-0'
                                                            onClick={() => {
                                                                setFilter("bank")
                                                                setMessage({})
                                                            }
                                                            }>Bank</Dropdown.Item>
                                                        <Dropdown.Item className='py-0'
                                                            onClick={() => {
                                                                setFilter("stripe")
                                                                setMessage({})
                                                            }}>Stripe</Dropdown.Item>
                                                        <Dropdown.Item className='py-0' onClick={() => {
                                                            setFilter("paypal")
                                                            setMessage({})
                                                        }}>Paypal</Dropdown.Item>

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </li>
                                            {message.status === false ?
                                                <li>
                                                    <ul className='w-100 mt-0'>
                                                        <li className='d-flex justify-content-center my-2 ' style={{ color: "red" }}>{message.msg}</li>
                                                    </ul>
                                                </li> : ""
                                            }
                                            {
                                                filter === "upi" ?
                                                    <li>
                                                        <ul className='w-100 mt-0'>
                                                            <li>
                                                                <p>UPI ID:</p>
                                                                <span>{parsebankDeials?.upiId}</span>
                                                            </li>
                                                            <li>
                                                                <p>UPI Name</p>
                                                                <span>{parsebankDeials?.upiName}</span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : ""
                                            }
                                            {filter === "stripe" || filter === "paypal" ? <ul className='w-100 mt-0'><li>
                                                <p>Email</p>
                                                <span>{bankDetails?.account}</span>
                                            </li></ul> : ""}
                                            {
                                                filter === "bank" ?
                                                    <li>
                                                        <ul className='w-100 mt-0'>
                                                            <li>
                                                                <p>Bank Name</p>
                                                                <span>{parsebankDeials?.bank_name}</span>
                                                            </li>
                                                            <li>
                                                                <p>IFSC Code</p>
                                                                <span>{parsebankDeials?.ifsc_code}</span>
                                                            </li>
                                                            <li>
                                                                <p>Account Holder Name</p>
                                                                <span>{parsebankDeials?.account_holder_name}</span>
                                                            </li>
                                                            <li>
                                                                <p>Account Number</p>
                                                                <span>{parsebankDeials?.account_no}</span>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    : ""}


                                            <hr className='bottom_line' style={{ opacity: "0.1", color: "#000000", borderWidth: "2px" }} />
                                            <li>
                                                <p>Remaining Balance</p>
                                                <span>₹{remainingBalance.toFixed(2)}</span>
                                                <div className="payout_img">
                                                    {/* {/ <img src={edit} alt="" />  /} */}
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="application_btn text-center mt-5 mb-3">
                                    <button className='theme-btn' type="submit">Confirm Request {loader.confirm ? <FaSpinner className="spin ms-1" /> : null}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default PayoutRequestModal
