import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
import { AiOutlineDollarCircle } from 'react-icons/ai'
import { GoDotFill } from 'react-icons/go'
import { IoSearchOutline } from 'react-icons/io5'
import { FaSpinner } from 'react-icons/fa'
import AdminAPproveModal from '../AdminModal/AdminApproveModal'
import AdminRejectModal from '../AdminModal/AdminRejectModal'
import { FetchAdminPayOut } from '../../../Redux/Actions/AdminPayOutAction'
import { useDispatch } from 'react-redux'
import CusstomPagination from '../../Common/CusstomPagination'

const ApprovePayout = () => {
    const [searchInput, setSearchInput] = useState('')
    const [limit, setLimit] = useState({ name: '20', code: '20' })
    const limited = [
        { name: '20', code: '20' },
        { name: '100', code: '100' },
        { name: '200', code: '200' },
        { name: '500', code: '500' },
        { name: '1000', code: '1000' }
    ];
    const dispatch = useDispatch()
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [data, setData] = useState([])
    const [producatId, setProducatId] = useState('')
    const [showModel, setShowModel] = useState(false)
    const handleShow = () => setShowModel(true)
    const handleClose = () => setShowModel(false)
    const [showModel1, setShowModel1] = useState(false)
    const handleShow1 = () => setShowModel1(true)
    const handleClose1 = () => setShowModel1(false)
    const [totalLength, setTotalLength] = useState(0);
    const [loader, setLoader] = useState({
        fetch: false
    })
    const handlesort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (data.pending_request) {
            if (searchInput) {

                const newFilteredData = filteredData?.filter(val => {
                    const name = (val.name || '').toLowerCase();
                    const amount = String(val.amount || '').toLowerCase();
                    const id = String(val.id || '').toLowerCase();
                    const searchTerm = searchInput.toLowerCase();

                    return name.includes(searchTerm) ||
                        amount.includes(searchTerm) ||
                        id.includes(searchTerm);
                });

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data?.pending_request);
                setTotalLength(data?.total_count)
            }
        } else {
            setFilteredData([])
        }
    }, [searchInput, data]);


    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 20
    });

    const handleAccept = (pId) => {

        handleShow()
        setProducatId(pId)
    }
    const handleDecline = (pId) => {
        handleShow1()
        setProducatId(pId)
    }
    const fetchdata = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "page": pagination.currentPage || 1,
            "limit": limit.code
        }
        dispatch(FetchAdminPayOut("fetch-pending-payout", formData, loader, setLoader, setData))
    }
    useEffect(() => {
        fetchdata()
        setPagination({
            ...pagination,
            totalItemOnPage: Number(limit?.code) || 20
        })
    }, [pagination.currentPage, limit?.code])

    return (
        <>
            {
                loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4" style={{ height: "100vh" }}><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :

                    <ScrollPanel className="custombar2 content-area pt-4 manage-task-footer">
                        <div className="main_task_details ps-4 pe-4 pb-4">
                            <div className="do_task_wrapper ">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="account-block mt-2 mb-0">
                                            <h6>Payouts</h6>
                                            <div className="d-flex task_main_wrap">
                                                <div className="task_process">
                                                    <span>Total Payouts</span>

                                                    <div className="progress_wrap" style={{ position: "relative" }}>
                                                        <h3 style={{ fontWeight: "700" }}> ₹ {data?.payout?.totalpayout}</h3>
                                                    </div>
                                                </div>
                                                <div className="task_process ps-3 pe-3">
                                                    <span className="text-center w-100">Approved</span>
                                                    <div className="task_process_bar text-center">
                                                        <h3>{data?.payout?.totalapproved}</h3>
                                                    </div>
                                                </div>
                                                <div className="task_process ps-3 pe-3">
                                                    <span className="text-center w-100">Approval Pending</span>
                                                    <div className="task_process_bar text-center">
                                                        <h3>{data?.payout?.totalpending}</h3>
                                                    </div>
                                                </div>
                                                {/* <div className="task_process ps-3 pe-3 ">
                                            <span className="text-center w-100">Scheduled</span>
                                            <div className="task_process_bar text-center">
                                                <h3>$1,550</h3>
                                            </div>
                                        </div> */}
                                                <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                                    <span className="text-center w-100">Rejected</span>
                                                    <div className="task_process_bar text-center">
                                                        <h3>{data?.payout?.totalrejected}</h3>
                                                    </div>
                                                </div>
                                                <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                                    <span className="text-center w-100">Request Payout</span>
                                                    <div className="task_process_bar text-center">
                                                        <h3>{data?.payout?.totalrequestpayout}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-bar">
                                            <div className="table-bar-left">
                                                <div className="table-bar-icon">
                                                    <div className="table-nav">
                                                        <div className="profile-menu">

                                                        </div>
                                                        {/* <div className="profile-menu">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="successN" className="drop-btn">
                                                            <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                            <li className="drop-list">
                                                                <span className="custom-check">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="agentType"
                                                                    />
                                                                    <span className="checkMark"></span>
                                                                </span>
                                                                dolor sit amet
                                                            </li>
                                                            <li className="drop-list">
                                                                <span className="custom-check">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="agentType"
                                                                    />
                                                                    <span className="checkMark"></span>
                                                                </span>
                                                                dolor sit amet.
                                                            </li>


                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div> */}
                                                    </div>


                                                    <ul>
                                                        <li title="Sort by Date" onClick={handlesort}>
                                                            <img src={require("../../../images/icon/sort.svg").default} alt="" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="table-bar-right">
                                                <div className='d-flex align-items-center gap-3 bar-btns'>
                                                    <div className='task-dropdown' style={{ width: "40%" }}>
                                                        <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} value={limit} onChange={(e) => setLimit(e.value)} options={limited} optionLabel="name"
                                                            placeholder="Select a Limit" className="rounded-2 border-0" />
                                                    </div>
                                                    <div className="header-search">
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            name="searchInput"
                                                            value={searchInput}
                                                            onChange={(e) => setSearchInput(e.target.value)}

                                                        />

                                                        <span className="left-icon"><IoSearchOutline
                                                        /></span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row mt-1 g-4">
                                {
                                    filteredData.length > 0 ? filteredData.map((curElem, i) => {
                                        const payment_detail = JSON.parse(curElem.payment_detail || null)

                                        return (
                                            <div className="col-xl-6 col-xxl-4 col-lg-6 col-12" key={i}>
                                                <div className='account-block mb-1 approve-payout'>
                                                    <div className='account-header'>
                                                        <div className='d-flex align-items-center gap-1'>
                                                            <AiOutlineDollarCircle className='dollar-icon' />
                                                            <h6 style={{ marginRight: "auto", fontSize: "14px" }}> Users's Withdraw Request</h6>
                                                        </div>
                                                        <span className='timing'> <GoDotFill style={{ color: "#9FA1A2", fontSize: "10px" }} />
                                                            {curElem.created}</span>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <span>Available Balance</span>
                                                            <span>₹{curElem.balance}</span>
                                                        </li>
                                                        <li>
                                                            <span>Withdraw Amount</span>
                                                            <span>₹{curElem.amount}</span>
                                                        </li>
                                                        <li>
                                                            <span>Withdrawl Method</span>
                                                            <span>{curElem.payment_mode}</span>
                                                        </li>
                                                        <li>
                                                            <span>Withdrawl Fee</span>
                                                            <span>₹0.00</span>
                                                        </li>

                                                    </ul>
                                                    <div className="application_btn withdraw-btn mt-2">
                                                        <button className='theme-btn blue' type="button" onClick={() => handleAccept(curElem.id)}>Accept Application</button>
                                                        <button className='theme-btn indigo' type="button" onClick={() => handleDecline(curElem.id)}>Decline Request</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : <div className='d-flex align-items-center justify-content-center'>{data.length === 0 ? "No History Found" : ""}</div>
                                }

                            </div>
                            <div className='mb-4 mt-4'>
                                {/* <Pagination

                                    listData={filteredData}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    currentTodo={currentTodo}
                                    listArr={data}
                                    loader={props.loader.fetch}
                                /> */}
                                <CusstomPagination
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    totalLength={totalLength}
                                    loader={loader.fetch}
                                />

                            </div>

                        </div>

                        <AdminAPproveModal show={showModel} handleClose={handleClose} producatId={producatId} type="payout" fetchdata={fetchdata} />
                        <AdminRejectModal show={showModel1} handleClose={handleClose1} producatId={producatId} type="payout" fetchdata={fetchdata} />
                    </ScrollPanel>
            }
        </>
    )
}

export default ApprovePayout
