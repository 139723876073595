import React, { useState } from 'react'
import listImg from '../../../images/PPImage.png'
import Submission from '../../../images/icon-2/Submission.svg'
import application from '../../../images/icon-2/application.svg'
import CursorClick from '../../../images/icon-2/CursorClick.svg'
import MoneyWavy from '../../../images/icon-2/MoneyWavy.svg'
import { BsCalendar4Event } from "react-icons/bs";
import { IoIosHeartEmpty, IoIosStarOutline } from 'react-icons/io';
import { TbPointFilled } from "react-icons/tb";
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch } from 'react-redux'
import { onDeleteTask } from '../../../Redux/Actions/TaskCreatorAction'


const TasksCard = ({ curlElem, setSelectedId, selectedId, fetchPublishTask }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [selectedDelete, setSelectedDelete] = useState('')
    const handleCardClick = () => {
        setSelectedId(curlElem.id);
        navigate("/creator/details", { state: { "id": curlElem } });
    };
    const handleDelete = (deleteId) => {
        let data = {
            "slug": deleteId
        }
        dispatch(onDeleteTask(data, fetchPublishTask))
    }
    const calculateDateDifference = (createdDate) => {
        const now = new Date();
        const created = new Date(createdDate);
        const diffInMs = now - created;

        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

        if (diffInDays > 0) {
            return `${diffInDays} days ago`;
        } else if (diffInHours > 0) {
            return `${diffInHours} hours ago`;
        } else {
            return `${diffInMinutes} minutes ago`;
        }
    };

    return (
        <div >
            <div className="task_section mt-3" key={curlElem.id}>
                <div className="d-flex justify-content-between mobile-card">
                    <div className="task_list_wrap">
                        <div className="task_list_img">
                            <img src={curlElem.profile ? curlElem.profile : listImg} alt="" />
                        </div>
                        <div className="list_head_wrapper">
                            <div className="list_head">
                                <h3>{curlElem.title}</h3>
                                <span><TbPointFilled style={{ fontSize: '10px' }} /> {calculateDateDifference(curlElem.created)}</span>
                                <div className='earn_status' style={{ backgroundColor: +curlElem.status === 0 ? "#fff3cd" : +curlElem.status === 1 ? "#DEF8EE" : "#f8d7da", color: +curlElem.status === 0 ? "#856404" : +curlElem.status === 1 ? "#4AA785" : "#721c24" }}>{+curlElem.status === 0 ? "Pending" : +curlElem.status === 1 ? "Approved" : +curlElem.status === 2 ? "Rejected" : ""}</div>
                            </div>
                            <div className="list_head_bottom">
                                <div className="head_info">
                                    <span>Price Submission - ₹{curlElem.price}</span>
                                    <BsCalendar4Event className='ms-4' />
                                    <span>Due Date: {curlElem.due_date}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className='d-flex align-items-center ps-2 ps-md-0 gap-1 text-center justify-content-start justify-content-md-start' style={{ cursor: "pointer", fontSize: "13px" }} onClick={handleCardClick}>
                        <MdOutlineEdit />
                        Edit Details
                    </span>
                    {/* <div className="task_like">
                            <IoIosHeartEmpty />
                        </div> */}
                </div>
                <div className="head_para text-center text-md-start">
                    {/* <p>{curlElem.description}</p> */}
                    <div dangerouslySetInnerHTML={{ __html: curlElem.description }} />
                </div>
                <div className="task_tag_wrap">
                    <div className="task_tag" >
                        <span>{curlElem.level_name}</span>
                    </div>
                    <div className="task_tag" >
                        <span>{curlElem.category_name}</span>
                    </div>
                </div>
                <div className="task_person_info">
                    <div className="task_verification">
                        <img src={application} alt="" />
                    </div>
                    <div className="task_verification">
                        <img src={Submission} alt="" />
                        <span>{curlElem.type === "limited" ? curlElem.max_allowed_submission : "Unlimited"}Submissions</span>
                    </div>
                    <div className="task_verification">
                        <img src={MoneyWavy} alt="" />
                    </div>
                    <div className="task_verification">
                        <img src={CursorClick} alt="" />
                    </div>
                    <div style={{ marginLeft: "auto", fontSize: "20px", cursor: "pointer", color: "red" }}>
                        <RiDeleteBinLine onClick={() => handleDelete(curlElem.slug)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TasksCard
