import React, { useEffect, useState } from 'react';
import TitleBar from '../../Common/TitleBar';
import SidePanel from '../../Common/SidePanel';
import DashboardHeader from '../../Common/DashboardHeader';
import { ScrollPanel } from 'primereact/scrollpanel';
import DashboardFooter from '../../Common/DashboardFooter';
import Tab from 'react-bootstrap/Tab';
import { Nav } from 'react-bootstrap';
import { FaRegPaperPlane } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { fetchAllMessage, fetchMessageAction, sendMessageAction } from '../../../Redux/Actions/ChatMessage';
import { FaSpinner } from 'react-icons/fa6';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { MdSearchOff } from "react-icons/md";

const TaskerChat = () => {
    const [isActive, setIsActive] = useState(true);
    const [activeTab, setActiveTab] = useState('');
    const [sendMessage, setSendMessage] = useState('')
    const [allMessage, setAllMessage] = useState({})
    const dispatch = useDispatch()
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const [data, setData] = useState({})
    const [loader, setLoader] = useState({
        fetch: false,
        create: false,
        save: false
    })
    const fetchMessage = () => {
        setLoader({
            ...loader,
            fetch: true,

        })
        let formData = {}
        dispatch(fetchMessageAction("fetch-message", formData, setData, loader, setLoader))
    }
    const handleCreateMessage = (curElem) => {
        setActiveTab(curElem)
        setLoader({
            ...loader,
            create: true,

        })
        let formData = {
            "id": curElem
        }
        dispatch(fetchAllMessage("fetch-all-message", formData, setAllMessage, loader, setLoader))
    }

    const handleSubmitMeassage = (e) => {
        e.preventDefault()
        if (sendMessage === "") {
            dispatch(setAlert("Please type message", "danger"))
            return;
        }
        setLoader({
            ...loader,
            save: true,

        })
        let formData = {
            "comment": sendMessage,
            "id": activeTab
        }
        dispatch(sendMessageAction("create-message", formData, setSendMessage, loader, setLoader, handleCreateMessage))

    }
    useEffect(() => {
        fetchMessage()
    }, [])

    return (
        <>
            <TitleBar title="Chat" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={`p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Chat" />

                                        <div className="content-area-in px-2 py-0" style={{ height: "100%" }}>
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                <div className="row">
                                                    <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-12">
                                                        <ScrollPanel style={{ height: '150px' }}>
                                                            <div className='chat-sidebar'>
                                                                <Nav className='d-flex flex-column gap-2 shadow-sm shadow-sm chat-tabs' variant="pills" style={{ padding: "22px 20px" }}>
                                                                    <h6 className='my-2'>Messages</h6>
                                                                    {
                                                                        loader.fetch ?
                                                                            <div className="d-flex align-items-center justify-content-center "><FaSpinner className="spin custom-spin ms-1" style={{ fontSize: "20px" }} /></div> :

                                                                            data?.list?.length > 0 ? data?.list?.map((curElem, i) => {

                                                                                return (
                                                                                    < div className="step-btn" key={i}>
                                                                                        <button className={curElem.kyc_id === activeTab ? 'theme-btn w-100 mb-2' : " theme-btn step-inactive mb-2  w-100 "} onClick={(e) => handleCreateMessage(curElem.kyc_id)}>Message({curElem.kyc_id})</button>
                                                                                    </div>)
                                                                            }) : <div>No message found</div>

                                                                    }
                                                                    {/* <Nav.Item>
                                                                        <Nav.Link eventKey="first">Message({data.list.kyc_id})</Nav.Link>
                                                                    </Nav.Item> */}
                                                                </Nav>

                                                            </div>
                                                        </ScrollPanel>

                                                    </div>
                                                    <div className="col-xxl-9 ps-0 pe-1 col-xl-8 col-lg-8 col-md-12">
                                                        <div className="chat-content">

                                                            {loader.create ?
                                                                <div className="d-flex align-items-center justify-content-center " style={{ height: "100vh" }}><FaSpinner className="spin custom-spin ms-1" style={{ fontSize: "30px" }} /></div> :


                                                                <div className="chat-card">
                                                                    {
                                                                        activeTab ? <div className="card-header account-block">
                                                                            <img
                                                                                className="img-fluid"
                                                                                src="https://backend.dotasks.in/public/uploads/profile/user.png"
                                                                                alt=""
                                                                            />
                                                                            <div className="d-flex flex-column gap-1">
                                                                                <span style={{ fontWeight: "600", lineHeight: "12px", fontSize: "16px" }}>Message({activeTab || 123})</span>
                                                                                {/* <span style={{ fontSize: "12px" }}>Online</span> */}
                                                                            </div>
                                                                        </div> : ""
                                                                    }
                                                                    <ScrollPanel className='chat-scroller' style={{ width: '100%' }}>
                                                                        <div className='card-body'>
                                                                            <ul>
                                                                                {allMessage.length > 0 ? allMessage.map((curElem, i) => {
                                                                                    return (
                                                                                        <>
                                                                                            {curElem.comment_by !== "Tasker" ?
                                                                                                <li className='sender'>
                                                                                                    <div>
                                                                                                        <img
                                                                                                            className="img-fluid"
                                                                                                            src="https://backend.dotasks.in/public/uploads/profile/user.png"
                                                                                                            alt=""
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='d-flex flex-column' style={{ gap: "6px" }}>
                                                                                                        <span className='sender-message'>{curElem.comment}</span>
                                                                                                        <small style={{ color: "#1c1c1ccc" }}>{curElem.created}</small>
                                                                                                    </div>
                                                                                                </li> :
                                                                                                <li className='sender justify-content-end replyer'>
                                                                                                    <div className='d-flex flex-column' style={{ gap: "6px" }}>
                                                                                                        <span className='sender-message'>{curElem.comment}</span>
                                                                                                        <small style={{ color: "#1c1c1ccc" }}>{curElem.created}</small>
                                                                                                    </div>
                                                                                                    <img
                                                                                                        className="img-fluid"
                                                                                                        src={curElem.profile ? curElem.profile : "https://images.unsplash.com/photo-1519058082700-08a0b56da9b4?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}

                                                                                                        alt=""
                                                                                                    />

                                                                                                </li>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }) : <div className='d-flex justify-content-center align-items-center chat-notext'><MdSearchOff style={{ fontSize: "70px", color: "#f10065" }} />

                                                                                    No message found</div>}



                                                                            </ul>
                                                                        </div>
                                                                    </ScrollPanel>
                                                                    {
                                                                        activeTab ? <div className='chat-box-footer account-block rounded-0'>
                                                                            <form onSubmit={handleSubmitMeassage} >
                                                                                <div className='submission_wrap d-flex gap-2 submission_bg '>
                                                                                    <input placeholder='Type a message here...' style={{ borderRadius: "50px", paddingInline: "20px", fontSize: "15px" }} className='form-control chat-input' type="text" name="sendMessage" value={sendMessage} onChange={(e) => setSendMessage(e.target.value)} />
                                                                                    <button className='theme-btn send-chat-btn' type="submit">{loader.save ? <FaSpinner className='spin ' /> : <FaRegPaperPlane style={{ fontSize: "16px" }} />}
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </div> : ""
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                            </Tab.Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </>
    );
};

export default TaskerChat;
