import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CommonStepperContent from './CommonStepperContent';
import { useDispatch, useSelector } from 'react-redux';
import { stepTaskerAction } from '../../Redux/Actions/TaskerDashboardAction';
import { commonAxios } from '../../Global/CommonAxios';
import { setAlert } from '../../Redux/Actions/AlertActions';

function UserStepper(props) {
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const authStep = useSelector(state => state.auth.user.step);


    const [steps, setSteps] = useState({});
    const [loader, setLoader] = useState({ fetch: false });
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepsData, setStepsData] = useState([{}, {}, {}]);

    const fetchStepData = () => {
        setLoader({ ...loader, fetch: true });
        dispatch(stepTaskerAction(setSteps, loader, setLoader));
    };

    const handleEnded = () => {
        if (activeIndex < stepsData.length - 1) {
            if (activeIndex + 1 <= authStep) {

                let updatedData = [...stepsData];
                if (activeIndex === 0) updatedData[1] = { step: steps.step2 };
                if (activeIndex === 1) updatedData[2] = { step: steps.step3 };

                setStepsData(updatedData);
                setActiveIndex(prev => +prev + 1);
                return;
            }

            let updatedData = [...stepsData];


            let formData = { step: +activeIndex + 1 };

            commonAxios("complete-onboarding", formData, {}, token)
                .then((res) => {
                    if (res.status) {
                        dispatch(setAlert(res.msg, "success"));
                        if (activeIndex === 0) updatedData[1] = { step: steps.step2 };
                        if (activeIndex === 1) updatedData[2] = { step: steps.step3 };

                        setStepsData(updatedData);
                        setActiveIndex(prev => +prev + 1);
                        dispatch({ type: 'UPDATE_STEP', payload: +res.data.step });
                    } else {
                        dispatch(setAlert(res.msg, "danger"));
                    }
                    setLoader({ ...loader, fetch: false });
                })
                .catch(() => setLoader({ ...loader, fetch: false }));
        } else {
            let formData = { step: +activeIndex + 1, onboarding: "1" };
            commonAxios("complete-onboarding", formData, {}, token).then((res) => {
                if (res.status) {
                    dispatch(setAlert(res.msg, "success"));
                    dispatch({ type: 'UPDATE_STEP', payload: +res.data.step });
                    dispatch({ type: 'ONBOARDING_STEP', payload: +res.data.onboarding });

                    props.handleClose();

                } else dispatch(setAlert(res.msg, "danger"));
                setLoader({ ...loader, fetch: false });
            });
        }
    };



    useEffect(() => {
        fetchStepData();
    }, []);

    useEffect(() => {
        if (steps?.step1 || steps?.step2 || steps?.step3) {
            let updatedData = [...stepsData];
            updatedData[0] = { step: steps.step1 };
            updatedData[1] = { step: steps.step2 };
            updatedData[2] = { step: steps.step3 };
            setStepsData(updatedData);
        }
    }, [steps]);

    useEffect(() => {
        if (authStep !== null && authStep !== undefined) {
            setActiveIndex(authStep);
        }
    }, [authStep]);



    return (
        <Modal
            className="theme-modal Payout_request_wrap step-modal"
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            centered
        >
            <Modal.Body>
                <div className="p-3 theme-stepper">

                    <ul className="d-flex align-items-center justify-content-between" style={{ gap: "6px" }}>
                        {[1, 2, 3].map((step, index) => (
                            <li key={index} className="user-stepper">
                                <div
                                    className="d-flex align-items-center flex-column"
                                    onClick={() => {
                                        if (index <= authStep) setActiveIndex(index);
                                    }}
                                    style={{ cursor: authStep >= index ? "pointer" : "not-allowed" }}
                                >
                                    <span className={+activeIndex === index ? "user-stepper-num active-step" : "user-stepper-num inactive-step"} style={{ cursor: authStep >= index ? "pointer" : "not-allowed" }}>
                                        {step}
                                    </span>
                                    <span className="user-stepper-heading">Step {step}</span>
                                </div>
                                {index < 2 && (
                                    <span className={authStep >= index + 1 ? "user-stepper-line active-step" : "user-stepper-line inactive-step"}></span>
                                )}
                            </li>
                        ))}
                    </ul>


                    {+activeIndex === 0 && authStep >= 0 && (
                        <CommonStepperContent handleEnded={handleEnded} data={stepsData[0]} />
                    )}
                    {+activeIndex === 1 && authStep >= 1 && (
                        <CommonStepperContent handleEnded={handleEnded} data={stepsData[1]} />
                    )}
                    {+activeIndex === 2 && authStep >= 2 && (
                        <CommonStepperContent handleEnded={handleEnded} data={stepsData[2]} />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default UserStepper;
