import React, { useEffect, useState } from "react";
import { PiWarningCircleBold } from "react-icons/pi";
import SidePanel from "../../../Common/SidePanel";
import DashboardHeader from "../../../Common/DashboardHeader";
import { ScrollPanel } from "primereact/scrollpanel";
import AboutClient from "./AboutClient";
import { Nav, Tab } from 'react-bootstrap';
import PayoutRequestModal from "../../ModalComponents/PayoutRequestModal";
import AvailableTasks2 from "./TaskModuleComponent/AvailableTasks2";
import TaskSubmitModal from "../../ModalComponents/TaskSubmitModal";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TaskTaskerDetails } from "../../../../Redux/Actions/TaskTaskerActions";
import { FaRegFilePdf, FaSpider, FaSpinner } from "react-icons/fa";
import SubmitTaskModal from "../../ModalComponents/SubmitTaskModal";
import { MdCancel, MdOutlineAddPhotoAlternate, MdOutlineVideoLibrary } from "react-icons/md";
import DashboardFooter from "../../../Common/DashboardFooter";
import { MdContentCopy } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

const TaskDetails = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const [show, setShow] = useState(false);
    const [right, setRight] = useState(false)
    const dispatch = useDispatch()
    const [taskDetails, setTaskDetails] = useState('')
    const [loader, setLoader] = useState({
        create: false,

    })
    const location = useLocation()
    const slugId = location.state.slug



    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    useEffect(() => {
        let formData = {
            "slug": slugId
        }
        setLoader({
            create: true
        })
        dispatch(TaskTaskerDetails(formData, setTaskDetails, setLoader))
    }, [slugId])

    const handleViewFile = (curElem) => {

        if (curElem.url) {
            const a = document.createElement('a');
            a.href = curElem.url;
            a.target = '_blank';
            a.download = curElem.name || 'downloaded_file';
            a.click();

        }
    }
    const handleCopy = (copy, type) => {

        navigator.clipboard.writeText(copy)
            .then(() => {
                setRight(type)
            })
            .catch((err) => {
                setRight(false)
            });
    };
    return (
        <>

            <div className="dashboard container-fluid overflow-hidden">

                <div className="row">

                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>

                        <SidePanel type="url" />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>

                        <div>
                            <div className="row">
                                <div className=" col-xl-9 col-lg-8 ps-0 pe-0">
                                    <div className="dashboard-in main_task_wrapper">

                                        <DashboardHeader toggleSidebar={toggleClass} title="Task Details" />

                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="main_task_details p-4 pt-2">
                                                <div className="do_task_wrapper ">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                                <div className="row" >
                                                                    <div className="col-12 mb-3">
                                                                        <Nav variant="pills" style={{ padding: "10px 28px 0px 6px" }} >
                                                                            <Nav.Item>
                                                                                <Nav.Link eventKey="first" > Task Details</Nav.Link>
                                                                            </Nav.Item>
                                                                            <Nav.Item>
                                                                                {/* <Nav.Link eventKey="second">My Submission</Nav.Link> */}
                                                                            </Nav.Item>
                                                                        </Nav>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <div className="setting_right_side_wrap">
                                                                            <Tab.Content>
                                                                                <Tab.Pane eventKey="first">
                                                                                    {

                                                                                        loader.create ?
                                                                                            <div className="d-flex align-items-center justify-content-center mt-4 " style={{ height: "100vh" }}><FaSpinner className="spin custom-spin ms-1" style={{ fontSize: "40px" }} /></div> : <>

                                                                                                <div className="col-12 ">
                                                                                                    <div className="account-block mt-2">
                                                                                                        <h6>{taskDetails.title}</h6>
                                                                                                        <div className="d-flex task_main_wrap  ">
                                                                                                            <div className="task_process">
                                                                                                                <span>Status</span>
                                                                                                                {/* <div className="task_process_bar">
                                                                        <p>Progress <span>/</span> 51%</p>
                                                                    </div> */}
                                                                                                                <div className="progress_wrap" style={{ position: "relative" }}>
                                                                                                                    <h3>{taskDetails.status === "1" ? <div style={{ fontSize: "15px", backgroundColor: "#C6C7F8", width: "fit-content", padding: "5px 20px", borderRadius: "50px" }}>InProgress</div> : taskDetails.status === "2" ? <div style={{ backgroundColor: "#DEF8EE", color: "#4AA785", width: "fit-content", padding: "5px 20px", borderRadius: "8px" }}>Complete</div> : <div style={{ backgroundColor: "#fff3cd", color: "#856404", width: "fit-content", padding: "5px 20px", borderRadius: "8px" }}>Inactive</div>}</h3>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            {/* <div className="task_process ps-3 pe-3">
                                                                                                            <span className="text-center w-100">Total Submission</span>
                                                                                                            <div className="task_process_bar text-center">
                                                                                                                <p>{taskDetails.total_submission}%</p>
                                                                                                            </div>
                                                                                                        </div> */}
                                                                                                            <div className="task_process ps-3 pe-3">
                                                                                                                <span className="text-center w-100">Due Date</span>
                                                                                                                <div className="task_process_bar text-center">
                                                                                                                    <p>{taskDetails.due_date}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="task_process ps-3 pe-3 " style={{ border: "0" }}>
                                                                                                                <span className="text-center w-100">Price</span>
                                                                                                                <div className="task_process_bar text-center">
                                                                                                                    <p>₹{taskDetails.price}  </p>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="account-block mb-3 mb-md-5 mt-4">
                                                                                                    <div className="task_border pb-2">
                                                                                                        <h6 className="mb-3">Tasks Overview</h6>
                                                                                                        <div className="task_overview_sec">
                                                                                                            <h3 className="mb-3">{taskDetails.title} ( #TSK000{taskDetails.id} )</h3>
                                                                                                            {/* <p>
                                                                                                            We are seeking a talented Social Media Manager with a knack for creating engaging and captivating Instagram Reels. The ideal candidate will have a strong understanding of social media trends, content creation, and audience engagement. This role involves managing our social media accounts, developing content strategies, and interacting with our followers to build.<br />
                                                                                                            Link : <a href="#">https://www.instagram.com/dotask6/</a>
                                                                                                        </p> */}
                                                                                                            <div
                                                                                                                dangerouslySetInnerHTML={{ __html: taskDetails?.description }}
                                                                                                            />
                                                                                                            {taskDetails?.caption || taskDetails?.hashtag ? <p>
                                                                                                                <span style={{ fontWeight: "500" }}>Caption</span>: {taskDetails?.caption || "N/A"}
                                                                                                                <br />
                                                                                                                <span style={{ fontWeight: "500" }}>HashTag</span>: {taskDetails?.hashtag || "N/A"}
                                                                                                                <span
                                                                                                                    style={{ padding: "8px 10px" }}
                                                                                                                    className="theme-btn ms-2 ms-md-4 mt-2 mt-md-0"
                                                                                                                    onClick={() => handleCopy(`  ${taskDetails?.caption || ""} ${taskDetails?.hashtag || ""} ${taskDetails?.primary_hashtag || ""}`, "captionHashtag")}
                                                                                                                >
                                                                                                                    {right === "captionHashtag" ? (
                                                                                                                        <IoMdCheckmark style={{ fontSize: "14px" }} />
                                                                                                                    ) : (
                                                                                                                        <MdContentCopy style={{ fontSize: "14px" }} />
                                                                                                                    )}
                                                                                                                </span>
                                                                                                            </p> : ""}

                                                                                                            {taskDetails?.url ? <p><span style={{ fontWeight: "500" }}>Url</span>: {taskDetails?.url === "null" ? "Not applicable" : taskDetails?.url}{taskDetails?.url !== "null" ? <span style={{ padding: "8px 10px" }} className="theme-btn ms-2 ms-md-4 mt-2 mt-md-0 " onClick={() => handleCopy(taskDetails?.url, "url")}>{right === "url" ? <IoMdCheckmark style={{ fontSize: "14px" }} /> : <MdContentCopy style={{ fontSize: "14px" }} />}</span> : ""}</p> : ""}
                                                                                                            <div className='submission_wrap p-3 pt-0 ps-0 icon-boxes-main d-flex flex-row justify-content-start gap-3 '>
                                                                                                                {
                                                                                                                    taskDetails?.attachment?.length > 0 ? taskDetails?.attachment?.map((curElem, index) => {


                                                                                                                        return (
                                                                                                                            <div className='account-block mb-0 icon-boxes mt-2' key={index} style={{ background: "#fff" }}>
                                                                                                                                <span className='icon-box-cross'>
                                                                                                                                    {/* <MdCancel style={{ color: "#afa4a8" }} /> */}
                                                                                                                                </span>
                                                                                                                                <span onClick={() => handleViewFile(curElem)}>
                                                                                                                                    {curElem.attachmenttype === "PDF" ? <FaRegFilePdf style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : curElem.attachmenttype === "Image" ? <MdOutlineAddPhotoAlternate
                                                                                                                                        style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : <MdOutlineVideoLibrary
                                                                                                                                        style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} />}
                                                                                                                                </span>
                                                                                                                                <p>{curElem.name}</p>
                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    }) : <div className='d-flex align-items-center justify-content-center w-100'>No attachment file</div>
                                                                                                                }



                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="col-12 mt-4 text-center mb-2">
                                                                                                        <button style={{ fontSize: "16px" }} className="theme-btn" onClick={handleShow}> Submit Task </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>



                                                                                    }

                                                                                </Tab.Pane>

                                                                                <Tab.Pane eventKey="second">
                                                                                    {/* <AvailableTasks2 /> */}
                                                                                </Tab.Pane>
                                                                                <Tab.Pane eventKey="third">
                                                                                </Tab.Pane>
                                                                            </Tab.Content>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Tab.Container >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ScrollPanel>
                                    </div>
                                    <div className="d-none d-lg-block">
                                        <DashboardFooter />
                                    </div>
                                </div>
                                <div className=" col-xl-3 col-lg-4 ps-0">
                                    <AboutClient aboutClient={taskDetails} />
                                </div>
                                <div className="d-block d-lg-none ps-0">
                                    <DashboardFooter />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <TaskSubmitModal
                show={show}
                handleClose={handleClose}
                slugId={slugId}
            /> */}
            <SubmitTaskModal
                show={show}
                handleClose={handleClose}
                slugId={slugId}
                type={taskDetails.type}
                caption={taskDetails?.caption}
                total_amount={taskDetails?.total_amount}
                multiple_submission={taskDetails?.multiple_submission}
            />
            {/* <PayoutRequestModal
                show={show}
                handleClose={handleClose}
            /> */}
        </>
    )
}

export default TaskDetails
