import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <>
            <div className="login-footer-wrap">
                <div className="login-footer">
                    <div className="login-footer-left">
                        <ul>
                            <li><a href="https://reelapps.io/terms.html" target="_blank">Terms & Conditions</a></li>
                            <li><a href="https://reelapps.io/privacy.html" target="_blank">Privacy Policy</a></li>
                            <li><a href="http://support.vineasx.com/" target="_blank">Support</a></li>
                        </ul>
                    </div>
                    <div className="login-footer-mid">
                        <p className="text-center">© 2024, All Rights Reserved to <a target="_blank" href="https://vineasx.com/">VineaSX Solutions LLC</a>. Developed and Maintained by <a target="_blank" href="https://v2.dotasks.in/">  DoTasks</a> <br /> You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>
                    </div>
                    <div className="login-footer-right">
                        <div className="reel-logo"><a href="https://reelapps.io/" target="_blank"><img src={require("../../images/reelapps.png")} alt="" /></a></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;