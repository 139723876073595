import React, { useState } from 'react';

import SidePanelAdmin from '../../Common/SidePanelAdmin';
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import { ScrollPanel } from 'primereact/scrollpanel';

import AdminUsersTabs from './AdminUsersTabs';
import TaskPreferenceTabs from "./TaskPreferenceTabs";
import PaymentMethodsTabs from "./PaymentMethodsTabs";
import RolesPermissionsTabs from './RolesPermissionsTabs';

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import TitleBar from '../../Common/TitleBar';
import PersonalDetailsAdmin from './PersonalDetailsAdmin';
import EmailPhoneAdmin from './EmailPhoneAdmin';
import SecurityAdmin from './SecurityAdmin';
import PasswordAdmin from './PasswordAdmin';
import AdminKyc from './AdminKyc';
import AdminNotification from './AdminNotification';

function ProfileSettingsAdmin() {

   const [isActive, setIsActive] = useState(false);
   const toggleClass = () => {
      setIsActive(!isActive);
   };

   const [isActive2, setIsActive2] = useState(false);
   const toggleNotification = () => {
      setIsActive2(!isActive2);
   };

   return (
      <>
         <TitleBar title="Settings" />
         <div className={isActive ? 'dashboard dashboard-modified activeSidePanel' : 'dashboard dashboard-modified'}>
            <SidePanelAdmin />
            <div className="dashboard-in admin-roles-footer">
               <DashboardHeader toggleSidebar={toggleClass} notificationToggle={toggleNotification} title="My Accounts" />
               <ScrollPanel className="custombar2 content-area">
                  <div className="content-area-in tab-style">
                     <Tab.Container id="left-tabs-example" defaultActiveKey="tab-1">
                        <div className="tab-bar">
                           <Nav variant="pillsb">
                              <Nav.Item><Nav.Link eventKey="tab-1">Personal Details</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-2">Email & Phone Number</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-5">Password</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-6">KYC</Nav.Link></Nav.Item>
                              {/* <Nav.Item><Nav.Link eventKey="tab-2">Payment Methods</Nav.Link></Nav.Item> */}

                              <Nav.Item><Nav.Link eventKey="tab-4">Security</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-7">Roles and Permissions</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-8">Payment Settings</Nav.Link></Nav.Item>
                              <Nav.Item><Nav.Link eventKey="tab-3">Notifications</Nav.Link></Nav.Item>
                              {/* <Nav.Item><Nav.Link eventKey="tab-9">Tasks Category</Nav.Link></Nav.Item> */}
                           </Nav>


                           {/* <div className="tab-bar-right">
                              <button className='theme-btn'>Add New User</button>
                     <ul>
                        <li><Link>Add New User</Link></li>
                        <li>
                           <span className="profile-menu">
                              <Dropdown>
                                 <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <BiDotsHorizontalRounded />
                                 </Dropdown.Toggle>
                                 <Dropdown.Menu>
                                    <Link>Action</Link>
                                    <Link>Another action</Link>
                                    <Link>Something else</Link>
                                 </Dropdown.Menu>
                              </Dropdown>
                           </span>
                        </li>
                     </ul>
                  </div> */}

                        </div>
                        <Tab.Content>
                           <Tab.Pane eventKey="tab-1">
                              <PersonalDetailsAdmin />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-2">
                              <EmailPhoneAdmin />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-3">
                              <AdminNotification />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-4">
                              <SecurityAdmin />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-5">
                              <PasswordAdmin />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-6">
                              <AdminKyc />
                           </Tab.Pane>

                           <Tab.Pane eventKey="tab-7">
                              <RolesPermissionsTabs />
                           </Tab.Pane>
                           <Tab.Pane eventKey="tab-8">
                              <PaymentMethodsTabs />
                           </Tab.Pane>
                           {/* <Tab.Pane eventKey="tab-9">
                              <TaskPreferenceTabs />
                           </Tab.Pane> */}

                        </Tab.Content>
                     </Tab.Container>
                  </div>
               </ScrollPanel>
               <DashboardFooter />
            </div>

         </div>

      </>
   )
}
export default ProfileSettingsAdmin;