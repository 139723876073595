import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Dropdown } from 'primereact/dropdown';
import { FaSpinner } from 'react-icons/fa6';


function AssignTaskModal(props) {


    const cities = props?.userAssignList?.map((iteam) => ({
        name: iteam.name,
        code: iteam.id
    }))
    return (
        <>
            <Modal className="theme-modal forgotpass-moda creator_new_task link_submit_modal" show={props.show} onHide={props.handleClose} backdrop="static" centered>
                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn justify-content-between'>
                        {/* <span><MdOutlineArrowBackIos /></span>  */}
                        <h3 className="m-auto" style={{ fontSize: "25px" }}>Assign Task</h3>
                        {/* <span onClick={props.handleClose}><RxCross1 /></span>    */}

                    </div>
                    <form onSubmit={props.handleSubmitAssign}>
                        <div className="modal-card mt-4 ">
                            <div className='account-block mb-0 px-1 py-1'>
                                <div className='submission_wrap submission_bg mt-3 mb-3'>
                                    <Dropdown value={props.EmployeeId} onChange={(e) => props.setEmployeeId(e.value)} options={cities} optionLabel="name"
                                        placeholder="Select a Employee" className="p-2 border-0" />
                                </div>
                            </div>
                            <div className="link_btn text-end my-2">
                                <button className='theme-btn saveBtn ms-auto me-2 d-flex' type="submit" style={{ padding: "8px 24px" }} >Submit{props.loader.assign ? <FaSpinner style={{ fontSize: "18px" }} className="spin ms-1" /> : null} </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        </>
    )

}
export default AssignTaskModal
