import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import SidePanelAdmin from '../../Common/SidePanelAdmin'
import DashboardHeader from '../../Common/DashboardHeader'
import AdminCreatorProfile from './AdminCreatorProfile'
import AdminCreatorAllTask from './AdminCreatorAllTask'
import AdminPaymentMethod from './AdminPaymentMethod'
import AdminReviews from './AdminReviews'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { activeAccountUserDetails, banAccoutUserDetails, FetchAdminCreaterUserDetails } from '../../../Redux/Actions/AdminManagerUserAction'
import AdminCreatorNav from './AdminTaskDeatils/AdminCreatorNav'
import { ScrollPanel } from 'primereact/scrollpanel'
import AdminBanModel from '../AdminModal/AdminBanModel'
import AdminActiveModel from '../AdminModal/AdminActiveModel'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import AdminCreatorEmail from './AdminCreatorEmail'
import AdminCreatorPassword from './AdminCreatorPassword'
import AdminCreatorKyc from './AdminCreatorKyc'
import AdminCreatorSecurity from './AdminCreatorSecurity'

const AdminNavProfile = () => {
    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const location = useLocation()

    const userId = location?.state?.slug
    const dispatch = useDispatch()
    const [userData, setUserData] = useState({})
    const [loader, setLoader] = useState({
        fetch: true,
        active: false,
        banAccount: false
    })
    const [reason, setReason] = useState('')
    const [showModel, setShowModel] = useState(false)
    const [showModel1, setShowModel1] = useState(false)
    const fetchDataTasker = () => {

        let formData = {
            "id": userId || userData?.profiledata?.id
        }
        dispatch(FetchAdminCreaterUserDetails(formData, loader, setLoader, setUserData))

    }
    useEffect(() => {
        if (userId) {
            fetchDataTasker()
        }

    }, [userId])
    const handleShow = () => setShowModel(true)
    const handleClose = () => setShowModel(false)
    const handleShow1 = () => setShowModel1(true)
    const handleClose1 = () => setShowModel1(false)


    const handleBanAccount = () => {
        setLoader({
            ...loader,
            banAccount: true

        })
        let data = {
            id: userData?.profiledata?.id
        }
        dispatch(activeAccountUserDetails(data, loader, setLoader, handleClose1, fetchDataTasker))
    }
    const handleActive = () => {
        setLoader({
            ...loader,
            active: true

        })
        let data = {
            id: userData?.profiledata?.id,
            "ban_reason": reason
        }
        if (reason === '') {
            setLoader({
                ...loader,
                active: false

            })
            dispatch(setAlert("Reason  is requried", "danger"))
        } else {
            dispatch(banAccoutUserDetails(data, loader, setLoader, handleClose, fetchDataTasker))
        }
    }

    return (
        <>

            <div className="dashboard creator_new_task container-fluid overflow-hidden">
                <div className="row">

                <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanelAdmin type="creator" />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div className="dashboard-in  ">

                            <DashboardHeader toggleSidebar={toggleClass} title="Creater" />
                            <div className="row">
                                <div className="col-12">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <div className="tab-bar">
                                            <Nav className='mb-2' variant="pills" style={{ padding: "20px 28px 0 28px" }} >
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first" >Profile Details</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="5" >Email & Phone Number</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="6" >Password</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="7" >KYC</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="8" >Security</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second">All Tasks</Nav.Link>
                                                </Nav.Item>
                                                {/* <Nav.Item>
                                                <Nav.Link eventKey="third">Payment methods</Nav.Link>
                                            </Nav.Item> */}
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth">Reviews</Nav.Link>
                                                </Nav.Item>

                                            </Nav>
                                            <div className="tab-bar-right me-4 mt-3">
                                                <ul>


                                                    {
                                                        userData?.profiledata?.isSuspended === "0" ? <li><a className='red' onClick={handleShow} style={{ cursor: "pointer" }}>Ban Account</a></li> : <li><a className='red' onClick={handleShow1} style={{ cursor: "pointer" }}>Activate</a></li>
                                                    }


                                                </ul>
                                            </div>
                                        </div>



                                        <div className="setting_right_side_wrap ">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first">
                                                    <AdminCreatorProfile data={userData.profiledata} loader={loader} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second">
                                                    <AdminCreatorAllTask data={userData.tasklist} loader={loader} profiledata={userData.profiledata} />

                                                </Tab.Pane>
                                                {/* <Tab.Pane eventKey="third">
                                                    <AdminPaymentMethod />
                                                </Tab.Pane> */}
                                                <Tab.Pane eventKey="fourth">
                                                    <AdminReviews data={userData?.review?.allreview} loader={loader} profiledata={userData.profiledata} averagerating={userData?.review?.averagerating} totalreview={userData?.review?.averagerating} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="5">
                                                    <AdminCreatorEmail data={userData.profiledata} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="6">
                                                    <AdminCreatorPassword data={userData.profiledata} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="7">
                                                    <AdminCreatorKyc data={userData.profiledata} />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="8">
                                                    <AdminCreatorSecurity data={userData.profiledata} />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>

                                    </Tab.Container>
                                </div>
                            </div>

                        </div>
                    </div>
                    <AdminBanModel show={showModel} handleClose={handleClose} setReason={setReason} reason={reason} loader={loader} handleBanAccount={handleActive} />
                    <AdminActiveModel show={showModel1} handleClose={handleClose1} loader={loader} handleBanAccount={handleBanAccount} />

                </div>
            </div >
        </>
    )


}

export default AdminNavProfile
