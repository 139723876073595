import { commonAxios } from "../../Global/CommonAxios";
import { setAlert } from "./AlertActions";

export const fetchTakerDashboardAction = (setSubmissionData, setLoader, loader) => (dispatch, getState) => {

    commonAxios("dashboard", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setSubmissionData(res.data)

            }
            else {

                dispatch(setAlert(res.msg, "danger"));
            }
            setLoader({
                ...loader,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                save: false
            })
        })
}
export const stepTaskerAction = (setSubmissionData, loader, setLoader) => (dispatch, getState) => {

    commonAxios("fetch-dashbord-onboarding", {}, dispatch, getState().auth.token)
        .then((res) => {

            if (res.status) {

                setSubmissionData(res.data.list)

            }

            setLoader({
                loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                loader,
                fetch: false
            })
        })
}