import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, userrole }) => {
    const auth = useSelector(state => state.auth)
    const role = useSelector(state => state.auth.user.role)

    if (!auth.isAuthenticated || userrole !== role) {
        localStorage.clear()
        return <Navigate to="/" />
    }
    return children;
}

export default PrivateRoute