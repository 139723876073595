import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';


import './App.scss';
import './css/flag.scss';
import './css/custom.scss';
import './css/dev.scss';
import { ThemeProvider } from "./Components/Theme-Context/ThemeContext";
import SignIn from "./Components/Auth/SignIn";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import Alert from "./Components/Common/Alert";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeAlert } from "./Redux/Actions/AlertActions";
import PrivateRoute from "./Components/Common/PrivateRoute";
import ResetPassword from "./Components/Auth/ResetPassword";
import MasterLogin from "./Components/Auth/MasterLogin";
import Dashboard from "./Components/Tasker/TaskerDashboard/Dashboard";
import Task from "./Components/Tasker/TaskModule/Task";
import TaskDetails from "./Components/Tasker/TaskModule/TaskDetails/TaskDetails";
import ProfileSettings from "./Components/Tasker/Profile-Settings/ProfileSettings";
import Achievements from "./Components/Tasker/Achievements/Achievements";
import VerificationFirstStep from "./Components/Tasker/Phone-Number/VerificationFirstStep";
import VerificationSecondStep from "./Components/Tasker/Phone-Number/VerificationSecondStep";
import VerificationThirdStep from "./Components/Tasker/Phone-Number/VerificationThirdStep";
import VerificationFourthStep from "./Components/Tasker/Phone-Number/VerificationFourthStep";
import VerificationFifthStep from "./Components/Tasker/Phone-Number/VerificationFifthStep";
import VerificationSixthStep from "./Components/Tasker/Phone-Number/VerificationSixthStep";
import AccountSelector from "./Components/Auth/AccountSelector";
import SignUp from "./Components/Auth/SignUp";
import Verification from "./Components/Auth/Verification";
import ProfileSettingsTaskCreator from "./Components/TaskCreator/Profile-Settings/ProfileSettingsTaskCreator";
import ProfileSettingsAdmin from "./Components/Admin/Profile-Settings/ProfileSettingsAdmin";
import ReviewsTaskCreator from "./Components/TaskCreator/Reviews/ReviewsTaskCreator";
import SpendingTaskCreator from "./Components/TaskCreator/Spending/SpendingTaskCreator";
import Earnings from "./Components/Tasker/EarningsPayouts/Earnings";
import CreatorDashboard from "./Components/TaskCreator/CreatorDashboard/CreatorDashboard";
import CreatorTask from "./Components/TaskCreator/CreatorTask/CreatorTask";
import CreateNewTask from "./Components/TaskCreator/CreatorTask/CreateNewTask";
import CreatorNavDetails from "./Components/TaskCreator/CreatorTask/CreatorTaskDetails/CreatorNavDetails";
import ProfileDetailsAdmin from "./Components/Admin/Profile-Details/ProfileDetailsAdmin";
import AdminDashboard from "./Components/Admin/AdminDashboard/AdminDashboard";
import NavTaskManager from "./Components/Admin/ManagementTask/NavTaskManager";
import AdminNavProfile from "./Components/Admin/AdminTaskCreatorProfile/AdminNavProfile";
import AdminNavTaskDetails from "./Components/Admin/AdminTaskCreatorProfile/AdminTaskDeatils/AdminNavTaskDetails";
import MobileVerification from "./Components/Auth/MobileVerification";
import MangerUser from "./Components/Admin/AdminTaskCreatorProfile/MangerUser";
import ApproveNavPayout from "./Components/Admin/ApprovePayout/ApproveNavPayout";
import PrivacyPolicy from "./Components/Common/PrivacyPolicy";
import TermsOfServices from "./Components/Common/TermsOfServices";
import Disclaimer from "./Components/Common/Disclaimer";
import Privacy from "./Components/Privacy/Privacy";
import Help from "./Components/Help/Help";
import CreateAmount from "./Components/TaskCreator/Payments/CreateAmount";
import HelpDetails from "./Components/Help/HelpDetails";
import AdminTaskCategory from "./Components/Admin/TaskCategory/AdminTaskCategory";
import TaskerPrivacy from "./Components/Privacy/TaskerPrivacy";
import AdminPrivacy from "./Components/Privacy/AdminPrivacy";
import AdminHelp from "./Components/AdminHelp/AdminHelp";
import TaskerHelp from "./Components/TaskerHelp/TaskerHelp";
import AdminHelpDetails from "./Components/AdminHelp/AdminHelpDetails";
import TaskerHelpDetails from "./Components/TaskerHelp/TaskerHelpDetails";
import RaisedQuery from "./Components/Tasker/RaisedQuery/RaisedQuery";
import Referral from "./Components/Tasker/Referral/Referral";
import Faq from "./Components/Tasker/Faq/Faq";
import ReferralAuth from "./Components/Auth/ReferralAuth";
import AdminTaskDetails from "./Components/Admin/AdminTaskCreatorProfile/AdminTaskDeatils/AdminTaskDetails";
import AdminTaskSubmissions from "./Components/Admin/ManagementTask/AdminTaskSubmissions";
import AdminTaskSubmissionDetailsAdminTaskSubmissionDetails from "./Components/Admin/ManagementTask/AdminTaskSubmissionDetails";
import AdminTaskSubmissionDetails from "./Components/Admin/ManagementTask/AdminTaskSubmissionDetails";
import AdminCreateTask from "./Components/Admin/ManagementTask/CreateTask/AdminCreateTask";
import NavAdminCreateTask from "./Components/Admin/ManagementTask/CreateTask/NavAdminCreateTask";
import CreatorFaqlist from "./Components/TaskCreator/CreatorFaq/CreatorFaqlist";
import AssignTask from "./Components/Admin/AssingTask/AssignTask";
import AdminNotificationNav from "./Components/Admin/AdminNotification/AdminNotificationNav";
import TaskerChat from "./Components/Tasker/TaskerChat/TaskerChat";
import ViewTaskSubmission from "./Components/Admin/ViewTaskSubmission.jsx/ViewTaskSubmission";
import TaskSubmissionProfile from "./Components/Admin/ViewTaskSubmission.jsx/TaskSubmissionProfile";

import AdminOnboarding from "./Components/Admin/AdninOnBoarding/AdminOnboarding";
import StaticsNav from "./Components/Admin/Stactics/StaticsNav";
import EmpolyeeUserTab from "./Components/Admin/EmpolyeeList/EmpolyeeUserTab";
import SecurityTabs from "./Components/TaskCreator/Profile-Settings/SecurityTabs";
import SettingTab from "./Components/Admin/SettingTab/SettingTab";





function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])

  return (
    <ThemeProvider>
      <Alert />
      <div className="App">
        <Router>

          <Routes>

            <Route path="/account-selector" element={<AccountSelector />} />

            <Route path="/referralauth" element={<ReferralAuth />} />
            <Route path="/" element={<SignIn />} />

            <Route path="/sign-up" element={<SignUp />} />
            < Route path="/verification" element={<Verification />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/admin-login" element={<MasterLogin />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route exact path="/mobile-verification" element={<MobileVerification />} />


            <Route exact path="/dashboard" element={<PrivateRoute userrole='Tasker'><Dashboard /> </PrivateRoute>} />
            <Route exact path="/tasks" element={<PrivateRoute userrole='Tasker'><Task /> </PrivateRoute>} />
            <Route exact path="/task-details" element={<PrivateRoute userrole='Tasker'><TaskDetails /> </PrivateRoute>} />
            <Route exact path="/help-details" element={<PrivateRoute userrole='Tasker'><HelpDetails /> </PrivateRoute>} />
            <Route exact path="/tasker-help-details" element={<PrivateRoute userrole='Tasker'><TaskerHelpDetails /> </PrivateRoute>} />
            <Route exact path="/profile-settings" element={<PrivateRoute userrole='Tasker'><ProfileSettings /> </PrivateRoute>} />
            <Route exact path="/achievements" element={<PrivateRoute userrole='Tasker'><Achievements /> </PrivateRoute>} />
            <Route exact path="/referral" element={<PrivateRoute userrole='Tasker'><Referral /> </PrivateRoute>} />
            <Route exact path="/earnings" element={<PrivateRoute userrole='Tasker'><Earnings /> </PrivateRoute>} />
            <Route exact path="/tasker-chat" element={<PrivateRoute userrole='Tasker'>< TaskerChat /> </PrivateRoute>} />
            <Route exact path="/raised-query" element={<PrivateRoute userrole='Tasker'>< RaisedQuery /> </PrivateRoute>} />
            <Route exact path="/referal" element={<PrivateRoute userrole='Tasker'>< Referral /> </PrivateRoute>} />
            <Route exact path="/faq" element={<PrivateRoute userrole='Tasker'>< Faq /> </PrivateRoute>} />
            <Route exact path="/Tasker-Help" element={<PrivateRoute userrole='Tasker'>< TaskerHelp /> </PrivateRoute>} />
            <Route exact path="/privacy-tasker" element={<PrivateRoute userrole='Tasker'>< TaskerPrivacy /> </PrivateRoute>} />


            <Route exact path="/step-1" element={<PrivateRoute><VerificationFirstStep /> </PrivateRoute>} />
            <Route exact path="/step-2" element={<PrivateRoute><VerificationSecondStep /> </PrivateRoute>} />
            <Route exact path="/step-3" element={<PrivateRoute><VerificationThirdStep /> </PrivateRoute>} />
            <Route exact path="/step-4" element={<PrivateRoute><VerificationFourthStep /> </PrivateRoute>} />
            <Route exact path="/step-5" element={<PrivateRoute><VerificationFifthStep /> </PrivateRoute>} />
            <Route exact path="/step-6" element={<PrivateRoute><VerificationSixthStep /> </PrivateRoute>} />
            <Route exact path="/privacy" element={<PrivacyPolicy />} />
            <Route exact path="/Termsofservices" element={<TermsOfServices />} />
            <Route exact path="/disclaimer" element={<Disclaimer />} />


            <Route exact path="/Help" element={<PrivateRoute userrole='Creator'>< Help /> </PrivateRoute>} />

            <Route exact path="/privacy-nav" element={<PrivateRoute userrole='Creator'>< Privacy /> </PrivateRoute>} />
            <Route exact path="/creator/dashboard" element={<PrivateRoute userrole='Creator'><CreatorDashboard /> </PrivateRoute>} />
            <Route exact path="/creator/task" element={<PrivateRoute userrole='Creator'><CreatorTask /> </PrivateRoute>} />
            <Route exact path="/create/task" element={<PrivateRoute userrole='Creator'><CreateNewTask /> </PrivateRoute>} />
            <Route exact path="/task-creator/profile-settings" element={<PrivateRoute userrole='Creator'><ProfileSettingsTaskCreator /> </PrivateRoute>} />
            <Route exact path="/task-creator/reviews" element={<PrivateRoute userrole='Creator'><ReviewsTaskCreator /> </PrivateRoute>} />
            <Route exact path="/task-creator/spending" element={<PrivateRoute userrole='Creator'><SpendingTaskCreator /> </PrivateRoute>} />
            <Route exact path="/creator/details" element={<PrivateRoute userrole='Creator'><CreatorNavDetails /> </PrivateRoute>} />
            <Route exact path="/amountpage" element={<PrivateRoute userrole='Creator'>< CreateAmount /> </PrivateRoute>} />
            <Route exact path="/creator-faq" element={<PrivateRoute userrole='Creator'>< CreatorFaqlist /> </PrivateRoute>} />












            <Route exact path="/admin/submission-details" element={<PrivateRoute userrole='Admin'>< AdminTaskSubmissionDetails /> </PrivateRoute>} />
            <Route exact path="/admin/task-assign" element={<PrivateRoute userrole='Admin'>< AssignTask /> </PrivateRoute>} />
            <Route exact path="/admin/notification" element={<PrivateRoute userrole='Admin'>< AdminNotificationNav /> </PrivateRoute>} />
            <Route exact path="/admin/profile-details" element={<PrivateRoute userrole='Admin'><ProfileDetailsAdmin /> </PrivateRoute>} />
            <Route exact path="/admin/create/task" element={<PrivateRoute userrole='Admin'><AdminCreateTask /> </PrivateRoute>} />
            <Route exact path="/admin/create/details" element={<PrivateRoute userrole='Admin'><NavAdminCreateTask /> </PrivateRoute>} />
            <Route exact path="/admin-help-details" element={<PrivateRoute userrole='Admin'><AdminHelpDetails /> </PrivateRoute>} />
            <Route exact path="/admin/view-submission" element={<PrivateRoute userrole='Admin'> <TaskSubmissionProfile /> </PrivateRoute>} />
            <Route exact path="/admin/onboarding" element={<PrivateRoute userrole='Admin'> <AdminOnboarding /> </PrivateRoute>} />
            <Route exact path="/admin/static" element={<PrivateRoute userrole='Admin'> <StaticsNav /> </PrivateRoute>} />
            <Route exact path="/admin/empolyee-list" element={<PrivateRoute userrole='Admin'> <EmpolyeeUserTab /> </PrivateRoute>} />
            <Route exact path="/admin/profile-settings" element={<PrivateRoute userrole='Admin'><ProfileSettingsAdmin /> </PrivateRoute>} />
            <Route exact path="/admin/task-category" element={<PrivateRoute userrole='Admin'><AdminTaskCategory /> </PrivateRoute>} />
            <Route exact path="/Admin-Help" element={<PrivateRoute userrole='Admin'>< AdminHelp /> </PrivateRoute>} />
            <Route exact path="/admin/dashboard" element={<PrivateRoute userrole='Admin'><AdminDashboard /> </PrivateRoute>} />
            <Route exact path="/admin/all-task" element={<PrivateRoute userrole='Admin'><NavTaskManager /> </PrivateRoute>} />
            <Route exact path="/admin/tasker/profile" element={<PrivateRoute userrole='Admin'><AdminNavProfile /> </PrivateRoute>} />
            <Route exact path="/admin/Task/details" element={<PrivateRoute userrole='Admin'><AdminNavTaskDetails /> </PrivateRoute>} />
            <Route exact path="/admin-manger-user" element={<PrivateRoute userrole='Admin'><MangerUser /> </PrivateRoute>} />
            <Route exact path="/admin/nav/payout" element={<PrivateRoute userrole='Admin'><ApproveNavPayout /> </PrivateRoute>} />
            <Route exact path="/privacy-admin" element={<PrivateRoute userrole='Admin'>< AdminPrivacy /> </PrivateRoute>} />
            <Route exact path="/admin-setting" element={<PrivateRoute userrole='Admin'><SettingTab /> </PrivateRoute>} />


          </Routes>
        </Router>
      </div>
    </ThemeProvider >
  );
}

export default App;
