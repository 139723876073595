import { ScrollPanel } from 'primereact/scrollpanel'
import React, { useEffect, useState } from 'react'
import { IoSearchOutline } from 'react-icons/io5'
import SummerNote from '../../TaskCreator/CreatorTask/SummerNote';
import { LuSend } from "react-icons/lu";
import { RiDeleteBinLine } from 'react-icons/ri';
import AdminNotificationModal from './AdminNotificationModal';
import { AddNotificationSend, deleteNotificationSend, EmailNotificationFetch, EmailNotificationSend } from '../../../Redux/Actions/AdminNotificationList';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { FaSpinner } from 'react-icons/fa';

const UserDashboardNotification = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [id, setId] = useState('')

    const [loader, setLoader] = useState({
        fetch: false,
        save: false,
        delete: false
    })
    const dispatch = useDispatch()
    const [fetchData, setFetchData] = useState([])
    const [data, setData] = useState({
        "message": ""
    });
    const fetchEmail = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(EmailNotificationFetch("fetch-notification", setFetchData, setLoader, loader,))
    }
    const onChangehandle = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            save: true
        })

        if (data.email === "") {
            dispatch(setAlert("Enter the email`", "danger"))
            setLoader({
                ...loader,
                save: false
            })
            return;
        }
        let formData = {
            "message": data.message,

        }

        dispatch(AddNotificationSend("add-notification", formData, setLoader, loader, fetchEmail, handleClose))
    }
    useEffect(() => {
        fetchEmail()
    }, [])
    const handleDelete = (id) => {
        setId(id)
        setLoader({
            ...loader,
            delete: true
        })
        let formData = {
            "id": id
        }
        dispatch(deleteNotificationSend("delete-notification", formData, setLoader, loader, fetchEmail))

    }

    return (
        <>
            <ScrollPanel className="custombar2 creator_new_task   content-area">
                <div className="content-area-in p-0 " style={{ marginTop: "-10px" }}>
                    <h5 className='my-3'>Notification</h5>
                </div>
                <div className='account-block pt-3'>
                    <div className="table-responsive mt-2">
                        <div className='d-flex align-items-center justify-content-between mb-3'>
                            <h6>Notification List

                            </h6>
                            <div onClick={handleShow} style={{ width: "fit-content" }} className='theme-btn ms-auto d-flex justify-content-end'>
                                Add Notification</div>
                        </div>
                        <table className="table theme-table">
                            <tr>
                                <th>Heading
                                </th>
                                <th>Action
                                </th>
                            </tr>{
                                fetchData?.list?.length > 0 ? fetchData?.list?.map((curElem, i) => {
                                    return (< tr key={i}>
                                        < td data-label=">Heading">{curElem.message}
                                        </td>
                                        <td data-label="Action">
                                            <div className="table-btns d-flex align-items-center gap-2">
                                                <span className="table-delete" onClick={() => handleDelete(curElem.id)}>
                                                    {curElem.id === id ? loader.delete ? <FaSpinner className="spin ms-1" /> : null : <RiDeleteBinLine />}
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                    )
                                }) :
                                    <td className='text-center py-3' colSpan={7}>

                                        No message found
                                    </td>

                            }

                        </table>

                    </div>
                </div>
            </ScrollPanel >
            <AdminNotificationModal
                show={show}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                onChangehandle={onChangehandle}
                loader={loader}
                data={data}
            />
        </>
    )
}

export default UserDashboardNotification
