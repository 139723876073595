import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { FaQuestionCircle, FaSpinner } from "react-icons/fa";
import { RxCross1 } from 'react-icons/rx';

const ApproveModal = (props) => {
    return (
        <>
            <Modal className="theme-modal admin-approve" show={props.show} onHide={props.close} backdrop="static" centered>

                <Modal.Header closeButton style={{ borderBottom: "0" }}>
                </Modal.Header>
                <Modal.Body className='pt-3'>
                    <div className='d-flex align-items-center modal-btn flex-column gap-2 justify-content-between'>
                        {/* <span style={{ right: "20px" }} className='ms-auto' onClick={props.handleClose} ><RxCross1 /></span> */}
                        <FaQuestionCircle style={{ fontSize: "70px", marginBottom: "15px" }} />
                        <h5> Are You Sure</h5>
                        <p style={{ fontSize: "14px" }}>Are Your Sure Want to Approve this task</p>
                        <div className="verify_btn mt-2 d-flex gap-3">
                            <button className="theme-btn cancel-btn" type="button" onClick={props.handleClose} style={{ padding: "8px 24px" }}>Cancel </button>
                            <button className="theme-btn approve-btn" type="button" onClick={props.handleConfirm} style={{ padding: "8px 24px" }}>Approve {props.loader.approve ? <FaSpinner className='spin ms-1' style={{ color: "#fff" }} /> : ""}</button></div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default ApproveModal;
