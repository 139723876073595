import React, { useEffect, useState } from 'react';
import { FaSpider, FaSpinner } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { useDispatch } from 'react-redux';
import { deleteAdminEmpolyeeUserlist, FetchAdminEmpolyeeUserlist } from '../../../Redux/Actions/AdminManagerUserAction';
import CusstomPagination from '../../Common/CusstomPagination';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import EmpolyeeModelConfirm from './EmpolyeeModelConfirm';



const EmpolyeeListTab = () => {
    const dispatch = useDispatch()
    const [searchInput, setSearchInput] = useState('')
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const [loader, setLoader] = useState({
        fetch: false,
        delete: false
    })
    const [limit, setLimit] = useState({ name: '20', code: '20' })
    const limited = [
        { name: '20', code: '20' },
        { name: '100', code: '100' },
        { name: '200', code: '200' },
        { name: '500', code: '500' },
        { name: '1000', code: '1000' }
    ];
    const [totalLength, setTotalLength] = useState(0);
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [EmpolyeeData, setEmpolyeeData] = useState({
        id: "",
        status: ""
    })
    const [sortOrder, setSortOrder] = useState('asc');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: Number(limit?.code) || 20
    });
    const handlesort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }
    const fetchData = () => {
        let formData = {
            "page": pagination.currentPage || 1,
            "limit": +limit?.code,
        }
        setLoader({
            fetch: true
        })

        dispatch(FetchAdminEmpolyeeUserlist("fetch-employee-list", formData, loader, setLoader, setData))
    }

    useEffect(() => {

        if (!searchInput) {

            setFilteredData(data?.userlist)
            setTotalLength(data?.total_count)

        } else {
            const filterSerach = data?.userlist?.filter(item => {
                return (
                    (item.name && item.name.toLowerCase().includes(searchInput.toLowerCase())) ||
                    (item.email && item.email.toLowerCase().includes(searchInput.toLowerCase())) ||
                    (item.id && item.id.toString().includes(searchInput))
                )
            })

            setFilteredData(filterSerach)
        }
    }, [searchInput, data, pagination.currentPage])

    useEffect(() => {
        fetchData()
        setPagination({
            ...pagination,
            totalItemOnPage: Number(limit?.code) || 20
        })

    }, [pagination.currentPage, limit.code])

    const handlesubmit = () => {
        setLoader({
            ...loader,
            delete: true,
        })

        let formData = {
            "id": EmpolyeeData.id,
            "status": EmpolyeeData.status === "0" ? "1" : "0"
        }
        dispatch(deleteAdminEmpolyeeUserlist("change-employee-status", formData, loader, setLoader, handleClose, fetchData))
    }
    const handlePause = (curElem) => {
        setShow(true)
        setEmpolyeeData({
            id: curElem.id,
            status: curElem.isPause
        })

    }
    return (
        <>
            <div className="table-bar mt-0">
                <div className="table-bar-left">
                    <div className="table-bar-icon">
                        <div className="table-nav">


                        </div>
                        <ul>
                            <li title="Sort by Id " onClick={handlesort}>
                                <img src={require("../../../images/icon/sort.svg").default} alt="" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="table-bar-right">
                    <div className='d-flex align-items-center gap-3 bar-btns'>
                        <div className='task-dropdown' style={{ width: "40%" }}>
                            <Dropdown style={{ padding: "4px 10px", marginTop: "1px" }} value={limit} onChange={(e) => setLimit(e.value)} options={limited} optionLabel="name"
                                placeholder="Select a Limit" className="rounded-2 border-0" />
                        </div>
                        <div className="header-search">
                            <input
                                type="text"
                                placeholder="Search"
                                name="searchInput"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}

                            />

                            <span className="left-icon"><IoSearchOutline
                            /></span>
                        </div>

                    </div>
                </div>
            </div>
            {loader.fetch ? <div className='d-flex justify-content-center align-items-center pt-3'><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
                <div className="table-responsive mt-3">
                    <table className="table theme-table">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>User Type</th>
                                <th>Date Joined</th>
                                <th>Status</th>
                                <th>Working Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {filteredData?.length > 0 ? filteredData?.map((curElem, i) => {

                            return (
                                <tr key={i}>

                                    <td data-label="User"><span className="userImg"><img src={curElem.profile ? curElem?.profile : "https://images.pexels.com/photos/2169434/pexels-photo-2169434.jpeg"} alt="" /></span>{curElem.name}</td>
                                    <td data-label="Email">{curElem.email}</td>
                                    <td data-label="Phone Number">{curElem.mobileNumber}</td>
                                    <td data-label="User Type">{curElem.role}</td>
                                    <td data-label="Date Joined"><IoCalendarClearOutline /> {curElem.created}</td>
                                    {curElem.isDeleted === "0" ? <td data-label="Status"><span className="table-stat active">Active</span></td> : curElem.isDeleted === "1" ? <td data-label="Status"><span className="table-stat inactive">Inactive</span></td> : ""}
                                    {curElem.isPause === "0" ? <td data-label="Working Status"><span className="table-stat active">Active</span></td> : curElem.isPause === "1" ? <td data-label="Working Status"><span className="table-stat inactive">Inactive</span></td> : ""}



                                    <td data-label="Action"> <p style={{ color: "#000000d1", fontWeight: "400", cursor: "pointer", height: "25px" }} className="d-flex align-items-center start_task gap-1" onClick={(e) => handlePause(curElem)}>
                                        {curElem.isPause === "0" ? "Pause" : "UnPause"}</p></td>
                                </tr>


                            )
                        })


                            : <div className="d-flex justify-content-center align-items-center mt-1"> {data?.userlist?.length === 0 ? "No User Data" : ""}</div>}


                    </table>
                </div>

            }

            <div className=' my-3 me-4'>
                <CusstomPagination
                    pagination={pagination}
                    setPagination={setPagination}
                    totalLength={totalLength}
                    loader={loader.fetch}
                />

            </div>
            <EmpolyeeModelConfirm show={show} handleClose={handleClose} loader={loader} handlesubmitDelete={handlesubmit} text="Are you sure" />
        </>
    )
}

export default EmpolyeeListTab
