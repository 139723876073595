import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import { CiBookmark } from "react-icons/ci";
import { ScrollPanel } from 'primereact/scrollpanel'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { BookMarkTaskTasker, FetchavilTaskTaskerData } from '../../../../../Redux/Actions/TaskTaskerActions';
import { FaSpider, FaSpinner } from "react-icons/fa";
import { FaBookmark } from "react-icons/fa";
import { MdOutlineWatchLater } from "react-icons/md";
import CusstomPagination from '../../../../Common/CusstomPagination';
import CountDownTimer from '../../../TaskerDashboard/CountDownTimer';

const OngoingTasks = (props) => {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const [data, setData] = useState('')
    const [searchInput, setSearchInput] = useState('')
    const [type, setType] = useState(props.type);
    const [filteredData, setFilteredData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [id, setId] = useState("")
    const [loader, setLoader] = useState({
        fetch: false,
        save: false
    })
    const [totalLength, setTotalLength] = useState(0);
    const [saveTask, setSaveTask] = useState([])
    const handleBookmark = (bId) => {
        setId(bId)
        setLoader({
            ...loader,
            save: true
        })
        let BookmarkId = {
            "slug": bId
        }
        dispatch(BookMarkTaskTasker(BookmarkId, FetchData, setLoader, loader))
    }


    const handlesort = () => {
        const sortedData = [...filteredData].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id;
            } else {
                return b.id - a.id;
            }
        });

        setFilteredData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    useEffect(() => {

        if (props.type) {
            setType(props.type);
        }


        if (data) {
            if (searchInput) {

                const newFilteredData = data.filter(val => {
                    return (
                        (val.name && val.name.toLowerCase().includes(searchInput.toLowerCase())) ||
                        (val.id && val.id.toString().toLowerCase().includes(searchInput.toLowerCase())) ||
                        (val.title && val.title.toLowerCase().includes(searchInput.toLowerCase()))
                    );
                });

                setFilteredData(newFilteredData);
            } else {
                setFilteredData(data);
            }
        }
        else {

            setFilteredData([]);
        }
    }, [props.type, searchInput, data]);

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 20
    });

    const FetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "page": pagination.currentPage || 1
        }

        dispatch(FetchavilTaskTaskerData("fetch-ongoing-list", formData, setData, setLoader, loader, setTotalLength, setSaveTask));
    }
    useEffect(() => {
        FetchData()
    }, [pagination.currentPage]);

    return (
        <>


            <ScrollPanel className="custombar2 content-area">

                <div className="content-area-in">

                    <div className="table-bar mt-0">
                        <div className="table-bar-left">
                            <div className="table-bar-icon">
                                <div className="table-nav">
                                    <div className="profile-menu">

                                    </div>
                                    {/* <div className="profile-menu">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="successN" className="drop-btn">
                                                <img src={require("../../../../../images/icon/filter.svg").default} alt="" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                <li className="drop-list">
                                                    <span className="custom-check">
                                                        <input
                                                            type="checkbox"
                                                            name="agentType"
                                                        />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                    dolor sit amet
                                                </li>
                                                <li className="drop-list">
                                                    <span className="custom-check">
                                                        <input
                                                            type="checkbox"
                                                            name="agentType"
                                                        />
                                                        <span className="checkMark"></span>
                                                    </span>
                                                    dolor sit amet.
                                                </li>


                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                                </div>


                                <ul>
                                    <li title="Sort by Date">
                                        <img src={require("../../../../../images/icon/sort.svg").default} alt="" onClick={handlesort} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="table-bar-right">
                            <div className="header-search">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    name="searchInput"
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                                <span className="left-icon"><IoSearchOutline
                                /></span>
                            </div>
                        </div>
                    </div>

                    {loader.fetch ? <div className='d-flex align-items-center justify-content-center mt-5'><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :
                        <div className="table-responsive mt-3">
                            <table className="table theme-table">
                                <thead>
                                    <tr>
                                        {/* <th>
                         <span className="custom-check">
                             <input
                                 type="checkbox"
                             />
                             <span className="checkMark"></span>
                         </span>
                     </th> */}
                                        <th>Task ID</th>

                                        <th>Creator</th>


                                        <th>Task Name</th>


                                        {/* <th>Max. Submission</th> */}
                                        <th>Price</th>
                                        <th>Due Date</th>
                                        <th>Action</th>
                                        {type === "completed" || type === "save" || type === "approve" || type === "rejected" ? "" : <th>BookMark</th>}


                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.length > 0 ? filteredData.map((curElem, i) => {

                                        // const SaveTasks = props.saveTask.some(task => task.id === curElem.id);


                                        return (
                                            <tr key={i} style={{ background: curElem.type === "7" ? "#f8d7da" : "" }}>
                                                {/* <td data-label="">
                         <span className="custom-check">
                             <input
                                 type="checkbox"
                             />
                             <span className="checkMark"></span>
                         </span>
                     </td> */}

                                                <td data-label="Task ID">{curElem?.id} </td>
                                                <td data-label="Task Creator"><span class="userImg"><img src={curElem?.profile ? curElem.profile : "https://backend.dotasks.in/public/uploads/profile/user.png"} alt="" /></span>{curElem.name}</td>
                                                <td data-label="Task Name">{curElem?.title}</td>
                                                {/* <td data-label="Max. Submission">{curElem?.max_submission}</td> */}
                                                <td data-label="Price">{curElem?.price}</td>
                                                <td data-label="Due Date"><MdOutlineWatchLater style={{ margin: "-2px 3px 0px 0px" }} />
                                                    {curElem.type === "7" ? <CountDownTimer expireTime={curElem.expire_time} /> : curElem.due_date}
                                                </td>


                                                {type === "completed" || type === "approve" ?
                                                    <td data-label="Action"><p className="start_task" style={{ padding: "15px 20px", borderRadius: "50px", backgroundColor: curElem.status === "1" ? "#fff3cd" : curElem.status === "2" ? "#DEF8EE" : "#f8d7da", color: curElem.status === "1" ? "#856404" : curElem.status === "2" ? "#4AA785" : "#721c24" }}>{curElem.status === "1" ? <p>Pending</p> : curElem.status === "2" ? <p>Approved</p> : <p>Rejected</p>}</p></td>

                                                    :
                                                    <td data-label="Action" onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })}><p className="start_task">{type === "rejected" ? "Resubmit" : "Start Task"}</p></td>

                                                }
                                                {type === "completed" || type === "save" || type === "approve" || type === "rejected" ? "" :
                                                    <td data-label="Bookmark"> {saveTask.includes(curElem.id) ? <> <FaBookmark size={22} onClick={() => handleBookmark(curElem?.slug)} />{curElem.slug === id ? loader.save ? <FaSpinner className="spin ms-1" /> : null : ""}</> : <> <CiBookmark size={22} onClick={() => handleBookmark(curElem?.slug)} /> {curElem.slug === id ? loader.save ? <FaSpinner className="spin ms-1" /> : null : ""}</>}</td>
                                                }
                                            </tr>
                                        )
                                    }) : <td className='text-center py-3' colSpan={7}>No tasks found</td>}



                                </tbody>
                            </table>
                        </div>


                    }

                </div>

                <div className='d-flex justify-content-end mb-5 me-4'>
                    <CusstomPagination
                        pagination={pagination}
                        setPagination={setPagination}
                        totalLength={totalLength}
                        loader={loader.fetch}
                    />
                </div>


            </ScrollPanel >



        </>
    )
}

export default OngoingTasks
