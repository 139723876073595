import React, { useEffect, useState } from 'react'
import { ScrollPanel } from 'primereact/scrollpanel'
import usd from '.././../../images/icon/CurrencyDollar.svg'
import { Dropdown } from 'react-bootstrap'
import { IoLocationOutline, IoPersonCircleOutline, IoSearchOutline } from 'react-icons/io5'
import { IoIosHeartEmpty, IoIosStarOutline } from 'react-icons/io';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import TasksCard from './TasksCard'
import { FaSpinner } from 'react-icons/fa'
import Pagination from '../../Common/Pagination';
import { RiDeleteBinLine } from "react-icons/ri";

const MyTasks = (props) => {
    const [selectedId, setSelectedId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });

    useEffect(() => {
        setData(props.cardData || []);
    }, [props.cardData]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = () => {
        const sortedData = [...data].sort((a, b) => {
            if (sortOrder === 'asc') {
                return new Date(a.id) - new Date(b.id);
            } else {
                return new Date(b.id) - new Date(a.id);
            }
        });
        setData(sortedData);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const filteredData = data.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexOfFirstTodo = indexOfLastTodo - pagination.totalItemOnPage;
    const currentTodo = filteredData.slice(indexOfFirstTodo, indexOfLastTodo)
    return (
        <>
            <ScrollPanel className="custombar2 creator_task content-area pt-lg-0 pt-3">
                <div className="content-area-in task_final pt-0">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="main_task_details pb-2">
                                    <div className="do_task_wrapper ">
                                        <div className="table-bar">
                                            <div className="table-bar-left">
                                                <div className="table-bar-icon">
                                                    <div className="table-nav">
                                                        <div className="profile-menu">

                                                        </div>
                                                        {/* <div className="profile-menu">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="successN" className="drop-btn">
                                                                    <img src={require("../../../images/icon/filter.svg").default} alt="" />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <p style={{ fontSize: 15, fontWeight: 500 }}> Lorem ipsum </p>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet
                                                                    </li>
                                                                    <li className="drop-list">
                                                                        <span className="custom-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                name="agentType"
                                                                            />
                                                                            <span className="checkMark"></span>
                                                                        </span>
                                                                        dolor sit amet.
                                                                    </li>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div> */}
                                                    </div>


                                                    <ul>
                                                        <li title="Sort by Date">
                                                            <img src={require("../../../images/icon/sort.svg").default} alt="" onClick={handleSort} />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="table-bar-right">
                                                <div className="header-search">
                                                    <input
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />
                                                    <span className="left-icon"><IoSearchOutline
                                                    /></span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* task_section_container */}

                                        {props.loader.fetch ? <FaSpinner className='spin ms-1 w-100 d-flex mt-4' style={{ fontSize: "40px" }} /> :
                                            <>
                                                {currentTodo.length > 0 ?

                                                    currentTodo.map((curlElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <TasksCard
                                                                    curlElem={curlElem}
                                                                    setSelectedId={setSelectedId}
                                                                    selectedId={selectedId}
                                                                    fetchPublishTask={props.fetchPublishTask}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    })
                                                    : <p style={{ textAlign: "center", marginTop: "30px" }}>No {props.type === "createTask" ? "all" : props.type === "PendingTasks" ? "pending" : props.type === "ApprovedTasks" ? "approverd" : props.type === "RejectTasks" ? "rejected" : props.type === "ongoingTasks" ? "ongoing" : "completed"} task </p>}
                                            </>

                                        }

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{ marginBottom: "50px" }} className=''>
                    {filteredData.length > 0 && (
                        <Pagination
                            listData={filteredData}
                            pagination={pagination}
                            setPagination={setPagination}
                            currentTodo={currentTodo}
                            listArr={props.cardData}
                            loader={props.loader.fetch}
                        />
                    )}
                </div>
            </ScrollPanel >

        </>
    )
}

export default MyTasks
