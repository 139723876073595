import React, { useEffect, useState } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { employeeDataAction } from '../../../Redux/Actions/AdminNotificationList'
import { Calendar } from 'primereact/calendar';
const EmployeeStatic = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [buttonLoader, setButtonLoader] = useState(false)
    const [loader, setLoader] = useState({
        fetch: false
    })
    const [endDate, setEndDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const currentDate = new Date();



    const fetchData = (showLoader = true) => {
        if (showLoader) {
            setLoader({ ...loader, fetch: true });
        }

        let formData = {
            "startDate": startDate?.toLocaleDateString('en-CA') || null,
            "endDate": endDate?.toLocaleDateString('en-CA') || null
        };

        dispatch(employeeDataAction("employee-statstics", formData, (newData) => {
            setData(newData);
            setLoader({ ...loader, fetch: false });
            setButtonLoader(false)
        }), loader, setLoader);
    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleSubmit = () => {
        fetchData(false)
        setButtonLoader(true)
    }
    const handleClear = () => {
        setEndDate(null)
        setStartDate(null)
    }

    return (
        <>
            {loader.fetch ? <div className="d-flex align-items-center justify-content-center mt-4"><FaSpinner className="spin ms-1" style={{ fontSize: "40px", height: "100vh" }} /></div> :
                <div className="refer-table mt-1">
                    <div className="row">
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className="col">
                            </div>
                        </div>
                        <div className='d-flex account-block date-pick-container align-items-center justify-content-end gap-2 mb-4'>
                            <div className='d-flex align-items-center'> <h6 style={{ fontWeight: "500", marginRight: "8px" }}>From</h6>
                                <div className="date-pickers flex justify-content-center">
                                    <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} maxDate={endDate || currentDate} dateFormat="dd/mm/yy" />
                                </div></div>
                            <div className='d-flex align-items-center'> <h6 style={{ fontWeight: "500", marginRight: "10px" }}>To</h6>
                                <div className="date-pickers flex justify-content-center">
                                    <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} minDate={startDate} maxDate={currentDate} dateFormat="dd/mm/yy" />
                                </div></div>

                            <button className='theme-btn' onClick={handleSubmit}>{buttonLoader ? <FaSpinner className='spin' /> : "Submit"}</button>
                            <button className='theme-btn' onClick={handleClear}>Clear</button>
                        </div>
                        <div className="table-responsive mt-2">

                            <table className="table theme-table">
                                <tr>
                                    <th>S.No
                                    </th>
                                    <th>Name</th>
                                    <th>Kyc</th>
                                    <th>Total Task Assign</th>
                                    <th>Pending task</th>
                                    <th>Approved task</th>
                                    <th>Rejected task</th>
                                </tr>
                                {data?.length > 0 ? data?.map((curElem, i) => {
                                    return (
                                        < tr key={i}>
                                            < td data-label="S.No">{i + 1}</td>
                                            <td data-label="Name">{curElem.name}</td>
                                            <td data-label="Kyc">{curElem.kyc}
                                            </td>
                                            <td data-label="Total Task Assign">{curElem?.total_task}
                                            </td>
                                            <td data-label="Pending task">{curElem?.total_task_pending}
                                            </td>
                                            <td data-label="Approved task">{curElem?.total_task_approve}
                                            </td>
                                            <td data-label="Rejected task">{curElem?.total_task_reject}
                                            </td>
                                        </tr>
                                    )
                                }) : <td className='text-center pt-3' colSpan={7}>No data found</td>}
                            </table>

                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default EmployeeStatic
