import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaskPerfernceData, TaskPerfernceUpdate } from "../../../Redux/Actions/AuthActions";
import { FaSpider, FaSpinner } from "react-icons/fa";


function TaskPreferenceTabs() {
  const dispatch = useDispatch();
  const Taskperference = useSelector(state => state.auth?.user?.task_type_preference)
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState({
    fetch: true,
    save: false,
  });
  const [checkedIds, setCheckedIds] = useState([]);



  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target
    let arr = [...checkedIds]
    if (checked) {
      arr.push(value)
    } else {
      const indexId = checkedIds.indexOf(value)
      if (indexId !== -1) {
        arr.splice(indexId, 1)
      }
    }
    setCheckedIds(arr)
  };

  const handleSaveTask = (e) => {
    e.preventDefault();
    setLoader({
      ...loader,
      save: true
    })
    let fromdata = {
      "task_type": checkedIds
    }

    dispatch(TaskPerfernceUpdate(fromdata, setLoader, loader))
  };



  useEffect(() => {
    dispatch(TaskPerfernceData(setData, setLoader, loader));
  }, []);


  useEffect(() => {
    if (Taskperference) {
      setCheckedIds(JSON.parse(Taskperference))
    }
    else {
      setCheckedIds([])
    }
  }, [Taskperference])

  return (


    <div className="account-block">
      {
        loader.fetch ? <div className="d-flex align-items-center justify-content-center"><FaSpinner className="spin ms-2" style={{ fontSize: "22px" }} /></div> :
          <form onSubmit={handleSaveTask}>
            <h6>Task Preferences</h6>
            <div className="row task-reference-list">
              {data.length > 0 ? (
                data.map((curElem, index) => (
                  <div className="col-sm-6" key={curElem.id}>
                    <div className="task-reference-block">
                      <span className="custom-check">
                        <input
                          type="checkbox"
                          id={curElem.id}
                          checked={checkedIds.includes(curElem.id)}
                          value={curElem.id}
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                        <span className="checkMark"></span>
                      </span>
                      <div className="task-reference-info">
                        <label className="form-check-label" for={curElem.id}>
                          {curElem.name}
                        </label>
                        {/* <h6>{curElem.name}</h6> */}
                        {/* <p>
                          Tasks involving inputting, updating, and managing data in various formats
                        </p> */}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No tasks available</p>
              )}
            </div>
            <div className="btnGroup mt-4">
              {/* <button type="button" className="theme-btn light">Cancel</button> */}
              <button type="submit" className="theme-btn">Save Changes {loader.save ? <FaSpinner className="spin ms-1" /> : null}</button>
            </div>
          </form>
      }
    </div>

  );
}

export default TaskPreferenceTabs;
