import React, { useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import DashboardFooter from "../../Common/DashboardFooter";
import NotificationBar from "../../Common/NotificationBar";
import TitleBar from '../../Common/TitleBar';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate } from 'react-router-dom';


function Faq() {

    const [isActive, setIsActive] = useState(true);
    const toggleClass = () => {
        setIsActive(!isActive);
    };
    const navigation = useNavigate()

    return (
        <>
            <TitleBar title="Faq" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Faq" />
                                        <ScrollPanel className="custombar2 content-area">
                                            <div className="content-area-in creator_new_task" style={{ padding: "26px 35px", height: "unset" }}>
                                                <h5 className='mb-3'>FAQ</h5>
                                                <div className='account-block mb-3'>
                                                    <h6 style={{ fontSize: "16px" }} className='mb-4'>Sign Up FAQ
                                                    </h6 >
                                                    <Accordion className='accordion-theme'>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>How do I sign up for DoTasks?</Accordion.Header>
                                                            <Accordion.Body>
                                                                To sign up for DoTasks, enter your mobile number on the sign-up page. You will receive an OTP on your mobile. Enter the OTP to verify your number. Once verified, you will gain access to the members area where you can see available tasks.To sign up for DoTasks, enter your mobile number on the sign-up page. You will receive an OTP on your mobile. Enter the OTP to verify your number. Once verified, you will gain access to the members area where you can see available tasks.
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>Do I need to provide any personal information to sign up?**</Accordion.Header>
                                                            <Accordion.Body>
                                                                No, you only need to provide your mobile number and verify it with an OTP to sign up.
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>Is there any age restriction to join DoTasks?</Accordion.Header>
                                                            <Accordion.Body>
                                                                Yes, you must be at least 18 years old to join DoTasks.
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>Can I use multiple accounts to sign up?</Accordion.Header>
                                                            <Accordion.Body>
                                                                No, each person is allowed to sign up with only one mobile number to ensure fair distribution of tasks.
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>What should I do if I don't receive the OTP?</Accordion.Header>
                                                            <Accordion.Body>
                                                                If you do not receive the OTP, ensure you have entered the correct mobile number and have a stable network connection. You can request the OTP again after a few minutes.
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                    </Accordion>
                                                </div>
                                                <div className='account-block mb-4'>
                                                    <h6 style={{ fontSize: "16px" }} className='mb-4'>Task FAQ

                                                    </h6 >
                                                    <Accordion className='accordion-theme'>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>What kind of tasks will I be doing on DoTasks?</Accordion.Header>
                                                            <Accordion.Body>
                                                                Tasks are small social media jobs such as liking a reel on Instagram, leaving a comment on a YouTube video, retweeting on Twitter, or uploading a video on YouTube. Basically you get paid for everything that you already do on your favourite social media apps.                                        </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>How do I know which tasks are available?</Accordion.Header>
                                                            <Accordion.Body>
                                                                Once you log in to the members area, you will see a list of available tasks. Each task will have specific instructions on what needs to be done.
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>Are there any guidelines for completing tasks?</Accordion.Header>
                                                            <Accordion.Body>
                                                                Yes, each task will have detailed guidelines. Make sure to follow the instructions carefully to ensure your task is accepted and you get paid.
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>Can I choose which tasks I want to do?</Accordion.Header>
                                                            <Accordion.Body>
                                                                Yes, you can choose tasks from the list of available tasks that you are comfortable completing.
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>How do I submit proof of task completion?**</Accordion.Header>
                                                            <Accordion.Body>
                                                                Each task will specify the type of proof required, such as screenshots or links. You can upload this proof directly in the members area.


                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                    </Accordion>
                                                </div>
                                                <div className='account-block'>
                                                    <h6 style={{ fontSize: "16px" }} className='mb-4'>Payments FAQ
                                                    </h6 >
                                                    <Accordion className='accordion-theme'>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>How will I get paid for the tasks I complete?</Accordion.Header>
                                                            <Accordion.Body>
                                                                Payments are made weekly via UPI. You need to update and verify your UPI ID in the members area to receive payments

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>What is the payment cycle?</Accordion.Header>
                                                            <Accordion.Body>
                                                                For tasks completed from Monday to Sunday, you will be paid after an additional week, on the following Monday.

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>How do I update my UPI ID?</Accordion.Header>
                                                            <Accordion.Body>
                                                                Go to the payment settings in the members area and enter your UPI ID. Follow the instructions to verify your UPI ID.

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>What should I do if I don't receive my payment?</Accordion.Header>
                                                            <Accordion.Body>
                                                                If you do not receive your payment on the scheduled date, check if your UPI ID is correctly updated and verified. If everything is correct and you still haven't received the payment, contact our support team for assistance.

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>Are there any fees for receiving payments?</Accordion.Header>
                                                            <Accordion.Body>
                                                                No, there are no fees for receiving payments through UPI on DoTasks.
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                    </Accordion>
                                                </div>
                                                <div className='theme-btn d-flex mb-4 m-auto' style={{ width: "fit-content" }}>
                                                    <a className='text-white' href="https://www.youtube.com/@dotasks/videos" target="_blank">Watch Video Training</a>
                                                </div>
                                            </div>
                                        </ScrollPanel>

                                        <div>
                                            <DashboardFooter />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                                <RecommendTasks />
                            </div> */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>




        </>
    )
}

export default Faq;
