import { ScrollPanel } from "primereact/scrollpanel";
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FaSpider, FaSpinner } from "react-icons/fa";
import { IoCalendarClearOutline, IoSearchOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MdOutlineEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import TaskPreferenceAddModel from "../AdminModal/TaskPreferenceAddModal";
import { LuFileEdit } from "react-icons/lu";

import TaskPreferenceEditModal from "../AdminModal/TaskPreferenceEditModal";
import TaskPreferenceAddModal from "../AdminModal/TaskPreferenceAddModal";
import DeleteNewModal from "../AdminModal/DeleteNewModal";
import { useDispatch } from "react-redux";
import { deleteAdminTaskPrefanceAction, editAdminTaskPrefanceAction, fetchAdminTaskPrefanceAction } from "../../../Redux/Actions/AdminSettingsActions";
import Pagination from "../../Common/Pagination";

function TaskPreferenceTabs() {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState({
    fetch: true,
    add: false,
    edit: false,
    delete: false
  })
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [data, setData] = useState([])
  const [editShow, setEditShow] = useState(false);
  const handleClose1 = () => setEditShow(false);
  const handleShow1 = () => setEditShow(true);
  const [searchInput, setSearchInput] = useState('')
  const [categroyId, setCategroyId] = useState('')
  const [edit, setEdit] = useState('')
  const [deleteShow, setDeleteShow] = useState(false);
  const handleClose2 = () => setDeleteShow(false);
  const handleShow2 = () => setDeleteShow(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalItemOnPage: 15
  });
  const [filteredData, setFilteredData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');


  const handlesort = () => {

    const sortedData = [...filteredData].sort((a, b) => {

      return sortOrder === 'asc' ? a.id - b.id : b.id - a.id;
    });

    setFilteredData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  }

  useEffect(() => {

    if (data) {
      if (searchInput) {

        const newFilteredData = data.filter(val =>

          val.name.toLowerCase().includes(searchInput.toLowerCase()) ||

          String(val.id || '').includes(searchInput.toLowerCase())
        );




        setFilteredData(newFilteredData);
      } else {
        setFilteredData(data);
      }
    }
  }, [searchInput, data]);

  const fetchData = () => {
    dispatch(fetchAdminTaskPrefanceAction(setData, setLoader, loader))
  }
  useEffect(() => {
    fetchData()
  }, [])

  const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
  const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;
  const currentTodo = filteredData.slice(indexofFirstTodo, indexofLastTodo);


  const handleEdit = (curElem) => {

    handleShow1()
    setEdit(curElem.name)
    setCategroyId(curElem.id)
  }
  const handleDelete = (pId) => {
    setCategroyId(pId)
    handleShow2()
  }

  const handleEditSubmit = () => {
    setLoader({
      ...loader,
      edit: true
    })

    let data = {
      "id": categroyId,
      "name": edit
    }
    dispatch(editAdminTaskPrefanceAction(data, setLoader, loader, handleClose1, fetchData))
  }
  const handlesubmitDelete = () => {
    setLoader({
      ...loader,
      delete: true
    })
    let data = {
      "id": categroyId
    }
    dispatch(deleteAdminTaskPrefanceAction(data, setLoader, loader, handleClose2, fetchData))
  }

  return (
    <>{loader.fetch ? <div className="d-flex align-items-center justify-content-center mt=-5"><FaSpinner className="spin" style={{ fontSize: "32px" }} /></div> :
      <>

        <div className="tabBtn">
          <button className="theme-btn" onClick={handleShow}>Add Category</button>
        </div>
        <div className="table-bar mt-0">
          <div className="table-bar-left">
            <div className="table-bar-icon">
              <div className="table-nav">


              </div>
              <ul>
                <li title="Sort by Id" onClick={handlesort}>
                  <img src={require("../../../images/icon/sort.svg").default} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="table-bar-right">
            <div className="header-search">
              <input type="text"
                placeholder="Search"
                name="searchInput"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}

              />
              <span className="left-icon"><IoSearchOutline /></span>
            </div>
          </div>
        </div>
        <div className="table-responsive mt-3">
          <table className="table theme-table">

            <thead>
              <tr>
                <th>S.No</th>
                <th>Category name</th>
                <th>Created at</th>
                <th>Action</th>
              </tr>
            </thead>
            {
              currentTodo?.length > 0 ? currentTodo.map((curElem, i) => {

                return (
                  <tr key={i}>
                    <td data-label="S.No">{i + 1}</td>
                    <td data-label="Category name">{curElem.name}</td>
                    <td data-label="Created at">{curElem.created ? <><IoCalendarClearOutline /> {curElem.created}</> : "-"}</td>

                    <td data-label="Action">
                      <div className="table-btns d-flex align-items-center gap-2">
                        <span className="table-edit" onClick={(e) => handleEdit(curElem)}><MdOutlineEdit />

                        </span>
                        <span className="table-delete" onClick={() => handleDelete(curElem.id)}><RiDeleteBinLine />
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              }) : <td colSpan={7}><div className="d-flex align-items-center justify-contnet center">{data.length === 0 ? "No task perferance" : ""}</div></td>
            }





          </table>
        </div>
        <div className="my-4">
        <Pagination

listData={filteredData}
pagination={pagination}
setPagination={setPagination}
currentTodo={currentTodo}
listArr={data}
loader={loader.fetch}
/>
        </div>
        <TaskPreferenceAddModal show={show} handleClose={handleClose} loader={loader} setLoader={setLoader} fetchData={fetchData} />
        <TaskPreferenceEditModal show={editShow} handleClose={handleClose1} loader={loader} edit={edit} setEdit={setEdit} handleEditSubmit={handleEditSubmit} />
        <DeleteNewModal show={deleteShow} handleClose={handleClose2} loader={loader} handlesubmitDelete={handlesubmitDelete} />
      </>
    }
    </>
  )
}

export default TaskPreferenceTabs;
