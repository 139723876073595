import React, { useState, useEffect } from "react";

import { PieChart, Pie, Sector, Cell } from "recharts";
import { ScrollPanel } from 'primereact/scrollpanel';
import { GoDotFill } from "react-icons/go";
import { BsFolder } from "react-icons/bs";
import { FaArrowTrendUp, FaSpinner } from "react-icons/fa6";
import { PiBatteryChargingLight, PiListChecks, PiUsersThree } from "react-icons/pi";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import RecommendTasks from "./RecommendTasks";
import TitleBar from "../../Common/TitleBar";
import SidePanel from "../../Common/SidePanel";
import DashboardHeader from "../../Common/DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchTakerDashboardAction } from "../../../Redux/Actions/TaskerDashboardAction";
import { useNavigate } from "react-router-dom";
import DashboardFooter from "../../Common/DashboardFooter";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { MdOutlineWatchLater } from "react-icons/md";
import { HiOutlineBellAlert } from "react-icons/hi2";
import { Alert } from "react-bootstrap";
import UserStepper from "../../Common/UserStepperModal";
import CountDownTimer from "./CountDownTimer";
import { FetchavilTaskTaskerData } from "../../../Redux/Actions/TaskTaskerActions";
function Dashboard() {
    const [isActive, setIsActive] = useState(true);
    const onBoading = useSelector(state => state.auth.user.onboarding)
    const toggleClass = () => {
        setIsActive(!isActive);
    };

    const navigation = useNavigate()
    const [show1, setShow1] = useState(false)
    const handleClose1 = () => setShow1(false);
    // const handleShow = () => setShow(true);
    const dispatch = useDispatch()
    const [loader, setLoader] = useState({
        fetch: false,
        save: true,
    })
    const [fetchData, setFetchData] = useState([])
    const [latestTask, setLatestTask] = useState([])
    const [totalLength, setTotalLength] = useState(0);
    const [saveTask, setSaveTask] = useState([])
    const [show, setShow] = useState([]);
    // piechart..........

    const data = [
        { name: "Group A", value: fetchData?.task_status?.inprogress },
        { name: "Group B", value: fetchData?.task_status?.approval_pending },
        { name: "Group c", value: fetchData?.task_status?.competed }
    ];
    const COLORS = ["#BAEDBD", "#95A4FC", "#1C1C1C"];

    // BarChart.......
    const data1 = fetchData?.bardata
    const totalPV = data1?.reduce((acc, item) => acc + (item.pv || 0), 0) || 0;
    const isOddLength = data1?.length % 2 !== 0;
    const maxPV = isOddLength ? totalPV : Math.max(...data1.map(item => item.pv || 0));
    const tickCount = 4;
    const tickInterval = maxPV / tickCount;
    const ticks = Array.from({ length: tickCount + 1 }, (_, i) => Math.floor(i * tickInterval));
    // const data1 = [
    //     {
    //         name: 'Jan',
    //         // value: 1000
    //         uv: 4000,
    //         pv: 2400,
    //         amt: 2400,
    //     },
    //     {
    //         name: 'Feb',
    //         // value: 1000
    //         uv: 3000,
    //         pv: 1398,
    //         amt: 2210,
    //     },
    //     {
    //         name: 'Mar',
    //         // value: 1000
    //         uv: 2000,
    //         pv: 9800,
    //         amt: 2290,
    //     },
    //     {
    //         name: 'Apr',
    //         // value: 1000
    //         uv: 2780,
    //         pv: 3908,
    //         amt: 2000,
    //     },
    //     {
    //         name: 'May',
    //         // value: 1000
    //         uv: 1890,
    //         pv: 4800,
    //         amt: 2181,
    //     },
    //     {
    //         name: 'Jun',
    //         // value: 1000
    //         uv: 2390,
    //         pv: 3800,
    //         amt: 2500,
    //     },
    //     {
    //         name: 'Jul',
    //         // value: 1000
    //         uv: 3490,
    //         pv: 4300,
    //         amt: 2100,
    //     },
    //     {
    //         name: 'Aug',
    //         uv: 3490,
    //         pv: 4300,
    //         amt: 2100,
    //         // value: 1000
    //     },
    //     {
    //         name: 'Sep',
    //         uv: 3490,
    //         pv: 4300,
    //         amt: 2100,
    //         // value: 1000
    //     },
    //     {
    //         name: 'Oct',
    //         // value: 1000
    //         uv: 3490,
    //         pv: 4300,
    //         amt: 2100,
    //     },
    //     {
    //         name: 'Nov',
    //         // value: 1000
    //         uv: 3490,
    //         pv: 4300,
    //         amt: 2100,
    //     },
    //     {
    //         name: 'Dec',
    //         // value: 1000
    //         uv: 349990,
    //         pv: 4300,
    //         amt: 21000000,
    //     },
    // ];
    const FetchData = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let formData = {
            "page": 1
        }

        dispatch(FetchavilTaskTaskerData("fetch-avaiable-list", formData, setLatestTask, setLoader, loader, setTotalLength, setSaveTask));
    }

    const fetchDataDashbord = () => {

        dispatch(fetchTakerDashboardAction(setFetchData, setLoader, loader))
    }
    useEffect(() => {
        FetchData()
        fetchDataDashbord()
        // if (onBoading === "0") {
        //     setShow1(true)
        // }
    }, [])
    const handleAlertClose = (index) => {
        setShow((prev) => {
            const newShow = [...prev];
            newShow[index] = false;
            return newShow;
        });
    };
    useEffect(() => {
        if (fetchData?.message?.length > 0) {
            setShow(fetchData.message.map(() => true));
        }
    }, [fetchData]);

    return (
        <>
            <TitleBar title="Dashboard" />
            <div className="dashboard container-fluid overflow-hidden">
                <div className="row">
                    <div className={`col-lg-2 col-md-3 col-sm-4 col-12 side-nav ${isActive ? "nav-show" : "nav-hide"}`}>
                        <SidePanel />
                    </div>
                    <div className={` p-0 ${isActive ? "col-lg-10 col-md-9 col-sm-8 col-12" : "col-12"} main-content`}>
                        <div>
                            <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 ps-0 pe-0">
                                    <div className="dashboard-in">
                                        <DashboardHeader toggleSidebar={toggleClass} title="Dashboard" />

                                        {
                                            loader.save ? <div className="d-flex align-items-center justify-content-center mt-4 " style={{ height: "100vh" }}><FaSpinner className="spin ms-1" style={{ fontSize: "40px" }} /></div> :

                                                <ScrollPanel className="custombar2 content-area">
                                                    <div className="p-4">
                                                        <div className="do_task_wrapper px-2">
                                                            <div className="row">
                                                                <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task">
                                                                        <div className="d-flex justify-content-between">
                                                                            <h3>Total Tasks</h3>
                                                                            <BsFolder className="m_icon" />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>{fetchData.totaltask}</span>
                                                                            {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task d2">
                                                                        <div className="d-flex justify-content-between">
                                                                            <h3>Ongoing Tasks</h3>
                                                                            <PiListChecks className="m_icon" />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>{fetchData.ongoing_task}</span>
                                                                            {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task">
                                                                        <div className="d-flex justify-content-between">
                                                                            <h3> Pending Approval </h3>
                                                                            <PiUsersThree className="m_icon" />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>{fetchData.approve_pending}</span>
                                                                            {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp className="down_trend" />
                                                                    </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-3 col-lg-4 col-md-6 col-12 mt-xxl-0 mt-3 ">
                                                                    <div className="total_task d2">
                                                                        <div className="d-flex justify-content-between">
                                                                            <h3>My Earnings</h3>
                                                                            <RiMoneyRupeeCircleLine className="m_icon" />
                                                                        </div>
                                                                        <div className="d-flex justify-content-between">
                                                                            <span className="task_no" style={{ fontSize: "24px" }}>₹{fetchData.myearning}</span>
                                                                            {/* <div className="class_trend">
                                                                        <span className="me-1">+11.01%</span>
                                                                        <FaArrowTrendUp />
                                                                    </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {fetchData?.message?.length > 0 &&
                                                                fetchData.message.map((curElem, i) => (
                                                                    show[i] && (
                                                                        <div key={i}>
                                                                            <Alert
                                                                                className="theme-alert"
                                                                                onClose={() => handleAlertClose(i)}
                                                                                dismissible
                                                                            >
                                                                                <div className="d-flex w-100 align-items-start align-items-md-center flex-column flex-md-row">
                                                                                    <div className="col-auto ps-2 pe-0">
                                                                                        <HiOutlineBellAlert style={{ fontSize: "25px" }} />
                                                                                    </div>
                                                                                    <div className="col-lg-11 ps-2 ps-lg-1">
                                                                                        <p>{curElem.message}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </Alert>
                                                                        </div>
                                                                    )
                                                                ))}
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-xxl-3 col-xl-4 col-12">
                                                                <div className="campaign-stat-left w-100 px-2">
                                                                    <div className="account-block status " style={{ height: "426px" }}>
                                                                        <h6>Task Status</h6>
                                                                        <div>
                                                                            <div className="dash_graph" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "380px", width: "100%" }}>
                                                                                <ResponsiveContainer width="100%" height="100%">
                                                                                    <PieChart>
                                                                                        <Pie
                                                                                            data={data}
                                                                                            cx="50%"
                                                                                            cy="50%"
                                                                                            innerRadius={60}
                                                                                            outerRadius={80}
                                                                                            fill="#8884d8"
                                                                                            paddingAngle={5}
                                                                                            dataKey="value"
                                                                                        >
                                                                                            {data.map((entry, index) => (
                                                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                                            ))}
                                                                                        </Pie>
                                                                                    </PieChart>
                                                                                </ResponsiveContainer>
                                                                            </div>
                                                                            <div style={{ marginTop: "-140px" }}>
                                                                                <div className="task_status mb-2">
                                                                                    <p><GoDotFill style={{ color: "#1C1C1C", marginRight: "3px" }} />Completed</p>
                                                                                    <span>{fetchData?.task_status?.competed}%</span>
                                                                                </div>
                                                                                <div className="task_status mb-2">
                                                                                    <p><GoDotFill style={{ color: "#BAEDBD", marginRight: "3px" }} />In Progress</p>
                                                                                    <span>{fetchData?.task_status?.inprogress}%</span>
                                                                                </div>
                                                                                <div className="task_status mb-2">
                                                                                    <p><GoDotFill style={{ color: "#95A4FC", marginRight: "3px" }} /> Pending Approval </p>
                                                                                    <span>{fetchData?.task_status?.approval_pending}%</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xxl-9 col-xl-8 col-12">
                                                                <div className="campaign-stat-right  w-100 px-2">
                                                                    <div className="account-block Task" style={{ minHeight: "426px" }}>
                                                                        <div className="d-flex justify-content-between">
                                                                            <h6>Latest Tasks</h6>
                                                                            <a href="/tasks"><span>Show All</span></a>
                                                                        </div>
                                                                        <div className="table-responsive mt-3">
                                                                            <table className="table theme-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Title</th>
                                                                                        <th>Price</th>
                                                                                        <th>Due Date</th>
                                                                                        <th>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        loader.fetch ? <td colSpan={7} style={{ textAlign: "center", borderBottom: "unset", paddingTop: "10px" }}><FaSpinner className="spin ms-1" style={{ fontSize: "22px" }} /></td> :
                                                                                            latestTask.length > 0 ? latestTask.slice(0, 7).map((curElem, i) => {

                                                                                                return (
                                                                                                    <tr key={i} style={{ background: curElem.type === "7" ? "#f8d7da" : "" }}>
                                                                                                        <td data-label="Title">{curElem.title}</td>
                                                                                                        <td data-label="Price">{curElem.price}</td>
                                                                                                        <td data-label="Due Date"><MdOutlineWatchLater style={{ margin: "-2px 2px 0px 0px" }} />
                                                                                                            {curElem.type === "7" ? <CountDownTimer expireTime={curElem.expire_time} /> : curElem.due_date}</td>
                                                                                                        <td data-label="Action" onClick={() => navigation("/task-details", { state: { "slug": curElem?.slug } })}><p className="start_task">Start Task</p></td>
                                                                                                    </tr>
                                                                                                )
                                                                                            }) : <td colSpan="7" style={{ textAlign: "center", borderBottom: "unset", paddingTop: "10px" }}>No task found</td>

                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="account-block d-none d-md-block mt-4 mb-3 mx-2 mb-lg-5">
                                                            <h6>Tasks Overview</h6>
                                                            <div className="task_overview ">
                                                                <ResponsiveContainer width="100%" height={300}>
                                                                    <BarChart
                                                                        data={data1}
                                                                        margin={{
                                                                            top: 5,
                                                                            right: 30,
                                                                            left: 20,
                                                                            bottom: 5,
                                                                        }}
                                                                        barSize={20}
                                                                    >
                                                                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                                                        <YAxis tickFormatter={(value) => Math.floor(value)} ticks={ticks} />
                                                                        <Tooltip />
                                                                        <Legend />
                                                                        <CartesianGrid strokeDasharray="3 3" />
                                                                        <Bar dataKey="pv" fill="#A8C5DA" background={{ fill: "#eee" }} />
                                                                    </BarChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </ScrollPanel>

                                        }
                                        <div className="d-none d-lg-block">
                                            <DashboardFooter />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xxl-2 col-xl-3 col-lg-4 ps-0">
                                    <RecommendTasks />
                                </div> */}
                                <div className="d-block d-lg-none">
                                    <DashboardFooter />
                                </div>
                                <UserStepper show={show1} handleClose={handleClose1} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>




        </>
    )
}

export default Dashboard;