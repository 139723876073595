import { ScrollPanel } from 'primereact/scrollpanel'
import React from 'react'
import { FaRegFilePdf, FaSpinner } from 'react-icons/fa6'
import { IoSearchOutline } from 'react-icons/io5'
import { MdOutlineAddPhotoAlternate, MdOutlineVideoLibrary } from 'react-icons/md'

const ViewTaskSubmission = ({ taskdetail, proof, asssets, loader }) => {

    const handleViewFile = (curElem) => {

        if (curElem.url) {
            const a = document.createElement('a');
            a.href = curElem.url;
            a.target = '_blank';
            a.download = curElem.name || 'downloaded_file';
            a.click();

        }
    }

    return (
        <>{loader.fetch ? <div className="d-flex align-items-center justify-content-center mt=-5"><FaSpinner className="spin" style={{ fontSize: "32px" }} /></div> :
            <div className='"dashboard-in main_task_wrapper'>
                <h5 className='mb-3'>
                    View Submission
                </h5>
                <div className='account-block mb-4 '>
                    <h5 style={{ fontSize: "18px" }} className='mb-4'>{taskdetail?.title}
                    </h5>
                    <div className='row'>
                        <div className='col-lg-6 mb-4'>
                            <div className='view-submission'>
                                <h6>Price</h6>
                                <span>₹ {taskdetail?.price}
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-4'>
                            <div className='view-submission'>
                                <h6>Completed Date
                                </h6>
                                <span>{taskdetail?.created}
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-4'>
                            <div className='view-submission'>
                                <h6>Caption
                                </h6>
                                <span>{taskdetail?.caption}
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-4'>
                            <div className='view-submission'>
                                <h6>hashtag
                                </h6>
                                <span>{taskdetail?.hashtag}
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-12 mb-4'>
                            <div className='view-submission'>
                                <h6>Url
                                </h6>
                                <span>{taskdetail?.url}
                                </span>
                            </div>
                        </div>
                        <div
                            dangerouslySetInnerHTML={{ __html: taskdetail?.description }}
                        />




                        <div className='submission_wrap p-3 pt-0 ps-0 icon-boxes-main d-flex flex-row justify-content-start gap-3 '>
                            {
                                asssets?.length > 0 ? asssets?.map((curElem, index) => {


                                    return (
                                        <div className='account-block mb-0 icon-boxes mt-2' key={index} style={{ background: "#fff" }}>
                                            <span className='icon-box-cross'>
                                                {/* <MdCancel style={{ color: "#afa4a8" }} /> */}
                                            </span>
                                            <span onClick={() => handleViewFile(curElem)}>
                                                {curElem.attachmenttype === "PDF" ? <FaRegFilePdf style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : curElem.attachmenttype === "Image" ? <MdOutlineAddPhotoAlternate
                                                    style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} /> : <MdOutlineVideoLibrary
                                                    style={{ fontSize: "28px", color: "#f10065", cursor: "pointer" }} />}
                                            </span>
                                            <p>{curElem.name}</p>
                                        </div>
                                    )
                                }) : <div className='d-flex align-items-center justify-content-center w-100'>No attachment file</div>
                            }



                        </div>
                    </div>
                </div>
                <div className="account-block refer-table mt-3 mb-5">
                    <div className="row">
                        <div className="table-responsive mt-2">

                            <table className="table theme-table">
                                <tr>
                                    <th>TaskId
                                    </th>
                                    <th>Task</th>
                                    <th>Post Data</th>
                                    <th>Assingn To </th>
                                    <th >Create Date</th>
                                    <th >Automation</th>
                                    <th >Status</th>
                                    <th >Reason</th>

                                </tr>

                                {
                                    proof?.length > 0 ? proof.map((curElem, i) => {
                                        const proofData = JSON.parse(curElem.proof_meta_data) || null
                                        const renderProofData = () => {
                                            if (!proofData) return null;

                                            return Object.entries(proofData).map(([key, value], index) => {
                                                if (key === "link" || key === "file") {
                                                    return (
                                                        <a key={index} href={value} target="_blank" style={{ marginRight: "10px" }}>
                                                            {value}
                                                        </a>
                                                    );
                                                } else if (key === "comment") {
                                                    return <p key={index} style={{ marginBottom: "5px" }}>{value}</p>;
                                                }
                                                return null;
                                            });
                                        };
                                        return (
                                            < tr key={i} >
                                                < td data-label="TaskId">{curElem.id}</td>
                                                <td data-label="Task">
                                                    {curElem?.proof_type === "link" || curElem?.proof_type === "file" ? <a href={curElem.proof} target='_blank' style={{ cursor: "pointer" }}>{curElem.proof}</a> :
                                                        <>{curElem?.proof}</>}
                                                </td>
                                                <td data-label="Post Data">
                                                    {renderProofData()}
                                                </td>
                                                <td data-label="Assingn To">
                                                    {curElem.name}
                                                </td>
                                                <td data-label="Create Date">{curElem.created}</td>
                                                <td data-label="Automation">
                                                    {curElem.is_automation_run === "1" ? <div className='earn_status complete' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(222, 248, 238)", color: "rgb(74, 167, 133)" }}>Yes</div> : <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>No</div>}
                                                </td>
                                                <td data-label="Status">
                                                    {curElem.status === "2" ? <div className='earn_status complete' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(222, 248, 238)", color: "rgb(74, 167, 133)" }}>Approved</div> : curElem.status === "3" ? <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "#f8d7da", color: "#721c24" }}>Rejected</div> : <div className='earn_status' style={{ padding: "5px 20px", width: "fit-content", borderRadius: "50px", backgroundColor: "rgb(255, 243, 205)", color: "rgb(133, 100, 4)" }}>Pending</div>}
                                                </td>
                                                <td data-label="Reason">
                                                    {curElem.reason}

                                                </td>
                                            </tr>
                                        )
                                    })
                                        : ""}

                            </table>

                        </div>
                    </div>


                </div>

            </div>
        }
        </>
    )
}

export default ViewTaskSubmission
